import { Button } from '../ui/button/button';
import { useEffect, useState } from 'react';
import { Checkbox } from '../ui/input/checkbox';

type MessageDialogProps = {
  // - メッセージリスト -
  message: string[];
  // - ボタンリスト -
  buttons: {
    label: string,
    callback: () => void,
    options?: {
      waitActive?: number,
    },
  }[];
  className?: string,
  // 次回からこのメッセージを表示しないチェックボックス
  remind?: {
    callback: (v: boolean) => void;
    label?: string;
    initCheck?: boolean;
  };
};

const DialogButton = (props: {
  label: string,
  callback: () => void,
  buttons: { label: string, callback: () => void, options?: { waitActive?: number | undefined } | undefined }[],
  options?: {
    waitActive?: number,
  },
}) => {
  const { label, callback, options, buttons } = props;
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (!active) {
      setTimeout(() => {
        setActive(true);
      }, options?.waitActive || 0);
    }
  }, [active]);
  const isMulchBtn = !!buttons.length;
  const noBtn = (label === 'いいえ');
  const canselBtn = (label === 'キャンセル');
  const yesBtn = (label === 'はい');
  const okBtn = (label === 'OK');
  const keyHandler = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        noBtn && callback();
        canselBtn && callback();
        (!isMulchBtn && okBtn) && callback();
        break;
      case 'Enter':
        yesBtn && callback();
        okBtn && callback();
        break;
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', keyHandler);
    return () => {
      window.removeEventListener('keydown', keyHandler);
    };
  }, []);
  return (
    <Button
      label={label}
      onClick={callback}
      disabled={!active}
    />
  );
};

const AgainCheck = (props: {
  label?: string,
  callback: (v: boolean) => void,
  initCheck?: boolean,
}) => {
  const { label, callback, initCheck } = props;
  const [check, setCheck] = useState(initCheck ?? false);
  return (
    <>
      <div className="dialog_contents__body__comment"><br /></div>
      <div className="dialog_contents__body__comment__remaind">
        <Checkbox
          checked={check}
          onClick={() => {
            setCheck(!check);
            callback(!check);
          }}
        />
        &nbsp;
        {label ?? '次回からこのメッセージを表示しない'}
      </div>
    </>
  );
};

export const MessageDialog = (props: MessageDialogProps) => {
  const { message, buttons, remind } = props;
  return (
    <div className="dialog_contents">
      <div className={`dialog_contents__body ${props.className ?? ''}`}>
        {message.map((v, i) => (
          <div
            key={`message-dialog-text_${i}`}
            className="dialog_contents__body__comment"
          >{v || <br />}</div>
        ))}
        {!!remind &&
          <AgainCheck
            {...remind}
          />
        }
      </div>
      <div className="dialog_contents__footer">
        {buttons.map((v, i) => (
          <div key={`message-dialog-button_${i}`}>
            <DialogButton
              label={v.label}
              callback={v.callback}
              buttons={buttons}
              options={v.options}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
