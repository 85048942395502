import { useEffect, useMemo, useRef, useState } from 'react';
import './tooltip.scss';
import './dom-content-tooltip.memo.scss';

export type AutoPosProps = {
  h?: "center",
  // h?: "center" | "right" | "left",
  v?: "center" | "top" | "bottom",
};

type DomContentTooltip = {
  content: JSX.Element,
  relativeEle: HTMLElement | null,
  positionType?: "fixed" | "absolute",
  imageRef: React.RefObject<HTMLDivElement>;
  autoPos?: AutoPosProps,
  observer: boolean,
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * 帰属する要素に対して自動的に位置調整させるツールチップ
 * ※[fixed - center] のみ調整済み。追加が必要になった都度調整予定。
 * 
 * @param {JSX.Element} content ツールチップ内で表示するDOM。
 * @param {HTMLElement | null} relativeEle ツールチップが帰属する要素
 * @param {AutoPosProps} autoPos 初期位置の自動配置箇所。デフォルトは左右中央下側。
 */
export const MemoDomContentTooltip = (props: DomContentTooltip) => {
  const { content, relativeEle, positionType = "fixed", autoPos: _autoPos = { h: "center", v: "bottom" }, imageRef, observer, ...defaultProps } = props;
  // - State -
  // -- 帰属要素の矩形 --
  const [relEleRect, setRelEleRect] = useState<DOMRect>();
  const [autoPos, setAutoPos] = useState<AutoPosProps>({ ..._autoPos });
  // -- 水平方向の基準座標 --
  const [baseHPos, setBaseHPos] = useState(0);
  // -- 垂直方向の基準座標 --
  const [baseVPos, setBaseVPos] = useState(0);
  // -- 水平方向の見切れ辺 --
  const [hEdge, setHEdge] = useState<"center" | "edge_right" | "edge_left">("center");
  const [alreadyAutoPosV, setAlreadyAutoPosV] = useState<{ top?: boolean, bottom?: boolean }>({});
  const [scrollObserver, setScrollObserver] = useState(false);
  // - Ref -
  const ref = useRef<HTMLDivElement>(null);
  const left = useMemo(() => {
    if (!ref.current) return baseHPos;
    const rect = ref.current.getClientRects().item(0);
    if (!rect) return baseHPos;
    return `calc(${baseHPos}px - ${(rect.width / 2)}px)`;
  }, [ref, baseHPos, observer, scrollObserver, ]);
  const top = useMemo(() => {
    if (!ref.current) return { top: baseVPos };
    const rect = ref.current.getClientRects().item(0);
    const imageRect = imageRef.current?.getClientRects().item(0);
    if (!rect) return { top: baseVPos };
    if (!imageRect) return { top: baseVPos };
    if ((imageRect.bottom + rect.height) > document.body.clientHeight) {
      return { bottom: 4 };
    }
    // if (rect.bottom >= window.document.body.clientHeight) {
    // }
    return { top: baseVPos };
  }, [ref, baseHPos, observer, scrollObserver, imageRef]);
  // - Effect -
  useEffect(() => {
    if (relativeEle) {
      relativeEle.style.position = "relative";
      setRelEleRect(relativeEle.getBoundingClientRect());
    }
  }, [relativeEle, observer, scrollObserver]);
  // -- 見切れ対応 --
  useEffect(() => {
    if (relativeEle && ref.current && relEleRect) {
      const refRect = ref.current.getBoundingClientRect();
      setHEdge((prev) => {
        // 左右見切れ
        if (refRect.right > window.innerWidth && refRect.left < 0) {
          return "center";
        }
        // 右見切れ
        if (refRect.right > window.innerWidth) {
          return "edge_right";
        }
        // 左見切れ
        if (refRect.left < 0) {
          return "edge_left";
        }
        return prev;
      });
      
      // 下見切れ
      if (autoPos.v !== "center" && refRect.bottom > window.innerHeight) {
        setAutoPos({ ..._autoPos, v: "top" });
      }
      // 上見切れ
      if (autoPos.v !== "center" && refRect.top < 0) {
        setAutoPos({ ..._autoPos, v: "bottom" });
      }
      // 上下見切れ
      if (alreadyAutoPosV.top && alreadyAutoPosV.bottom) {
        setAutoPos({ ..._autoPos, v: "center" });
        return;
      }
    }
  }, [relativeEle, ref.current, baseVPos, baseHPos, setHEdge, autoPos.v, alreadyAutoPosV.top, alreadyAutoPosV.bottom, observer, scrollObserver]);
  useEffect(() => {
    if (!alreadyAutoPosV.top && autoPos.v === "top") {
      setAlreadyAutoPosV({ ...alreadyAutoPosV, top: true });
    }
    if (!alreadyAutoPosV.bottom && autoPos.v === "bottom") {
      setAlreadyAutoPosV({ ...alreadyAutoPosV, bottom: true });
    }
  }, [autoPos.v, alreadyAutoPosV.top, alreadyAutoPosV.bottom, observer, scrollObserver]);
  // -- 座標自動調整 --
  useEffect(() => {
    if (!relEleRect) { return };
    switch (autoPos.h) {
      case "center":
      default:
        if (positionType === "fixed") {
          setBaseHPos(relEleRect.x + relEleRect.width / 2);
        }
        break;
      // 左右配置は必要になり次第追加予定
    }
    switch (autoPos.v) {
      case "center":
      default:
        if (positionType === "fixed") {
          setBaseVPos(relEleRect.y + relEleRect.height / 2);
        }
        break;
      case "top":
        if (positionType === "fixed") {
          setBaseVPos(relEleRect.y);
        }
        break;
      case "bottom":
        if (positionType === "fixed") {
          setBaseVPos(relEleRect.y + relEleRect.height);
        }
        break;
    }
  }, [autoPos, relEleRect, observer, scrollObserver]);
  useEffect(() => {
    const set = () => setScrollObserver((prev) => !prev);
    const table = document.getElementById('orders_table');
    table?.addEventListener('scroll', set);
    return (() => {
      table?.removeEventListener('scroll', set);
    })
  }, []);
  return (
    <div
      {...defaultProps}
      className={`tooltip dom_content_tooltip_memo pos_${autoPos.h}_${autoPos.v}${defaultProps.className ? ` ${defaultProps.className}` : ''}${hEdge === "center" ? '' : ` ${hEdge}`}`}
      ref={ref}
      style={{ position: positionType, left, ...top }}
    >
      {content}
    </div>

  );
};