import { EditableImageManager } from '../../editable-image/editable-image.manager';
import { FrameController } from '../frame/frame-controller';
import { ImageController, initPictureFlags } from './image-controller';
import * as lodash from 'lodash';
import { UiManager } from '../../ui/ui.manager';
import { ImageEvent } from './image-event';
import { CjContainer, CjShape, CjStage } from '../../../model/cj-factory';
import { LayoutDataManager } from '../../data/layout-data.manager';
import { CjTool } from '../../../../utilities/cj-tool';
import createjs from 'createjs-module';
import { EditableEvent } from '../editable-event';
import {
  CJ_BGIMAGE_NAME,
  CJ_CONTAINER_NAME,
  CJ_DPOBJ_NAME,
} from '../../../model/cj-factory/cj-obj-name.collection';
import { FitChecker } from '../../../../utilities/fit-checker';
import { FocusModeType } from '../../data/layout-data-type';
import { store } from '../../../../app/store';
import { xmlActions } from '../../../../xml/slice/xml-slice';
import { ImageEditManager } from '../image-edit.manager';
import { AlbumManager } from '../../../albam/albam';
import { cloneDeep, partition } from 'lodash';
import { TextEvent } from '../text/text-event';

export type ListenerIdsType = {
  moveStart?: Function,
  moving?: Function,
  moveEnd?: Function,
}

type RemoveOption = {
  isUndo?: boolean;
}

type RepairOption = {
  isCopy?: boolean;
}

export class ImageEditableTool {
  private stage: CjContainer;
  private readonly pageId: string;

  private editableImageManager!: EditableImageManager;
  private frameController!: FrameController;
  private imageController!: ImageController;
  private imageEvent!: ImageEvent;
  private layoutDataManager!: LayoutDataManager;
  private editableEvent!: EditableEvent;
  private imageEditManager!: ImageEditManager;
  private textEvent!: TextEvent;
  rotateCount = 0;

  private ListenerIds: ListenerIdsType = {
    moveStart: undefined,
    moving: undefined,
    moveEnd: undefined,
  };

  constructor(stage: CjContainer, pageId: string) {
    this.stage = stage;
    this.pageId = pageId;
  }

  di(
    imageController: ImageController,
    frameController: FrameController,
    editableMng: EditableImageManager,
    imageEvent: ImageEvent,
    layoutDataManager: LayoutDataManager,
    editableEvent: EditableEvent,
    imageEditMng: ImageEditManager,
    textEvent: TextEvent,
  ): void {
    this.imageController = imageController;
    this.frameController = frameController;
    this.editableImageManager = editableMng;
    this.imageEvent = imageEvent;
    this.layoutDataManager = layoutDataManager;
    this.editableEvent = editableEvent;
    this.imageEditManager = imageEditMng;
    this.textEvent = textEvent;
  }

  initialize(): void {
  }

  destroy(): void {
  }

  update(stage: CjContainer): void {
    this.stage = stage;
  }

  // - 写真枠モード -
  frameMode(image: CjContainer) {
    // CjTool.getDummyFrame(image).alpha = 0;
    const focusMode = this.layoutDataManager.selector('getFocusMode');
    const target = CjTool.getImageAreaContainer(image);
    this.removeEvt(target);
    this.editableEvent.disFocused(target);
    this.imageEvent.handleFocus(target, 'scale');
  }

  // - 写真編集モード -
  imageMode(image: CjContainer, isPng?: boolean, isDai?: boolean) {
    if (isPng) {
      // this.editableEvent.disFocused(image);
      this.imageEvent.handlePngFocus(image);
      this.layoutDataManager.dispatch('setFocusMode', 'png');

      this.ListenerIds.moveStart = image.on('mousedown', (e: any) => {
        this.imageEvent.handleMoveStart(image, e, true);
      });
      this.ListenerIds.moving = image.on('pressmove', (e: any) => {
        this.imageEvent.handleMove(image, e, true);
      });
      this.ListenerIds.moveEnd = image.on('pressup', (e: any) => {
        this.imageEvent.handleMoveEnd(image, e, true);
      });
      this.imageEvent.imageInitData.push({
        target: image,
        param: {
          x: image.x,
          y: image.y,
          movePos: cloneDeep(image.movePos),
          rotation: image.rotation,
          scaleX: image.scaleX,
          scaleY: image.scaleY,
          regX: image.regX,
          regY: image.regY,
        },
      });
      return;
    }
    const photoContainer = CjTool.getPhotoContainer(image);
    const target = CjTool.getImageContainer(image);
    this.removeEvt(target);
    if (this.textEvent.isFocus && this.textEvent.targetFrame) {
      this.editableEvent.disFocused(this.textEvent.targetFrame);
    } else {
      this.editableEvent.disFocused(target);
    }
    target.mouseEnabled = true;
    isDai ? this.imageEvent.handleFocus(target, 'dai')
      : this.imageEvent.handleFocus(target, 'rotate');

    this.ListenerIds.moveStart = target.on('mousedown', (e: any) => {
      this.imageEvent.handleMoveStart(target, e);
    });
    this.ListenerIds.moving = target.on('pressmove', (e: any) => {
      this.imageEvent.handleMove(target, e);
    });
    this.ListenerIds.moveEnd = target.on('click', (e: any) => {
      e.stopPropagation();
      this.imageEvent.handleMoveEnd(target, e);
    });

    target.filters = [];
    target.uncache();
    photoContainer.alpha = 0.7;
    CjTool.getDummyFrame(target).alpha = 0.2;
    this.imageEvent.imageInitData.push({
      target: target,
      param: {
        x: photoContainer.x,
        y: photoContainer.y,
        movePos: cloneDeep(photoContainer.movePos),
        rotation: photoContainer.rotation,
        scaleX: photoContainer.scaleX,
        scaleY: photoContainer.scaleY,
        regX: photoContainer.regX,
        regY: photoContainer.regY,
      },
    });
    this.imageEvent.updateToolPos(isPng ? 'png' : 'rotate', image);
  }

  // - 回転 -
  async rotate(v: number, target?: CjContainer) {
    if (!target) return;
    const focusMode = this.layoutDataManager.selector('getFocusMode');
    if (focusMode === 'png') {
      const preAngle = target.rotation ?? 0;
      const angle = preAngle + v;
      target.rotation = angle >= 360 ? angle - 360 : angle;
      return;
    }
    const container = CjTool.getRotateContainer(target);
    const isCheckPhoto = CjTool.checkPhotoInFrame(container);
    const imageContainer = CjTool.getImageContainer(container);
    const imageAreaContainer = CjTool.getImageAreaContainer(container);
    const mask = CjTool.getMaskContainer(target);
    const maskData = mask.getBounds();
    if (focusMode === 'rotate' || focusMode === 'dai') {
      const photo = CjTool.getPhotoContainer(imageContainer);
      const image = CjTool.getPhoto(imageContainer);
      if (v < 90) {
        photo.rotation += v;
        await this.layoutDataManager.dispatch('setRotation', photo);
        const photoFrames: [] = this.layoutDataManager.selector('getEditors', target.pageId);
        const isToast = FitChecker.checkImageFit(target, photoFrames, this.stage);
        this.imageEditManager.isFit = isToast;
        UiManager.ins.emit('l->r:show-toast', { isToast, target });
        return;
      }
      let angle;
      let maskH;
      let maskW;
      switch (photo.rotation) {
        case 90:
          angle = 180;
          maskH = maskData.height;
          maskW = maskData.width;
          break;
        case 180:
          angle = 270;
          maskH = maskData.width;
          maskW = maskData.height;
          break;
        case 270:
          angle = 0;
          maskH = maskData.height;
          maskW = maskData.width;
          break;
        default:
          angle = 90;
          maskH = maskData.width;
          maskW = maskData.height;
          break;
      }
      await this.layoutDataManager.dispatch('setRotation', photo);
      const scale = await this.imageController.resizeImage(imageAreaContainer, image, maskH, maskW);
      photo.rotation = angle >= 360 ? angle - 360 : angle;
      photo.x = photo.defaultPos.x;
      photo.y = photo.defaultPos.y;
      photo.movePos = { x: 0, y: 0 };
      photo.scaleX = scale;
      photo.scaleY = scale;
    } else {
      container.isRotate = !container.isRotate;
      const angle = container.isRotate ? Number(container.rotation) + 90 : Number(container.rotation) - 90;
      container.rotation = angle;
      if (isCheckPhoto) {
        const photoContainer = CjTool.getPhotoContainer(imageContainer);
        // const photo = CjTool.getPhoto(imageContainer);
        const _mask = CjTool.getMaskContainer(target);
        const mask = _mask.getChildByName('mask').clone();
        mask.set({ alpha: 0, x: 0, y: 0 });
        // // -- 境界情報を取得 --
        const frame = CjTool.getScaleContainer(target);
        const maskData = _mask.getTransformedBounds();
        imageContainer.filters = [];
        imageContainer.uncache();
        // photoContainer.rotation = container.isRotate ? photoContainer.rotation - 90 : photoContainer.rotation + 90;
        // mask.rotation = angle === 0 ? mask.rotation + 90 : mask.rotation - 90;
        await this.layoutDataManager.dispatch('setRotation', container);
        _mask.alpha = 0;
        mask.cache(0, 0, maskData.width, maskData.height);
        imageContainer.filters = [new createjs.AlphaMaskFilter(mask.cacheCanvas as HTMLCanvasElement)];
        imageContainer.cache(0, 0, maskData.width, maskData.height);
        await this.layoutDataManager.dispatch('setRotation', photoContainer);
        const editableList = this.editableImageManager.list;
        const photo = editableList.find((v) => v.id === photoContainer.editableImageId);
        await this.layoutDataManager.dispatch('removeImage', imageContainer);
        await this.imageController.createPhotoImage(photo!, target, {
          ...initPictureFlags,
          isRotate: true,
          isUpdate: true,
        });
        // this.imageController.resizeImage(photoContainer, photo, maskData.height, maskData.width);
        // this.imageController.centeringImage()
      }
      await this.layoutDataManager.dispatch('setRotation', container);
    }
    // const a = CjTool.getPhotoContainer(target).getTransformedBounds()
    // const b = CjTool.getImageAreaContainer(target).getTransformedBounds()
    // const test = new CjShape();
    // test.graphics.s('red').dr(a.x + b.x, a.y + b.y,CjTool.getPhotoContainer(target).getTransformedBounds().width,CjTool.getPhotoContainer(target).getTransformedBounds().height);
    // this.stage.addChild(test)
    this.imageEvent.updateToolPos(focusMode, target);
    const photoFrames: [] = this.layoutDataManager.selector('getEditors', target.pageId);
    const isToast = FitChecker.checkImageFit(target, photoFrames, this.stage);
    this.imageEditManager.isFit = isToast;
    UiManager.ins.emit('l->r:show-toast', { isToast, target });
  }

  // - イベントの削除 -
  removeEvt(target: CjContainer, _type?: string) {
    const type = _type ? _type : this.layoutDataManager.selector('getFocusMode');
    switch (type) {
      case 'scale':
        break;
      case 'rotate':
      case 'dai':
        const container = CjTool.getRotateContainer(target);
        // -- マスクを作成 --
        const _mask = CjTool.getMaskContainer(target);
        const mask = _mask.getChildByName('mask').clone();
        mask.set({ alpha: 0, x: 0, y: 0 });
        // -- 境界情報を取得 --
        const frame = CjTool.getScaleContainer(target);
        const maskData = _mask.getBounds();
        const imageContainer = CjTool.getImageContainer(target);
        const photoContainer = CjTool.getPhotoContainer(target);
        photoContainer.alpha = 1;
        CjTool.getDummyFrame(target).alpha = 0;
        _mask.alpha = 0;
        if (!CjTool.getImageAreaContainer(target)?.logoFlag) {
          mask.cache(0, 0, maskData.width, maskData.height);
          this.stage.getChildByName(CJ_BGIMAGE_NAME.overwrap);
          imageContainer.filters = [new createjs.AlphaMaskFilter(mask.cacheCanvas as HTMLCanvasElement)];
          imageContainer.cache(0, 0, maskData.width, maskData.height);
        }
        const imageArea = CjTool.getImageAreaContainer(target);
        const containerLength = container.numChildren;
        imageArea && container.setChildIndex(imageArea, containerLength - 1);
        target.off('mousedown', this.ListenerIds.moveStart!);
        target.off('pressmove', this.ListenerIds.moving!);
        target.off('pressup', this.ListenerIds.moveEnd!);
        break;
      case 'move':
        this.stage.getChildByName(CJ_BGIMAGE_NAME.grayout).visible = false;
        // target.off('mousedown', this.ListenerIds.moveStart);
        // target.off('pressmove', this.ListenerIds.moving);
        // target.off('pressup', this.ListenerIds.moveEnd);
        break;
      case 'png':
        target.off('mousedown', this.ListenerIds.moveStart!);
        target.off('pressmove', this.ListenerIds.moving!);
        target.off('pressup', this.ListenerIds.moveEnd!);
        break;
      case 'none':
        break;
      default:
        break;
    }
  }

  // - 移動 -
  move() {
    if (!this.imageEvent.targetFrame) return;
    const target = CjTool.getDefaultContainer(this.imageEvent.targetFrame);
    if (!target) return;
    const grayOut = this.stage.getChildByName(CJ_BGIMAGE_NAME.grayout);
    grayOut.visible = true;
    const frameLength = this.stage.numChildren;
    grayOut && this.stage.setChildIndex(grayOut, frameLength - 1);
    this.editableEvent.disFocused(target);
    this.imageEvent.handleFocus(target, 'move');
    UiManager.ins.emit('l->r:dis-select');
  }

  // - 複製 -
  clone(): CjContainer | undefined {
    if (!this.stage || !this.imageEvent.targetFrame || !this.imageEvent.activeFocus) return;
    // -- セレクトの解除 --
    UiManager.ins.emit('l->r:dis-select');
    const target = this.imageEvent.targetFrame;
    const container = CjTool.getDefaultContainer(target);
    this.editableEvent.disFocused(target);
    const imageAreas = this.layoutDataManager.selector('getAllImageAreas', this.pageId);
    const maxIma = Math.max(...imageAreas.map((v: CjContainer) => v.subMaskID));
    // -- クローン作成 --
    const clone = lodash.cloneDeep(container);
    const movePos = CjTool.getImageAreaContainer(clone).movePos;
    // -- 新しいphotoAreaIdを発行（連番） --
    clone.photoAreaId = String(maxIma + 1);
    // -- 座標をすこしずらす --
    clone.x += 30;
    clone.y += 30;
    movePos.x += 30;
    movePos.y += 30;

    CjTool.getImageAreaContainer(clone).copyFlag = true;
    CjTool.getImageAreaContainer(clone).orgsubMaskID = CjTool.getImageAreaContainer(container).orgsubMaskID;
    CjTool.getImageAreaContainer(clone).subMaskID = String(maxIma + 1);
    // CjTool.getImageAreaContainer(clone).subMaskID = String(Number(clone.frameId) + 1);
    // CjTool.getImageAreaContainer(clone).photoSeqNo = Number(clone.frameId) + 1;
    // -- イベント更新 --
    clone.removeAllEventListeners();
    const focusContainers = CjTool.getFocusContainers(clone);
    for (const focusContainer of focusContainers) {
      focusContainer.parent.removeChild(focusContainer);
    }
    this.imageEvent.setImageEvent(clone, 'frame');
    // -- stageへ追加 --
    this.stage.addChild(clone);
    CjTool.updateClonePram(clone);
    const cloneIma = CjTool.getImageAreaContainer(clone);
    cloneIma.depth = imageAreas.length + 1;
    const editorContainers = this.layoutDataManager.selector('getAlbum')?.filter((v: any) => v?.pageId === this.pageId) ?? [];
    const maxFrame = Math.max(...editorContainers.map((v: CjContainer) => v.frameId));
    clone.frameId = String(maxFrame + 1);
    const cloneScale = CjTool.getScaleContainer(clone);
    this.layoutDataManager.dispatch('setEditorContainer', clone);
    this.layoutDataManager.dispatch('setImageAreaContainer', cloneIma);
    this.layoutDataManager.dispatch('setScaleContainer', cloneScale);
    CjTool.getHighlight(target).visible = false;
    if (CjTool.checkPhotoInFrame(clone)) {
      const image = CjTool.getPhotoContainer(clone);
      const editableImage = cloneDeep(this.editableImageManager.list.find((elm) => elm.id === image.editableImageId));
      if (editableImage) {
        image.selectID = editableImage.selectId;
        image.subMaskID = String(maxIma + 1);
        editableImage.photoAreaId = clone.photoAreaId ?? '';
        this.imageController.createPhotoImage(editableImage, clone, { ...initPictureFlags, isClone: true });
      }
    }
    return clone;
  }

  // - ゴミ箱 -
  remove(target: CjContainer, focusMode: FocusModeType, albumMng?: AlbumManager, option: RemoveOption = {}) {
    const frameLength = this.stage.numChildren;
    this.removeEvt(target, focusMode);
    if (focusMode === 'png') {
      target.deleteFlag = true;
      this.imageEvent.activeFocus && (this.imageEvent.activeFocus.visible = false);
      this.imageEvent.activeFocus = null;
      this.imageEvent.targetFrame = null;
      this.stage.removeChild(target);
      try {
        this.editableImageManager.toUnUse(target.editableImageId ?? '');
      } catch {
      }
    } else {
      const editor = CjTool.getDefaultContainer(target);
      const ima = CjTool.getImageAreaContainer(target);
      if (focusMode === 'rotate' || focusMode === 'dai') {
        const overWrap = this.stage.getChildByName(CJ_CONTAINER_NAME.overwrap);
        const guide = this.stage.getChildByName(CJ_CONTAINER_NAME.guide);
        const additional = this.stage.getChildByName(CJ_CONTAINER_NAME.additional);

        const frameLength = this.stage.numChildren;
        // if (focusMode === 'dai') {
        //   this.layoutDataManager.selector('getAdditional', this.pageId).getChildByName(CJ_CONTAINER_NAME.mask).visible = true;
        //   CjTool.getMaskContainer(target).alpha = 1;
        // }
        const container = CjTool.getRotateContainer(target);
        this.imageEvent.activeFocus && (this.imageEvent.activeFocus.visible = false);
        this.imageEvent.activeFocus = null;
        this.imageEvent.isFocus = false;
        this.imageEvent.targetFrame = null;
        container.removeChild(target);
        target.deleteFlag = true;
        this.layoutDataManager.dispatch('removeImage', target);
        additional && this.stage.setChildIndex(additional, frameLength - 1);
        overWrap && this.stage.setChildIndex(overWrap, frameLength - 1);
        guide && this.stage.setChildIndex(guide, frameLength - 1);
        CjTool.getMaskContainer(container).alpha = 1;
        UiManager.ins.emit('l->r:change-use-image-num', { type: 'decrement' });
      } else {
        (target.name === CJ_CONTAINER_NAME.editor && !option.isUndo) && (CjTool.getImageAreaContainer(target).deleteFlag = true);
        if (option.isUndo) {
          this.layoutDataManager.dispatch('removeFrame', target);
        }
        this.imageEvent.activeFocus && (this.imageEvent.activeFocus.visible = false);
        this.imageEvent.activeFocus = null;
        this.imageEvent.targetFrame = null;
        const isPrevPhoto = CjTool.checkPhotoInFrame(target);
        if (isPrevPhoto) {
          CjTool.getPhotoContainer(target).deleteFlag = true;
          CjTool.getImageContainer(target).deleteFlag = true;
          // -- 既に画像があった場合 --
          const prevPhotoImage = CjTool.getImageContainer(target);
          const index = albumMng?.currentAlbum?.editableIDList.findIndex((v) => v === prevPhotoImage.editableImageId);
          if (index !== undefined && index > -1) albumMng?.currentAlbum?.editableIDList.splice(index, 1);
          const usedIdList = albumMng?.getUsedEditableIDList();
          UiManager.ins.emit('l->r:change-use-image-num', { type: 'decrement' });
          // --- 削除する ---
          // this.layoutDataManager.dispatch('removeImage', prevPhotoImage);
          // if (prevPhotoImage?.editableImageId && usedIdList && !usedIdList.includes(prevPhotoImage?.editableImageId)) {
          this.editableImageManager.toUnUse(prevPhotoImage.editableImageId ?? '');
          // }
        }
        // this.stage.removeChild(target);
        target.visible = false;
      }
      editor && this.stage.setChildIndex(editor, ima.depth || frameLength - 3);
    }
    UiManager.ins.emit('l->r:dis-select');
    // this.editableEvent.disFocused(target);
  }

  // - 復活 -
  repairer(img: CjContainer, focusMode: FocusModeType, parent?: CjContainer, albumMng?: AlbumManager, option: RepairOption = {}) {
    if (!this.stage) return;
    if (focusMode === 'png') {
      (img.getChildByName(CJ_DPOBJ_NAME.freeGraphic) as CjContainer).deleteFlag = false;
      this.editableImageManager.toUse(img.editableImageId ?? '');
      this.stage.addChild(img);
    } else if (focusMode === 'rotate' || focusMode === 'dai') {
      (img.getChildByName(CJ_CONTAINER_NAME.photo) as CjContainer).deleteFlag = false;
      img.deleteFlag = false;
      this.editableImageManager.toUse(img.editableImageId ?? '');
      parent && parent.addChild(img);
      this.imageMode(img, false, focusMode === 'dai');
      if (img.editableImageId) {
        albumMng?.currentAlbum?.editableIDList.push(img.editableImageId);
      }
    } else {
      if (img.name === CJ_CONTAINER_NAME.editor) {
        const frame = CjTool.getDefaultContainer(img!);
        CjTool.getImageAreaContainer(img).deleteFlag = false;
      }
      if (CjTool.checkPhotoInFrame(img)) {
        CjTool.getPhotoContainer(img).deleteFlag = false;
        CjTool.getImageContainer(img).deleteFlag = false;
        const image = CjTool.getImageContainer(img);
        if (!option.isCopy) {
          this.editableImageManager.toUse(image.editableImageId ?? '');
        }
        if (image.editableImageId) {
          albumMng?.currentAlbum?.editableIDList.push(image.editableImageId);
        }

        // this.layoutDataManager.dispatch('setImageContainer', image);
      }
      // img.name === CJ_CONTAINER_NAME.image
      UiManager.ins.emit('l->r:change-use-image-num', { type: 'increment' });
      this.stage.addChild(img);
      if (img.name === CJ_CONTAINER_NAME.editor) {
        const frame = CjTool.getDefaultContainer(img!);
        const imageArea = CjTool.getImageAreaContainer(frame);
        const frameLength = this.stage.numChildren;
        if (imageArea) {
          this.stage.setChildIndex(frame, imageArea.depth ?? frameLength - 3);
        }
      }
    }
  }

}
