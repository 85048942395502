import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { UiManager } from '../ui/ui.manager';
import { CommandManager } from '../command/command.manager';
import { AlbumManager, AlbumPage } from '../../albam/albam';
import { EditableImageManager } from '../editable-image/editable-image.manager';
import { PanZoomManager } from '../pan-zoom/pan-zoom.manager';
import { ImageController, initPictureFlags } from './image/image-controller';
import { FrameController } from './frame/frame-controller';
import { EditableEvent } from './editable-event';
import { ImageEditableTool } from './image/image-editable-tool';
import { ImageEvent } from './image/image-event';
import { EditableSideTool } from './editable-side-tool';
import { TextController } from './text/text-controller';
import { TextEvent } from './text/text-event';
import { PreviewManager } from '../preview/preview.manager';
import { LayoutDataManager } from '../data/layout-data.manager';
import { UuidGenerator } from '../../../utilities/uuid-generator';
import * as createjs from 'createjs-module';
import { CjBitmap, CjContainer, CjShape, CjStage } from '../../model/cj-factory';
import { RulerManager } from '../ruler/ruler.manager';
import { EditableEventCollection } from '../../model/editable-event.collection';
import { Env } from '../../../models/env';
import { PosManager } from '../pos/pos.manager';
import { TrimmingManager } from '../trimming/trimming.manager';
import { CJ_BGIMAGE_NAME, CJ_CONTAINER_NAME } from '../../model/cj-factory/cj-obj-name.collection';
import { padding, xmlActions } from '../../../xml/slice/xml-slice';
import { dialogActions } from '../../../components/dialog/slice/dialog-slice';
import { store } from '../../../app/store';
import { XmlStructureModel } from '../../../xml/model/xml-structure-model';
import { OrderPageDataXml } from '../../../xml/class/order/order-page-data-xml';
import { apiActions } from '../../../slices/api-slice';
import { ApiImagesGet } from '../../../api/front/images/api-images';
import { EditableImage } from './editable-image';
import { CjTool } from '../../../utilities/cj-tool';
import { AdditionalManager } from '../additional/additional.manager';
import { WindowsImgBugFix } from '../../../utilities/windows-img-bug-fix';
import { LayoutInitType } from '../../../components/pages/layout/edit-template/layout.edit-template';
import { AcquiredTempInfo, layoutActions } from '../../../slices/layout-slice';
import { isEqual } from 'lodash';
import { parseGetRemotes } from 'simple-git/dist/src/lib/responses/GetRemoteSummary';
import { LayoutXmlUtile } from '../../model/layout.xml.utile';
import { HashGenerator } from '../../../utilities/hash-generator';
import { ScreenColorType } from '../../../components/pages/layout/menu/layout.menu';

export type PathType = {
  base: string,
  ad: string,
  name: string,
}

type GetEditableImageParam = {
  editableImage: EditableImage;
  index: number;
}

export type ScreenColorCodeType = '#000000' | '#dddddd' | '#ffffff'

const screenColorCode = {
  black: '#000000',
  gray: '#dddddd',
  white: '#ffffff',
}  as const;

export class ImageEditManager implements ILayoutEditorManagerBase {
  // canvasの生成用data
  private readonly CANVAS_ID = UuidGenerator.create();
  static readonly CANVAS_W = 4000;
  static readonly CANVAS_H = 4000;
  // wrapperContainerの初期位置
  static readonly STAGE_CONTAINER_X = 1000;
  static readonly STAGE_CONTAINER_Y = 1000;
  static readonly IMAGE_MAX_SIZE = 4000;
  public templateVirtual = {
    w: 0,
    h: 0,
  };
  public templateReal = {
    w: 0,
    h: 0,
  };
  public kijshopCd = '';
  public shopOrderId = '';
  public orderId = '';
  public xml: XmlStructureModel | null = null;
  public isAdditionalFrame: boolean = false;
  public isDAI: boolean = false;
  // private readonly BASE_URL = 'https://mercury.marietta.co.jp/share/labonet/template/';
  private readonly BASE_URL = `${Env.api.back.protocol}://${Env.api.back.host}/files/template`;

  public readonly path: PathType;
  // private readonly canvasWidth: number;
  // private readonly canvasHeight: number;
  private pageId: string = '';
  imageSetMode: 'in' | 'out' = 'in';
  isFit: boolean = false;
  isSetEvent: boolean = false;

  private container: HTMLDivElement | null;
  private canvas: HTMLCanvasElement | null;
  private wrapperStage: CjStage | null;
  public stage: CjContainer | null;
  private guide: CjBitmap | null;
  private draggingDom = false;
  public screenColor: ScreenColorType = 'black';

  private commandManager!: CommandManager;
  private albumManager!: AlbumManager;
  private editableImageManager!: EditableImageManager;
  private panZoomManager!: PanZoomManager;
  private layoutDataManager!: LayoutDataManager;
  private previewManager!: PreviewManager;
  private rulerManager!: RulerManager;
  private posManager!: PosManager;
  private trimmingManager!: TrimmingManager;
  private imageController!: ImageController;
  private frameController!: FrameController;
  private editableEvent!: EditableEvent;
  private imageEditableTool!: ImageEditableTool;
  private imageEvent!: ImageEvent;
  private editableSideTool!: EditableSideTool;
  private textController!: TextController;
  private textEvent!: TextEvent;
  private additionalManager!: AdditionalManager;

  constructor() {
    this.container = null;
    this.canvas = null;
    this.wrapperStage = null;
    this.stage = null;
    this.path = {
      base: '',
      ad: '',
      name: '',
    };
    this.guide = null;
  }

  initialize() {
  }

  destroy(): void {
    // if (!this.container) return;
    // // - DOM のリセット -
    // this.deleteCanvasEle(this.container);
    // listener 削除
    // this.cjCanvas?.removeAllEventListeners();
    UiManager.ins.emit('r->l:center-line', { display: false });
    UiManager.ins.emit('l->r:open-trim-menu', { isOpen: false });
    // this.editableSideTool.transparentAdditional('show');
    this.isSetEvent = false;
    UiManager.ins.off('l->r:frame-fit:push', this.changeFitNum);
    UiManager.ins.off('l->r:frame-fit:get', this.onFitNumGet);
    UiManager.ins.off('r->l:change-screen-color', this.changeScreenColor);
    UiManager.ins.emit('l->r:show-toast', { isToast: false, target: this.stage! });

    this.previewManager.stop();
    this.commandManager.clear();
    this.wrapperStage?.removeAllEventListeners();
    this.stage?.removeAllEventListeners();
    createjs.Ticker.removeAllEventListeners();
    EditableEventCollection.forEach((e: any) => UiManager.ins.off(e));
    window.removeEventListener('dragstart', this.detectionDraggingDom);

    this.imageController.destroy();
    this.frameController.destroy();
    this.editableEvent.destroy();
    this.imageEditableTool.destroy();
    this.imageEvent.destroy();
    this.editableSideTool.destroy();
    this.textController.destroy();
    this.textEvent.destroy();
    this.commandManager.destroy();
  }

  di(
    uiMng: UiManager,
    commandMng: CommandManager,
    albumMng: AlbumManager,
    editableMng: EditableImageManager,
    panZoomMng: PanZoomManager,
    LayoutStoreMng: LayoutDataManager,
    previewMng: PreviewManager,
    rulerMng: RulerManager,
    posMng: PosManager,
    trimmingMng: TrimmingManager,
    additionalMng: AdditionalManager,
  ): void {
    this.commandManager = commandMng;
    this.albumManager = albumMng;
    this.editableImageManager = editableMng;
    this.panZoomManager = panZoomMng;
    this.layoutDataManager = LayoutStoreMng;
    this.previewManager = previewMng;
    this.rulerManager = rulerMng;
    this.posManager = posMng;
    this.trimmingManager = trimmingMng;
    this.additionalManager = additionalMng;
  }

  async initStage(param?: {
    kijshopCd: string,
    shopOrderId: string,
    orderId: string,
  }) {
    if (this.albumManager.currentAlbum?.restoreMount && param) {
      this.kijshopCd = param.kijshopCd;
      this.shopOrderId = param.shopOrderId;
      this.orderId = param.orderId;
    }
    this.createCanvasEle();
    // container.appendChild(this.canvas)
    // - stage の初期化 -
    await this.initTemplate();
    this.wrapperStage?.stage.update();
  }

  private detectionDraggingDom = () => {
    this.draggingDom = true;
  }

  async activateStage(container: HTMLDivElement, kijshopCd: string, shopOrderId: string, orderId: string, initData: LayoutInitType) {
    const canvas = this.getCanvasEle();
    this.canvas!.width = ImageEditManager.CANVAS_W;
    this.canvas!.height = ImageEditManager.CANVAS_H;
    container.appendChild(canvas);
    UiManager.ins.on('l->r:frame-fit:push', this.changeFitNum);
    UiManager.ins.on('l->r:frame-fit:get', this.onFitNumGet);
    UiManager.ins.on('r->l:change-screen-color', this.changeScreenColor);
    window.addEventListener('dragstart', this.detectionDraggingDom)
    this.kijshopCd = kijshopCd;
    this.shopOrderId = shopOrderId;
    this.orderId = orderId;
    const xml = store.getState().xml[shopOrderId];
    const orderInfo = xml?.orderInfo?.infoData?.find((info) => info.xml.metaModel.id === orderId);
    const orderParts = orderInfo?.parts?.partsData;
    const orderPages = orderParts?.map((elm) => elm.page?.pageData).flat();
    const currentAlbumPath = this.albumManager.currentAlbum?.indexes;
    const orderPage = orderPages?.find((page) => isEqual(page?._indexes, currentAlbumPath));
    this.xml = xml ?? null;
    this.imageController.initialize();
    this.frameController.initialize(orderPage);
    this.editableEvent.initialize();
    this.imageEditableTool.initialize();
    await this.editableEvent.setEvent();
    if (!this.isSetEvent) {
      this.isSetEvent = true;
    }
    // this.imageEvent.initialize();
    this.editableSideTool.initialize();
    this.textController.initialize();
    this.textEvent.initialize();
    // - PreviewManager -
    const albumData = this.albumManager.currentAlbum;
    if (albumData && !albumData?.restoreMount) {
      this.previewManager.start(this.canvas, this.albumManager.currentAlbum!);
    }
    // // - PanZoomManager -
    this.panZoomManager.boot(this.canvas);
    if (!this.wrapperStage) return;
    this.trimmingManager.boot(this.stage!, this.layoutDataManager, this.pageId);

    // --- 特定の要素以外クリックでフォーカスを外す ---
    this.wrapperStage?.addEventListener('click', (e) => {
      if (this.imageEvent.isMove && this.imageEvent.dragTarget) {
        this.imageEvent.handleMoveEnd(this.imageEvent.dragTarget, e);
        const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);
        containers.forEach(v => {
          CjTool.getHighlight(v).visible = false;
        })
      }
      if (this.trimmingManager) {
        if (this.trimmingManager.trimGuides) {
          this.trimmingManager.moveEnd();
        }
      }
      if (this.textEvent.targetFrame) {
        this.editableEvent.disFocused(this.textEvent.targetFrame);
      } else if (this.imageEvent.targetFrame) {
        this.editableEvent.disFocused(this.imageEvent.targetFrame, true);
      }
    });
    UiManager.ins.emit('r->l:image-guide-on/off', { mode: initData.modeAbleArea });
    UiManager.ins.emit('r->l:image-resize-in/out', { mode: initData.modeInOut });
    UiManager.ins.emit('r->l:center-line', { display: initData.display });
    UiManager.ins.emit('l->r:frame-fit:push', { fitNum: initData.fitNum });
    UiManager.ins.emit('r->l:change-operation-cursor', { mode: initData.modeCursor });
    UiManager.ins.emit('r->l:transparent-additional', {
      type: initData.additionalShow, callback: () => {
      },
    });
    UiManager.ins.emit('l->r:show-toast', { isToast: this.isFit, target: this.stage! });
    this.screenColor = initData.screenColor;
    this.changeScreenColor({ color: this.screenColor });

    // -- ステージの再描画処理 --
    // NOTE: RAF(RequestAnimationFrame)モード。60FPS
    // createjs.Ticker.timingMode = createjs.Ticker.RAF
    if (albumData && !albumData?.restoreMount) {
      createjs.Ticker.addEventListener('tick', this.wrapperStage);
    }
    // createjs.Ticker.addEventListener('tick', () => {
    //   this.wrapperStage?.update();
    //   // this.stage?.update();
    // });
    // this.panZoomManager.getOperationMode === 'pan-zoom' ?
    //   this.editableSideTool.lock()
    //   : this.editableSideTool.unlock();
  }

  deactivateStage(container: HTMLDivElement) {
    // this.editableSideTool.unlock();
    if (!this.canvas) {
      throw new Error('this.canvas is null !!')
    }
    this.destroy();
    try {
      container.removeChild(this.canvas);
        this.canvas.height = 0;
        this.canvas.width = 0;
        this.canvas.remove();
    } catch {
    }
  }

  getCanvasEle() {
    if (!this.canvas) {
      throw new Error('this.canvas is null !!');
    }
    return this.canvas;
  }

  setCanvasData() {
    if (!this.canvas) {
      throw new Error('this.canvas is null !!');
    }
    this.canvas.width = ImageEditManager.CANVAS_W;
    this.canvas.height = ImageEditManager.CANVAS_H;
  }

  removeCanvasData() {
    if (!this.canvas) {
      throw new Error('this.canvas is null !!');
    }
    this.destroy();
    this.canvas.height = 0;
    this.canvas.width = 0;
    this.canvas.remove();
  }

  activateAllFocus() {
    const containers: CjContainer[] = this.layoutDataManager.selector('getEditors', this.pageId);
    containers.forEach(con => {
      con.mouseEnabled = true;
    });
    UiManager.ins.emit('l->r:wait-loading', { message: '' });
  }

  createCanvasEle() {
    if (this.canvas) {
      throw new Error('There is already a canvas !!');
    }

    // テンプレートのサイズ
    // canvas作成
    const tempSize = this.albumManager.currentAlbum?.parseData.templateData.size[0];
    this.canvas = document.createElement('canvas');
    this.canvas.id = this.CANVAS_ID;
    // テンプレートのサイズをcanvasのサイズにする
    this.canvas.width = ImageEditManager.CANVAS_W;
    this.canvas.height = ImageEditManager.CANVAS_H;
    this.templateVirtual.w = tempSize.virtual[0].$.width;
    this.templateVirtual.h = tempSize.virtual[0].$.height;
    this.templateReal.w = tempSize.real[0].$.width;
    this.templateReal.h = tempSize.real[0].$.height;
    // - canvas へのイベントの付与 -
    this.canvas.addEventListener('dragover', (e) => {
      e.preventDefault();
      if (!(e.currentTarget instanceof HTMLCanvasElement)) {
        throw new Error('e.currentTarget is not instance of HTMLCanvasElement !!');
      }
      const scale = this.panZoomManager.scale;
      const boundingRect = e.currentTarget.getBoundingClientRect();
      const x = (e.clientX - boundingRect.x) / scale;
      const y = (e.clientY - boundingRect.y) / scale;
      UiManager.ins.emit('r->l:editable-image:drag:check', { x, y });
      // }
    });

    const addImage = (files: File[], isPng?: boolean) => {
      if (this.imageEvent.activeFocus) return;
      const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);
      const target = containers.find((elm) => elm.isChecked);
      if (!target) {
        this.activateAllFocus();
        return;
      }
      if (!target.logoFlag && isPng) {
        UiManager.ins.emit('l->r:push-dialog', {
          title: '確認',
          message: ['PNG形式の画像のため合成することができません。'],
          buttons: [
            {
              label: 'OK',
              callback: () => {
                this.activateAllFocus();
              }
            },
          ]
        });
        return;
      }
      UiManager.ins.emit('l->r:wait-loading', { message: '画像の合成中です...' });
      target.logoFlag ? this.getPhotoImageLogo({ files, isPng }) : this.getPhotoImage({ files });
    };

    // - 画像のdrop -
    this.canvas.addEventListener('drop', (e) => {
      e.preventDefault();
    if (this.editableImageManager.flags.dragging) {
        // -- ドローワーからのDnD --
        UiManager.ins.emit('r->l:editable-image:drop');
      this.draggingDom = false;
    } else if (e.dataTransfer?.files.length && !this.draggingDom) {
        // -- ローカルからの直接のDnD --
        const imageArea: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);
        const photoAreaCount = imageArea.filter((v) => !v.logoFlag).length;
        const targets = imageArea.filter(elm => elm.isChecked);
        const max = Math.max(...targets.map(v => v.depth));
        const target = targets.find((elm) => elm.depth === max);
        const _files = !target?.logoFlag
          ? Array.from(e.dataTransfer.files).filter((v) => /\.(jpe?g)$/i.test(v.name))
          : Array.from(e.dataTransfer.files).filter((v) => /\.(jpe?g)$/i.test(v.name) || /\.(png)$/i.test(v.name));
        const isPng = Array.from(e.dataTransfer.files).filter((v) => /\.(png)$/i.test(v.name)).length === 1;
        const files = _files.slice(0, photoAreaCount);
        // Windows でのバグチェック
        // Windows のバグのせいでドロワーからなのにローカルから判定される
        if (!WindowsImgBugFix.check(files) && target) {
          const highlight = CjTool.getHighlight(target);
          highlight.visible = false;
          UiManager.ins.emit('r->l:editable-image:drop');
          this.activateAllFocus();
          return;
        }
        if (!files.length && target) {
          const highlight = CjTool.getHighlight(target);
          highlight.visible = false;
        }
        if (target?.logoFlag && e.dataTransfer.files.length > 1) {
          const highlight = CjTool.getHighlight(target);
          UiManager.ins.emit('l->r:push-dialog', {
            title: '確認',
            message: [
              `対象の写真エリアはロゴタイプです。`,
              `画像を複数枚設置することはできません。`,
              `先頭の画像のみ配置します。`,
            ],
            buttons: [{
              label: 'OK', callback: () => {
                highlight.visible = false;
              },
            }],
          });
          this.activateAllFocus();
          return;
        } else if (target?.logoFlag && e.dataTransfer.files.length === 1) {
          addImage(_files, isPng);
        } else if (photoAreaCount >= e.dataTransfer.files.length) {
          addImage(files, isPng);
        } else {
          UiManager.ins.emit('l->r:push-dialog', {
            title: '確認',
            message: [
              `選択された画像の枚数がこのページの写真エリアの数を超えています。`,
              `このページに配置できる写真枚数は ${photoAreaCount}枚です。`,
              `配置できる枚数だけを配置する場合は「はい」、`,
              `中止する場合は「いいえ」をクリックしてください。`,
            ],
            buttons: [
              {
                label: 'いいえ',
                callback: () => {
                  this.activateAllFocus();
                  return;
                },
              },
              {
                label: 'はい',
                callback: () => {
                  addImage(files, isPng);
                },
              },
            ],
          });
        }
      } else {
        this.editableEvent.cancelDraggingFunc();
        this.draggingDom = false;
      }
    });
  }

  // - templateの初期化 -
  private async initTemplate() {
    if (!this.canvas) {
      throw new Error('There is no canvas !!');
    }
    // - createJSのCanvas -
    this.wrapperStage = new CjStage(this.canvas);
    // - event判定用の背景 -
    const blackBack = new CjShape();
    blackBack.name = CJ_BGIMAGE_NAME.blackback;
    blackBack.graphics.f('#000').dr(0, 0, ImageEditManager.CANVAS_W, ImageEditManager.CANVAS_H);
    this.wrapperStage.addChild(blackBack);
    // - createJSのCanvas -
    this.stage = new CjContainer();
    this.stage.x = ImageEditManager.STAGE_CONTAINER_X;
    this.stage.y = ImageEditManager.STAGE_CONTAINER_Y;
    this.stage.name = CJ_CONTAINER_NAME.stage;
    this.wrapperStage.addChild(this.stage);
    // - サムネに異常があった場合 -
    this.path.base = this.albumManager.currentAlbum?.dirName ?? '';
    // - サムネのパスから基準のパスを作る(dirNameはCoverを考慮していないためaddPathを用意) -
    const thumbnailPath = this.albumManager.currentAlbum?.thumbnail.templatePath;
    const fullPath = thumbnailPath?.substr(0, thumbnailPath?.indexOf('/thumbnail.jpg')) ?? this.path.base;
    // - テンプレートのパス部分だけ抽出 -
    this.path.ad = fullPath.substr(fullPath.indexOf(this.BASE_URL) + this.BASE_URL.length);
    // - pathからテンプレート名を抽出 -
    const dirNames = this.path.ad.split('/');
    this.path.name = dirNames[dirNames.indexOf('template') + 3] ?? '';

    this.pageId = this.albumManager.currentAlbum?.id ?? `layout-order:${UuidGenerator.create()}`;
    this.stage.pageId = this.pageId;
    // this.pageId = `layout-order:${UuidGenerator.create()}`;
    const isRestore = !this.albumManager.currentAlbum?.isChange;
    const album = this.albumManager.currentAlbum?.parseData;
    if (album.templateData?.additionalFrame) {
      this.isAdditionalFrame = !!album.templateData.additionalFrame[0].$.frameID;
      this.isDAI = true;
      this.layoutDataManager.dispatch('setFocusMode', 'DAI');
    }

    // - 画像の取得先 -
    const url = this.BASE_URL + this.path.ad;
    await this.initIns(this.stage, this.path, url, this.pageId);
    await this.initDi();
    const orderPage = (() => {
      if (this.albumManager.currentAlbum?.restoreMount) {
        const xml = store.getState().xml[this.shopOrderId];
        const orderInfo = xml?.orderInfo?.infoData?.find((info) => info.xml.metaModel.id === this.orderId);
        orderInfo && (this.imageController.orderInfo = orderInfo);
        const optionInfo = LayoutXmlUtile.getOptionInfoData(this.orderId, xml);
        const optionParts = LayoutXmlUtile.flatOptionParts(optionInfo);
        const orderParts = orderInfo?.parts?.partsData?.concat(optionParts);
        const orderPages = orderParts?.map((elm) => elm.page?.pageData).flat();
        const currentAlbumPath = this.albumManager.currentAlbum?.indexes;
        const _orderPage = orderPages?.find((page) => isEqual(page?._indexes, currentAlbumPath));
        return _orderPage;
      } else {
        return undefined;
      }
    })();
    if (orderPage) {
      this.frameController.orderPage = orderPage;
      this.imageController.orderPage = orderPage;
      this.textController.orderPage = orderPage;
    }
    await this.frameController.createBgImage(this.path.ad, url);
    await this.frameController.createPhotoFrame(this.path.ad, url);
    await this.frameController.createAdditional();
    await this.frameController.createOverWrap(this.path.ad, url);
    await this.frameController.createGuide(this.path.ad, url);
    await this.frameController.createGrayOut();
    if (isRestore) {
      await this.textController.restoreText();
      await this.imageController.restore();
    }
    await this.frameController.createStageMask(screenColorCode[this.screenColor]);
    if (!this.albumManager.currentAlbum?.restoreMount) {
      UiManager.ins.emit('l->r:wait-loading2', { message: '' });
    }
  }

  get getBaseUrl() {
    return this.BASE_URL;
  }

  // - 台紙形状変化時の更新メソッド -
  async updateTemplate(temp: AcquiredTempInfo, images: EditableImage[], initData: LayoutInitType) {
    if (!this.wrapperStage) return;
    this.albumManager.currentAlbum!.parseData = temp.template;
    this.albumManager.currentAlbum!.thumbnail.image = temp.thumbnail;
    this.albumManager.currentAlbum!.thumbnail.templatePath = temp.thumbnail;
    this.wrapperStage.removeAllChildren();
    // - createJSのCanvas -
    this.stage = new CjContainer();
    this.stage.x = ImageEditManager.STAGE_CONTAINER_X;
    this.stage.y = ImageEditManager.STAGE_CONTAINER_Y;
    this.stage.name = CJ_CONTAINER_NAME.stage;
    this.wrapperStage.addChild(this.stage);

    const tempSize = this.albumManager.currentAlbum?.parseData.templateData.size[0].virtual[0].$;
    this.templateVirtual.w = tempSize.width;
    this.templateVirtual.h = tempSize.height;
    // - サムネに異常があった場合 -
    this.path.base = this.albumManager.currentAlbum?.dirName ?? '';
    // - サムネのパスから基準のパスを作る(dirNameはCoverを考慮していないためaddPathを用意) -
    const thumbnailPath = this.albumManager.currentAlbum?.thumbnail.templatePath;
    const fullPath = thumbnailPath?.substr(0, thumbnailPath?.indexOf('/thumbnail.jpg')) ?? this.path.base;
    // - テンプレートのパス部分だけ抽出 -
    this.path.ad = fullPath.substr(fullPath.indexOf(this.BASE_URL) + this.BASE_URL.length);
    // - pathからテンプレート名を抽出 -
    const dirNames = this.path.ad.split('/');
    this.path.name = dirNames[dirNames.indexOf('template') + 3] ?? '';
    // - 画像の取得先 -
    const url = this.BASE_URL + this.path.ad;
    this.updateIns(this.stage, this.path, url);
    await this.frameController.createBgImage(this.path.ad, url, true);
    await this.frameController.createPhotoFrame(this.path.ad, url, true);
    await this.frameController.createAdditional();
    await this.frameController.createOverWrap(this.path.ad, url, true);
    await this.frameController.createGuide(this.path.ad, url, true);
    await this.frameController.createGrayOut();
    await this.frameController.createStageMask(screenColorCode[this.screenColor]);

    UiManager.ins.emit('r->l:image-guide-on/off', { mode: initData.modeAbleArea });
    UiManager.ins.emit('r->l:image-resize-in/out', { mode: initData.modeInOut });
    UiManager.ins.emit('r->l:center-line', { display: initData.display });
    UiManager.ins.emit('l->r:frame-fit:push', { fitNum: initData.fitNum });
    UiManager.ins.emit('r->l:transparent-additional', {
      type: initData.additionalShow, callback: () => {
      },
    });
    this.screenColor = initData.screenColor;
    this.changeScreenColor({ color: this.screenColor });
    const editors = this.layoutDataManager.selector('getEditors', this.pageId);
    await Promise.all(editors.map((con: any) => new Promise<void>((resolve) => {
      const img = images.find((v) => v.photoAreaId === con.photoAreaId);
      if (img) {
        const index = this.albumManager.currentAlbum?.editableIDList.findIndex((v) => v === img.id);
        if (index !== undefined && index > -1) {
          this.albumManager.currentAlbum?.editableIDList.splice(index, 1);
        }
        this.imageController.createPhotoImage(img, con, { ...initPictureFlags, isClone: true, isUpdate: true })
          .finally(() => {
            if (this.trimmingManager.isOpen) {
              UiManager.ins.emit('r->l:change-middle-frame', { id: Number(img.photoAreaId) });
            }
            this.trimmingManager.reboot(this.stage!);
            resolve();
          });
      } else {
        this.trimmingManager.reboot(this.stage!);
        resolve()
      }
    })))
    // for (const con of editors) {
    //   const img = images.find((v) => v.photoAreaId === con.photoAreaId);
    //   if (img) {
    //     this.imageController.createPhotoImage(img, con, { ...initPictureFlags, isClone: true, isUpdate: true })
    //       .finally(() => {
    //         if (this.trimmingManager.isOpen) {
    //           UiManager.ins.emit('r->l:change-middle-frame', { id: Number(img.photoAreaId) });
    //         }
    //         this.trimmingManager.reboot(this.stage!);
    //       });
    //   } else {
    //     this.trimmingManager.reboot(this.stage!);
    //   }
    // }
  }

  // - インスタンス用意 -
  private initIns(stage: CjContainer, path: PathType, url: string, pageId: string) {
    this.imageController = new ImageController(stage, pageId);
    this.imageEditableTool = new ImageEditableTool(stage, pageId);
    this.imageEvent = new ImageEvent(stage);
    this.frameController = new FrameController(stage, pageId);
    this.editableSideTool = new EditableSideTool(stage, pageId);
    this.editableEvent = new EditableEvent(stage, path, url, pageId);
    this.textController = new TextController(stage, pageId);
    this.textEvent = new TextEvent(stage, pageId);
  }

  // - インスタンス用意 -
  private updateIns(stage: CjContainer, path: PathType, url: string) {
    this.imageController.update(stage);
    this.imageEditableTool.update(stage);
    this.imageEvent.update(stage);
    this.frameController.update(stage);
    this.editableSideTool.update(stage);
    this.editableEvent.update(stage, path, url);
    this.textController.update(stage);
    this.textEvent.update(stage);
  }

  // - インスタンスのDI -
  private initDi() {
    this.imageController.di(
      this.frameController,
      this.albumManager,
      this.editableImageManager,
      this.panZoomManager,
      this.imageEvent,
      this.imageEditableTool,
      this.layoutDataManager,
      this.editableEvent,
      this.editableSideTool,
      this,
    );
    this.imageEditableTool.di(
      this.imageController,
      this.frameController,
      this.editableImageManager,
      this.imageEvent,
      this.layoutDataManager,
      this.editableEvent,
      this,
      this.textEvent,
    );
    this.imageEvent.di(
      this.imageController,
      this.frameController,
      this.panZoomManager,
      this.editableEvent,
      this.editableSideTool,
      this.imageEditableTool,
      this.layoutDataManager,
      this.editableImageManager,
      this.posManager,
      this,
      this.textEvent,
      this.trimmingManager,
    );
    this.frameController.di(
      this.imageController,
      this.albumManager,
      this.editableImageManager,
      this.imageEvent,
      this.imageEditableTool,
      this.layoutDataManager,
      this.additionalManager,
      this,
      this.editableEvent,
      this.editableSideTool,
      this.textController,
    );
    this.editableSideTool.di(
      this,
      this.frameController,
      this.imageController,
      this.editableImageManager,
      this.imageEvent,
      this.textController,
      this.layoutDataManager,
      this.editableEvent,
      this.albumManager,
    );
    this.editableEvent.di(
      this.frameController,
      this.imageController,
      this.commandManager,
      this.editableImageManager,
      this.panZoomManager,
      this.imageEditableTool,
      this.textController,
      this.textEvent,
      this.editableSideTool,
      this.imageEvent,
      this.layoutDataManager,
      this.rulerManager,
      this.trimmingManager,
      this.posManager,
      this.albumManager,
      this,
    );
    this.textController.di(
      this.textEvent,
      this.layoutDataManager,
      this.editableEvent,
      this.posManager,
    );
    this.textEvent.di(
      this.textController,
      this.imageEvent,
      this.editableEvent,
      this.posManager,
      this.layoutDataManager,
    );
  }

  private deleteCanvasEle(container: HTMLDivElement) {
    if (this.canvas) {
      container.removeChild(this.canvas);
      this.canvas = null;
    }
  }

  private onFitNumGet: (v: { callback: (p: { fitNum: number }) => void }) => void = (v: { callback: (p: { fitNum: number }) => void }) => {
    v.callback({ fitNum: this.imageController.fitNum });
  };
  private changeFitNum: (v: { fitNum: number }) => void = (v: { fitNum: number }) => {
    this.imageController.fitNum = v.fitNum;
    this.layoutDataManager.dispatch('setFitNum', v.fitNum);
    UiManager.ins.emit('l->r:frame-fit:change', { fitNum: v.fitNum });
  };

  private changeScreenColor = (v: {color: ScreenColorType}) => {
    const nextColor = v.color;
    this.screenColor = nextColor;
    if (!this.stage) return;
    const prevMask = CjTool.getStageMask(this.stage);
    if (prevMask) {
      this.stage?.removeChild(prevMask);
      this.frameController.createStageMask(screenColorCode[nextColor]);
    }
  }

  private getPhotoImageLogo(param: {
    files: File[],
    isPng?: boolean,
  }): Promise<GetEditableImageParam | null> {
    const { files } = param;
    return new Promise(async (resolve) => {
      if (files.length > 1) {
        UiManager.ins.emit('l->r:push-dialog', {
          title: '確認',
          message: [
            `対象の写真エリアはロゴタイプです。`,
            `画像を複数枚設置することはできません。`,
            `先頭の画像のみ配置します。`,
          ],
          buttons: [
            {
              label: 'OK', callback: () => {
                this.activateAllFocus();
              },
            },
          ],
        });
        resolve(null);
        return;
      }
      store.dispatch(apiActions.run(
        new ApiImagesGet({
          kijshopCd: this.kijshopCd,
          shopOrderId: this.shopOrderId,
          kind: '4',
        }),
        {
          onSuccess: async (res) => {
            const length = res.body.data.length;
            const index = padding(length + 1, 5);
            // const fileName = `ER_PPM_${this.shopOrderId}_${index}.${files[0].type.split('image/')[1] || files[0].name.split('.')[files[0].name.split('.').length - 1] || 'jpg'}`;
            const _ext = files[0].type.split('image/')[1] || files[0].name.split('.')[files[0].name.split('.').length - 1];
            const ext = _ext === 'png' ? 'png' : 'jpg';
            const fileName = `ER_PPM_${this.shopOrderId}_${index}.${ext}`;
            let exifFlg = false;
            const stopWarning = store.getState().layout.isStopExifWarning;
            // const file: File = {
            //   ...files[0],
            //   name: fileName,
            // }
            const file = new File(
              [files[0]],
              fileName,
              {
                type: files[0].type,
                lastModified: files[0].lastModified,
              },
            );
            const hashFile = new File(
              [files[0]],
              'dummy_hash.jpg',
              {
                type: files[0].type,
                lastModified: files[0].lastModified,
              },
            );
            const hash = await HashGenerator.generate(hashFile);
            const list = this.layoutDataManager.selector('getLogoArea', true);
            UiManager.ins.emit(
              'r->l:add-image-logo',
              {
                file,
                kijshopCd: this.kijshopCd,
                shopOrderId: this.shopOrderId,
                orderId: this.orderId || null,
                samePushIgnore: true,
                hashData: {
                  create: false, hash, checkList: list.map((v: CjContainer) => {
                    const photo = CjTool.getPhotoContainer(v);
                    const editable = this.editableImageManager.list.find((v2) => v2.id === photo.editableImageId && v2.kind === '4');
                    return { path: editable?.name ?? '', hash: editable?.hash ?? '' };
                  }).filter((v: any) => v.path !== ''),
                },
                onCreatedEditableImage: (p: { editableImage: EditableImage }) => {
                  p.editableImage.flags.used = true;
                  UiManager.ins.emit('r->l:editable-image:drop:direct', {
                    editableImage: p.editableImage,
                    index: NaN,
                  });
                  if (String(p.editableImage.exif.colorSpace) !== '1' && !exifFlg && !stopWarning && !param.isPng) {
                    exifFlg = true;
                    UiManager.ins.emit('l->r:push-dialog', {
                      title: '確認',
                      message: !p.editableImage.exif.colorSpace ? [
                        'Exifヘッダ情報に色空間の情報がありません。',
                        '色空間がsRGBでない場合、色が変わる可能性があります。',
                      ] : [
                        'sRGB以外の色空間が指定されています。',
                        '色空間がsRGBでない場合、色が変わる可能性があります。',
                      ],
                      buttons: [{
                        label: 'OK',
                        callback: () => {
                        },
                      }],
                      remind: {
                        callback: (v) => store.dispatch(layoutActions.setIsStopExifWarning(v)),
                      },
                    });
                  }
                },
                callback: (p: { editableImage: EditableImage }) => {

                },
              },
            );
          },
        },
      ));
    });
  }

  private getPhotoImage(param: {
    files: File[],

  }): Promise<GetEditableImageParam | null> {
    const { files } = param;
    const orderSelect = store.getState().xml[this.shopOrderId]?.orderSelect?.metaModel.imageData ?? [];
    // const orderSelect = this.xml?.orderSelect?.metaModel.imageData ?? [];
    return new Promise((resolve) => {
      let exifFlg = false;
      UiManager.ins.emit(
        'r->l:add-image',
        {
          files: files,
          // files: Array.from(e.dataTransfer.files),
          kijshopCd: this.kijshopCd,
          shopOrderId: this.shopOrderId,
          orderId: this.orderId || null,
          samePushIgnore: true,
          selectIdList: [...(orderSelect.map((v) => ({
            path: v.selectFileName?.real.path || '',
            selectID: v.selectID || '',
          })))],
          onCreatedEditableImage: (e) => {
            UiManager.ins.emit('r->l:editable-image:drop:direct', {
              editableImage: e.editableImage,
              index: e.index,
              callback: () => {
                const stopWarning = store.getState().layout.isStopExifWarning;
                if (String(e.editableImage.exif.colorSpace) !== '1' && !exifFlg && !stopWarning) {
                  exifFlg = true;
                  UiManager.ins.emit('l->r:push-dialog', {
                    title: '確認',
                    message: !e.editableImage.exif.colorSpace ? [
                      'Exifヘッダ情報に色空間の情報がありません。',
                      '色空間がsRGBでない場合、色が変わる可能性があります。',
                    ] : [
                      'sRGB以外の色空間が指定されています。',
                      '色空間がsRGBでない場合、色が変わる可能性があります。',
                    ],
                    buttons: [{
                      label: 'OK',
                      callback: () => {
                      },
                    }],
                    remind: {
                      callback: (v) => store.dispatch(layoutActions.setIsStopExifWarning(v)),
                    },
                  });
                }
              },
            });
          },
          onUploaded: (e) => {
            if (e.sameImage) return;
            store.dispatch(xmlActions.uploadImage({
              kijshopCd: this.kijshopCd,
              shopOrderId: this.shopOrderId,
            }).layout(this.orderId || '').add(e.editableImage, (res) => {
              store.dispatch(layoutActions.setSaveSelect(res));
            }));
          },
        },
      );
    });
  }

}
