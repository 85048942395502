import { ApiBaseFront } from '../api-base-front';

export type MetaShopOrderPostRequest = {
  kijshopCd: string,
  shopOrderId: string,
  data: {
    shopOrderId: string,
    lastUpdateDate: string,
    createDate: string,
    lastName: string,
    note: string,
    status: string,
    orderDate?: string,
    isOneStop?: 1 | 0,
    cfOrderId?: string,
    oneStopOrderDate: string | null,
    oneStopOrderUser: string | null,
    isOneStopCancel?: 1 | 0,
    orderUser?: string,
  },
};

export class ApiMetaShopOrderPost extends ApiBaseFront {
  constructor(param: MetaShopOrderPostRequest) {
    super(
      'POST',
      '/api/v1/meta/shopOrder',
      'JSON',
      param,
    );
  }
}

export type MetaShopOrderGetResponse = {
  shopOrderId: string,
  lastUpdateDate: string,
  createDate: string,
  lastName: string,
  note: string,
  status: string,
  lockUser: string,
  orderDate?: string,
  isOneStop?: 1 | 0,
  cfOrderId?: string,
  oneStopOrderDate: string | null,
  oneStopOrderUser: string | null,
  isOneStopCancel?: 1 | 0,
  orderUser: string,
};

export class ApiMetaShopOrderGet extends ApiBaseFront<MetaShopOrderGetResponse> {
  constructor(param: { kijshopCd: string, shopOrderId: string }) {
    const { kijshopCd, shopOrderId } = param;
    super(
      'GET',
      '/api/v1/meta/shopOrder',
      'JSON',
      {
        kijshopCd,
        shopOrderId,
      },
    );
  }
}

export class ApiMetaShopOrderDelete extends ApiBaseFront {
  constructor(param: { kijshopCd: string, shopOrderId: string }) {
    const { kijshopCd, shopOrderId } = param;
    super(
      'DELETE',
      `/api/v1/meta/shopOrder/${kijshopCd}/${shopOrderId}`,
      'JSON',
      {},
    );
  }
}
