import { TextArea } from '../textarea/textarea';
import { Select } from '../select/select';
import { Input } from '../input/input';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { useAppSelector } from '../../../app/hooks';
import './sidebar.scss';
import iconReturn from '../../../assets/img/icon/return.svg';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { SelectFormDesignation } from '../../dialog/unique/select-form-designation';
import * as lodash from 'lodash';
import { ColorIcon } from '../color-icon/color-icon';
import { apiActions } from '../../../slices/api-slice';
import { useParams } from 'react-router-dom';
import { Radiobutton } from '../input/radiobutton';
import { Button } from '../button/button';
import { DeliveryAddressInfoInput } from '../../dialog/unique/delivery-address-info-input';
import { xmlActions } from '../../../xml/slice/xml-slice';
import {
  ApiMetaShopOrderGet,
  ApiMetaShopOrderPost,
  MetaShopOrderGetResponse,
} from '../../../api/front/meta/api-meta-shop-order';
import { ResponseBase } from '../../../api/response-base';
import { DateFormatter } from '../../../utilities/date-formatter';
import { localStorageActions } from '../../../slices/local-storage-slice';
import { orderLockActions } from '../../../slices/lock-order-slice';
import { orderPreparationActions } from '../../../slices/order-preparation-slice';
import { LoadingPopup } from '../loading/loading-popup';
import {
  ApiShopOrderStatusListGet,
  ShopOrderListGetResponseStatus, StatusList,
} from '../../../api/front/shop-order/api-shop-order-list';
import warningIcon from '../../../assets/img/icon/warning_icon.svg';
import { LaboOrderCompChecker } from '../../../utilities/labo-order-comp-checker';
import { CfOrderGetOneDataEntity } from '../../../api/cf/api-cf-order-get-one/api-cf-order-get-one';
import { ApiUpdateCloudFolderName } from '../../../api/front/cloud-folder/api-update-cloud-folder-order-name';

export const Sidebar = (props: { isOneStop?: boolean, cfData?: CfOrderGetOneDataEntity | null }) => {
  const {
    isOneStop,
    cfData,
  } = props;
  // - Hooks -
  const dispatch = useDispatch();
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const {
    date,
    deliveryStore: _deliveryStore,
    customerOrderId,
    note,
    xml,
    masterShopList,
    masterShop,
    deliveryTypeInitValue,
    isProxyMode,
    masterShopSystemData,
    orderedList,
    updatingCustomerName,
    registeringCustomerName,
    updatingNote,
    registeringNote,
    updatingDesignation,
    imageLessOrder,
    orderData,
  } = useAppSelector((state) => ({
    customerOrderId: state.common.customerOrderId,
    date: state.orderPreparation.orderData?.createDate,
    note: state.orderPreparation.orderData?.note,
    deliveryStore: state.orderPreparation.orderData?.lastName || '',
    xml: state.xml[shopOrderId],
    masterShopList: state.common.data.masterShop?.lnwMasterShop.shop?.[0].shopData,
    masterShop: state.common.data.masterShop?.lnwMasterShop.system?.[0].systemData,
    orderingShopId: state.common.orderingShopId,
    deliveryTypeInitValue: state.storage.data.deliveryType,
    // isProxyMode: Boolean(state.systemConfig.metaData.surrogateOrder || state.xml[shopOrderId]?.info.metaModel.dpNumber),
    isProxyMode: Boolean(state.xml[shopOrderId]?.info.metaModel.dpNumber),
    masterShopSystemData: state.common.data.masterShop?.lnwMasterShop?.system?.[0]?.systemData || [],
    orderedList: state.common.orderedList?.order || [],
    updatingCustomerName: state.orderPreparation.updatingCustomerName,
    registeringCustomerName: state.orderPreparation.registeringCustomerName,
    updatingNote: state.orderPreparation.updatingNote,
    registeringNote: state.orderPreparation.registeringNote,
    updatingDesignation: state.orderPreparation.updatingDesignation,
    imageLessOrder: state.orderPreparation.imageLessOrder,
    orderData: state.orderPreparation.orderData,
  }), lodash.isEqual);
  // - Ref -
  // -- お客様氏名 --
  const customerNameRef = useRef<HTMLInputElement>(null);
  // - State -
  // -- お客様氏名 --
  const [customerName, setCustomerName] = useState('');
  // -- disable用背景 --
  const [disableBg, setDisableBg] = useState(false);
  const [redBox, setRedBox] = useState('');
  // -- 配送店舗 --
  const [deliveryStore, setDeliveryStore] = useState(_deliveryStore || '');
  const [deliveryStoreList, setDeliveryStoreList] = useState<{ value: string, label: string }[]>([]);
  const [deliveryType, setDeliveryType] = useState<'store' | 'private'>((deliveryTypeInitValue as 'store' | 'private') || 'store');
  // -- メモ --
  const [memo, setMemo] = useState('');
  // 個別配送対応店舗はtrue
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [orderedStatusName, setOrderedStatusName] = useState('');
  const isLaboOrderRetry = useRef(false);
  // -- 更新待機用 --
  // const [updatingName, setUpdatingName] = useState(false);
  // const [updating, setUpdating] = useState(false);
  const loadError = useCallback(() => {
    dispatch(dialogActions.pushMessage({
      title: 'エラー',
      message: [
        'すでに削除済みのオーダーのため編集できません。',
        '「OK」をクリックすると 注文一覧に戻ります。',
      ],
      buttons: [{
        label: 'OK',
        callback: () => {
          dispatch(dialogActions.popAll());
          dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
        },
      }],
    }));
  }, [kijshopCd]);
  // - Callback -
  // -- 注文一覧に戻るボタン --
  const handlerClickBackOrderList = useCallback(() => {
    if (!xml || (customerName && customerName !== 'null')) { // TODO null
      console.log(updatingCustomerName);
      if (updatingCustomerName) {
        dispatch(orderPreparationActions.setRegisteringCustomerName(true));
        // setUpdating(true);
        return;
      }
      if (updatingNote) {
        dispatch(orderPreparationActions.setRegisteringNote(true));
        // setUpdating(true);
        return;
      }

      dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));

    } else {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          'お客様名が入力されていません。',
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
              setRedBox('redBox');
            },
          },
        ],
      }));
    }
  }, [customerName, xml, updatingCustomerName, updatingNote]);
  // -- お客様氏名 --
  const handlerChangeCustomerName = useCallback((v) => {
    // if (!updatingCustomerName) {
    //   dispatch(orderPreparationActions.setUpdatingCustomerName(true));
    //   // setUpdatingName(true);
    // }
    setCustomerName(v);
  }, [updatingCustomerName]);
  // -- お客様氏名のフォーカスが外れたとき --
  const handlerBlurCustomerName = useCallback((v) => {
    if (xml) {
      if (v && xml.customer.viewModel.lastName !== v) {
        dispatch(orderLockActions.check(
          kijshopCd, shopOrderId,
          {
            yes: () => {
              setRedBox('');
              dispatch(apiActions.run(
                new ApiMetaShopOrderGet({
                  kijshopCd,
                  shopOrderId,
                }),
                {
                  onSuccess: (res: ResponseBase<MetaShopOrderGetResponse>) => {
                    if (!res?.body?.data?.createDate) {
                      loadError();
                      return;
                    }
                    if (res.body.data) {
                      dispatch(apiActions.run(
                        new ApiMetaShopOrderPost({
                          kijshopCd,
                          shopOrderId,
                          data: {
                            ...res.body.data,
                            lastUpdateDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                            lastName: v,
                            // status: 'ラボ発注中',
                          },
                        }),
                        {
                          onSuccess: () => {
                            xml.customer.viewModel.lastName = v;
                            xml.customer.build();
                            dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId }).update(xml));
                            dispatch(orderPreparationActions.setUpdatingCustomerName(false));
                            if (cfData && isOneStop) {
                              dispatch(apiActions.run(
                                new ApiUpdateCloudFolderName({
                                  kijshopCd,
                                  orderId: cfData.id,
                                  name: v,
                                })
                              ))
                            }
                            // setUpdatingName(false);
                          },
                          onError: () => {
                            dispatch(orderPreparationActions.setUpdatingCustomerName(false));
                            // setUpdatingName(false);
                          },
                        },
                      ));
                    } else {
                      dispatch(orderPreparationActions.setUpdatingCustomerName(false));
                      // setUpdatingName(false);
                    }
                  },
                  onError: (e: ResponseBase<any>) => {
                    dispatch(orderPreparationActions.setUpdatingCustomerName(false));
                    // setUpdatingName(false);
                    if (String(e?.error?.errorCode) === '500') {
                      loadError();
                    }
                  },
                },
              ));
            },
            no: () => {
              dispatch(orderPreparationActions.setUpdatingCustomerName(false));
              // setUpdatingName(false);
              setCustomerName(customerName);
              dispatch(orderLockActions.setId(''));
              dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
            },
          }));
      } else if (v && v !== 'null') { // TODO null
        dispatch(orderPreparationActions.setUpdatingCustomerName(false));
        // setUpdatingName(false);
        setRedBox('');
      } else {
        dispatch(orderPreparationActions.setUpdatingCustomerName(false));
      }
    } else {
      dispatch(orderPreparationActions.setUpdatingCustomerName(false));
      // setUpdatingName(false);
    }
  }, [customerOrderId, xml, isOneStop, cfData]);
  // -- 配送店舗 --
  const handlerChangeDeliveryStore = useCallback((v) => {
    dispatch(orderLockActions.check(
      kijshopCd, shopOrderId,
      {
        yes: () => {
          setDeliveryStore(v);
          if (xml) {
            xml.delivery.viewModel.shopCode = v;
            xml.delivery.build();
            dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId }).update(xml));
            dispatch(localStorageActions.setData(kijshopCd, 'deliveryStore', v));
          }
        },
        no: () => {
          dispatch(orderLockActions.setId(''));
          dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
        },
      },
    ));
  }, [customerOrderId, xml]);
  // -- メモ --
  const handlerChangeMemo = useCallback((v) => {
    dispatch(orderPreparationActions.setUpdatingNote(true));
    setMemo(v);
  }, [customerOrderId]);
  const handlerBlurMemo = useCallback((v: string) => {
    if (note !== v) {
      dispatch(orderLockActions.check(
        kijshopCd, shopOrderId,
        {
          yes: () => {
            dispatch(apiActions.run(
              new ApiMetaShopOrderGet({
                kijshopCd,
                shopOrderId,
              }),
              {
                onSuccess: (res: ResponseBase<MetaShopOrderGetResponse>) => {
                  if (!res?.body?.data?.createDate) {
                    loadError();
                    return;
                  }
                  if (res.body.data) {
                    dispatch(apiActions.run(
                      new ApiMetaShopOrderPost({
                        kijshopCd,
                        shopOrderId,
                        data: {
                          ...res.body.data,
                          lastUpdateDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                          note: memo || '',
                          lastName: res.body.data.lastName === 'null' ? '' : res.body.data.lastName,
                        },
                      })
                    , {
                        onSuccess: () => {
                          dispatch(orderPreparationActions.setUpdatingNote(false));
                        },
                        onError: () => {
                          dispatch(orderPreparationActions.setUpdatingNote(false));
                        }
                      }));
                  }
                },
                onError: (e: ResponseBase<any>) => {
                  if (String(e?.error?.errorCode) === '500') {
                    loadError();
                  }
                  dispatch(orderPreparationActions.setUpdatingNote(false));
                },
              },
            ));
          },
          no: () => {
            setMemo(note || '');
            dispatch(orderLockActions.setId(''));
            dispatch(orderPreparationActions.setUpdatingNote(false));
            dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
          },
        }));
    } else {
      dispatch(orderPreparationActions.setUpdatingNote(false));
    }
  }, [memo, customerOrderId, note]);
  // -- 発送帳票指定ボタン --
  const handlerClickFormDesignation = useCallback(() => {
    dispatch(orderLockActions.check(
      kijshopCd, shopOrderId, {
        yes: () => {
          dispatch(dialogActions.push({
            title: '発送帳票を選択',
            element: <SelectFormDesignation
              kijshopCd={kijshopCd}
              shopOrderId={shopOrderId}
            />,
          }));
        },
        no: () => {
          dispatch(orderLockActions.setId(''));
          dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
        },
      },
    ));
  }, []);
  // -- 配送情報登録 --
  const handlerClickDeliveryStoreRegister = useCallback(() => {
    dispatch(dialogActions.push({
      title: '配送先情報入力',
      element: <DeliveryAddressInfoInput
        kijshopCd={kijshopCd}
        shopOrderId={shopOrderId}
        orderId={orderId}
      />,
    }));
  }, []);
  // -- 店舗・個別切り替え --
  const changeDeliveryType = useCallback((type: 'store' | 'private') => {
    if (xml?.info?.xmlModel?.lnwOrder?.order?.[0]?.$?.orderDate) {
      return;
    }
    dispatch(orderLockActions.check(
      kijshopCd, shopOrderId,
      {
        yes: () => {
          if (deliveryType !== type) {
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: [
                '配送方法を変更した場合、今まで入力した配送情報は初期化されます。',
                'よろしいですか？',
              ],
              buttons: [
                {
                  label: 'いいえ',
                  callback: () => {
                    dispatch(dialogActions.pop());
                    dispatch(dialogActions.pop());
                    // TODO なぜか2回発動するので要修正
                  },
                },
                {
                  label: 'はい',
                  callback: () => {
                    dispatch(dialogActions.pop());
                    dispatch(dialogActions.pop());
                    setDeliveryType(type);
                    resetXml(type);
                    dispatch(localStorageActions.setData(kijshopCd, 'deliveryType', type));
                  },
                },
              ],
            }));
          }
        },
        no: () => {
          dispatch(orderLockActions.setId(''));
          dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
        },
      }));
  }, [deliveryStore, deliveryType, xml]);
  // -- deliveryType切り替え時、xml初期化 --
  const resetXml = useCallback((type: 'store' | 'private') => {
    if (xml) {
      xml.delivery.viewModel.customerName = undefined;
      xml.delivery.viewModel.zipCode = undefined;
      xml.delivery.viewModel.prefecture = undefined;
      xml.delivery.viewModel.address1 = undefined;
      xml.delivery.viewModel.address2 = undefined;
      xml.delivery.viewModel.phoneNumber = undefined;
      xml.delivery.viewModel.mailAddress = undefined;
      xml.delivery.viewModel.timeZone = undefined;
      xml.delivery.viewModel.start = undefined;
      xml.delivery.viewModel.end = undefined;
      xml.delivery.viewModel.dispTimeZone = undefined;
      xml.delivery.viewModel.shopName = undefined;
      xml.delivery.viewModel.deliveryFormGUID = undefined;
      xml.delivery.viewModel.formName = undefined;
      xml.delivery.viewModel.pdfPath = undefined;
      xml.delivery.viewModel.originalPath = undefined;
      xml.delivery.viewModel.importPath = undefined;
      xml.delivery.viewModel.orderFormGUID = undefined;
      if (type === 'store') {
        xml.delivery.viewModel.type = 'shop';
        xml.delivery.viewModel.shopCode = (isProxyMode) ? shopOrderId.slice(0, 7) : deliveryStore;
      } else {
        xml.delivery.viewModel.type = 'customer';
        xml.delivery.viewModel.shopCode = (isProxyMode) ? shopOrderId.slice(0, 7) : kijshopCd;
      }
      xml.delivery.build();
      dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId }).update(xml));
    }
  }, [deliveryStore, deliveryType, xml]);

  // - Effect -
  useEffect(() => {
    if (masterShopSystemData.find((v) => v.$.systemID === 'customerDelivery')) {
      setShowButton(true);
    }
  }, [masterShopSystemData]);
  // -- お客様氏名の入力が空のときフォーカス --
  useEffect(() => {
    if (customerName === '' && customerNameRef.current) {
      // customerNameRef.current.focus();
      customerNameRef.current.onfocus = () => {
        dispatch(orderPreparationActions.setUpdatingCustomerName(true));
      };
    }
  }, [customerName]);

  // ラジオボタンを表示する店舗一覧。
  useEffect(() => {
    if (masterShop) {
      if (masterShop.find((v) => v.$.systemID === 'customerDelivery')) {
        setShowCheckbox(true);
      }
    }
  }, [masterShop]);
  // -- 名前・配送店舗一覧取得 --
  useEffect(() => {
    if (xml) {
      const name = xml.customer.viewModel.lastName;
      setCustomerName((prev) => {
        const v = (name && !updatingCustomerName) ? name : (prev || '');
        if (!v) {
          customerNameRef?.current?.focus();
          // dispatch(orderPreparationActions.setUpdatingCustomerName(true));
        }
        return v;
      });
      if (xml.delivery.viewModel.type === 'customer') {
        setDeliveryStore(kijshopCd);
        setDeliveryType('private');
      } else {
        setDeliveryType('store');
        if (isProxyMode) {
          setDeliveryStoreList([{ value: xml.delivery.viewModel.shopCode!, label: xml.delivery.viewModel.shopCode! }]);
          setDeliveryStore(xml.delivery.viewModel.type!);
        } else {
          if (masterShopList) {
            const shopList: { value: string, label: string }[] = masterShopList.map((v) => ({
              value: v.$.shopCode!,
              label: v.name?.[0].$.lastName!,
            })) || [];
              setDeliveryStoreList(shopList);
            if (xml.delivery.viewModel.shopCode && shopList.some(v => v.value === xml.delivery.viewModel.shopCode)) {
              setDeliveryStore(xml.delivery.viewModel.shopCode);
            } else {
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: [
                  '配送店舗の読み込みに失敗しました',
                  '配送店舗を再設定してください',
                ],
                buttons: [
                  {
                    label: 'OK',
                    callback: () => {
                      setDeliveryStore(kijshopCd);
                      dispatch(dialogActions.pop());
                      handlerChangeDeliveryStore(kijshopCd);
                    },
                  },
                ]
              }))
            }
          } else {
            dispatch(dialogActions.pushSeriousError());
          }
        }
      }
    }
  }, [xml, masterShopList]);
  // メタ情報取得
  useEffect(() => {
    dispatch(apiActions.run(
      new ApiMetaShopOrderGet({ kijshopCd, shopOrderId }),
      {
        onSuccess: (res: ResponseBase<MetaShopOrderGetResponse>) => {
          if (res.body.data) {
            dispatch(orderPreparationActions.setOrderData(res.body.data));
          }
          if (res.body.data && String(res.body.data.note) !== 'null') {
            setMemo(res.body.data.note || '');
          }
        },
      },
      {
        ignoreSystemError: true,
      },
    ));
  }, [shopOrderId]);
  const _statusList = ['注文作成中', '注文作成済', 'レイアウト中', 'ラボ発注可'];
  // メタ情報更新
  const status = xml?.status.getShopOrderStatus(xml, isOneStop) || '';
  useEffect(() => {
    if (status && orderedStatusName) {
      dispatch(apiActions.run(
        new ApiMetaShopOrderGet({
          kijshopCd,
          shopOrderId,
        }),
        {
          onSuccess: (res: ResponseBase<MetaShopOrderGetResponse>) => {
            if (!res?.body?.data?.createDate) {
              loadError();
              return;
            }
            if (res?.body?.data) {
              const orderDate = xml?.info?.xmlModel?.lnwOrder?.order?.[0]?.$?.orderDate;
              const query = (): { date: string, status: string } => {
                if (orderDate || !_statusList.find((v) => v === orderedStatusName)) {
                  return { date: res.body.data?.lastUpdateDate || '', status: orderedStatusName };
                } else {
                  return { date: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'), status };
                }
              };
              if (res.body.data.status === query().status) {
                if (res.body.data.status === 'ラボ発注中' && LaboOrderCompChecker.checkTimeOut(res.body.data)) {
                  isLaboOrderRetry.current = true;
                  dispatch(orderPreparationActions.setLaboOrderRetry(true));
                  dispatch(dialogActions.pushMessage({
                    title: '確認',
                    message: [
                      '発注処理が正常に完了しませんでした',
                      '「再発注」ボタンをクリックしてください',
                    ],
                    buttons: [{
                      label: 'OK',
                      callback: () => {
                        dispatch(dialogActions.pop());
                      }
                    }]
                  }))
                } else {
                  isLaboOrderRetry.current = false;
                  dispatch(orderPreparationActions.setLaboOrderRetry(false));
                }
                return;
              }
              // 注文メタ更新
              dispatch(apiActions.run(
                new ApiMetaShopOrderPost({
                  kijshopCd,
                  shopOrderId,
                  data: {
                    ...res.body.data,
                    lastUpdateDate: query().date,
                    status: query().status,
                    lastName: res.body.data.lastName === 'null' ? '' : res.body.data.lastName,
                  },
                }),
                {
                  onSuccess: (res) => console.log(res),
                  onError: (res) => console.log(res),
                },
              ));
            }
          },
          onError: (e: ResponseBase<any>) => {
            if (String(e?.error?.errorCode) === '500') {
              loadError();
            }
          },
        },
      ));
    }
  }, [status, orderedStatusName]);

  const [allList, setAllList] = useState<StatusList>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!orderedList) return;
    const setStatus = (list: StatusList) => {
      const data = orderedList.find((v) => v.shopOrderId === shopOrderId);
      if (data) {
        setOrderedStatusName(data.orderStatusName);
      } else {
        const data2 = list.find((v) => v.shopOrderId === shopOrderId);
        if (data2) {
          setOrderedStatusName(data2.status);
        }
      }
    };
    if (allList.length === 0 && !loading) {
      setLoading(true);
      dispatch(apiActions.run(
        new ApiShopOrderStatusListGet(kijshopCd),
        {
          onSuccess: (res: ResponseBase<ShopOrderListGetResponseStatus>) => {
            setLoading(false);
            setAllList(res.body.data?.statusList || []);
            setStatus(res.body.data?.statusList || []);
          },
        },
      ));
    } else {
      setStatus(allList);
    }
  }, [shopOrderId, orderedList, allList]);

  useEffect(() => {
    if (registeringCustomerName && !updatingCustomerName) {
      // dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
      dispatch(orderPreparationActions.setRegisteringCustomerName(false));
    }
    return () => {
      dispatch(orderPreparationActions.setRegisteringCustomerName(false));
    };
  }, [updatingCustomerName]);

  useEffect(() => {
    if (registeringNote && !updatingNote) {
      dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
      dispatch(orderPreparationActions.setRegisteringNote(false));
    }
    return () => {
      dispatch(orderPreparationActions.setRegisteringNote(false));
    };
  }, [updatingNote]);
  useEffect(() => {
    if (registeringCustomerName && !customerName) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          'お客様名が入力されていません。',
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
              setRedBox('redBox');
            },
          },
        ],
      }));
      dispatch(orderPreparationActions.setRegisteringCustomerName(false));
    }
  }, [registeringCustomerName]);

  // クラウドフォルダ専用特殊ステータス
  const cfSpecialStatus = useMemo(() => {
    if (orderData?.isOneStopCancel) {
      return 'キャンセル';
    }
    if (orderData?.status === 'ラボ発注中' && cfData?.isSelectedDownload) {
      return 'ラボ処理中';
    }
    return '';
  }, [orderData, cfData]);

  const ordering = Boolean(xml?.info?.xmlModel?.lnwOrder?.order?.[0]?.$?.orderDate);

  return (
    <>
      <div className="sidebar">
        <div
          className="sidebar__return"
          onClick={handlerClickBackOrderList}
        >
          <img
            src={iconReturn}
            alt=""
          />
          <div>注文一覧に戻る</div>
        </div>
        <div className="sidebar__contents">
          <div className="sidebar__contents__title">
            注文概要
          </div>
          <div className="sidebar__contents__item">
            <div className="item_label">
              作成日時
            </div>
            <div className="item_body">
              {date?.slice(0, 16)}
            </div>
          </div>

          <div className="sidebar__contents__item">
            <div className="item_label">
              注文番号
            </div>
            <div className="item_body">
              {shopOrderId}
            </div>
          </div>

          <div className={`sidebar__contents__item item_customer${customerName === '' ? ' no_name' : ''}`}>
            <div className="item_label">
              お客様氏名
            </div>
            <div className={`item_body ${redBox}`}>
              <Input
                value={customerName}
                onChange={(e) => handlerChangeCustomerName(e.target.value)}
                onBlur={(e) => handlerBlurCustomerName(e.target.value)}
                ref={customerNameRef}
                maxLength={30}
                disabled={ordering}
              />様
            </div>
          </div>

          <div className="sidebar__contents__item item_delivery">
            <div className="item_label">
              配送店舗
            </div>
            <div className="item_body">
              {showCheckbox ? (
                <div className="radio_buttons_box">
                  <Radiobutton
                    value="store"
                    // onChange={(e) => handlerChangeDeliveryType(e.target.value)}
                    onChange={() => changeDeliveryType('store')}
                    checkState={deliveryType}
                    // checked={deliveryType === 'store'}
                    label="店舗配送"
                    disabled={ordering}
                  />
                  <Radiobutton
                    value="private"
                    // onChange={(e) => handlerChangeDeliveryType(e.target.value)}
                    onChange={() => changeDeliveryType('private')}
                    checkState={deliveryType}
                    // checked={deliveryType === 'private'}
                    label="個別配送"
                    disabled={ordering}
                  />
                </div>
              ) : (<></>)}
              {deliveryType === 'store' ? (
                <div className="item_body__type_store">
                  <Select
                    value={deliveryStore}
                    list={deliveryStoreList}
                    onChange={(e) => handlerChangeDeliveryStore(e.target.value)}
                    disabled={ordering}
                  />
                  {
                    showButton && (masterShopSystemData.find((v) => v.$.systemID === 'shipmentFormImport')) ? (
                      <Button
                        label="発送帳票指定"
                        onClick={handlerClickFormDesignation}
                        disabled={ordering || !xml}
                      />
                    ) : (<></>)
                  }
                </div>
              ) : (<></>)}
              {deliveryType === 'private' && showButton ? (
                <Button
                  label="配送情報登録"
                  onClick={handlerClickDeliveryStoreRegister}
                  disabled={ordering}
                />
              ) : (<></>)}
            </div>
          </div>

          <div className="sidebar__contents__item item_status">
            <div className="item_label">
              ステータス
            </div>
            <div className={`item_body labeled_icon_hr${isLaboOrderRetry.current ? ' error' : ''}`}>
              {isLaboOrderRetry.current ?
                <img src={warningIcon} /> :
                <ColorIcon color={ordering || !_statusList.find((v) => v === orderedStatusName) ? (cfSpecialStatus || orderedStatusName) : (imageLessOrder.length ? '注文作成済' : (cfSpecialStatus || status))} />
              }
              {
                isLaboOrderRetry.current ? '送信エラー' :
                ordering || !_statusList.find((v) => v === orderedStatusName) ?
                  (cfSpecialStatus || orderedStatusName) : (imageLessOrder.length ? '注文作成済' : (cfSpecialStatus || status))
              }
            </div>
          </div>

          <div className="sidebar__contents__item item_memo">
            <div className="item_label">
              オーダーメモ
            </div>
            <div className="item_label">
              (記載した内容はラボには連携されません)
            </div>
            <div className="item_body">
              <TextArea
                value={memo}
                onChange={(e) => handlerChangeMemo(e.target.value)}
                onBlur={(e) => handlerBlurMemo(e.target.value)}
                disabled={ordering}
              />
            </div>
          </div>

        </div>
      </div>
      {registeringCustomerName || updatingDesignation || registeringNote ? (<LoadingPopup label="データを更新中です..." />) : (<></>)}
      {disableBg ? <div className="sidebar__disable_bg" /> : (<></>)}
    </>
  );
};
