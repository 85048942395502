import { Button } from '../../ui/button/button';
import { Table } from '../../ui/table/table';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { dialogActions } from '../slice/dialog-slice';
import './lab-order.scss';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { push } from 'connected-react-router';
import { ColorIcon } from '../../ui/color-icon/color-icon';
import { xmlActions } from '../../../xml/slice/xml-slice';
import { ErrorOrder, orderPreparationActions } from '../../../slices/order-preparation-slice';
import { LoadingPopup } from '../../ui/loading/loading-popup';
import { ApiCfOrderGetOne, CfOrderGetOneDataEntity } from '../../../api/cf/api-cf-order-get-one/api-cf-order-get-one';
import { ApiGetCloudFolderOrderFile, CloudFolderGetFileList } from '../../../api/front/cloud-folder/api-get-cloud-folder-order-file';
import { apiActions } from '../../../slices/api-slice';
import { ResponseBase } from '../../../api/response-base';
import { ApiImagesGet, ImagesGetResponse } from '../../../api/front/images/api-images';
import { ApiCfLabOrder } from '../../../api/front/cf-lab-order/cf-lab-order';
import { store } from '../../../app/store';

export type LabOrderProps = {
  kijshopCd: string,
  orderList: {
    createDate: string,
    lastName: string,
    shopOrderId: string,
    status: string,
    cfOrderId?: string,
    // size: string,
    cnt?: number,
  }[],
  callbackEndOrder: () => void,
  retry?: boolean,
};

export type OrderStatus = {
  shopOrderId: string,
  errorCode: string,
};

export const LabOrder = (props: LabOrderProps) => {
  const { kijshopCd, orderList, callbackEndOrder, retry } = props;
  // - Hooks -
  const dispatch = useDispatch();
  // - state -
  const [orderingMessage, setOrderingMessage] = useState('');
  // ワンストップ用 クラウドフォルダデータ取得
  const getOneStopData = useCallback((kijShopId: string, cfOrderId: string) => {
    return new Promise<{
      cfData: CfOrderGetOneDataEntity,
      cfFiles: CloudFolderGetFileList['fileList'],
    }>((resolve, reject) => {
      dispatch(apiActions.run(
        new ApiCfOrderGetOne({
          kijshopCd: kijShopId,
          orderId: cfOrderId,
        },
        ), {
        onSuccess: (cfDataRes: ResponseBase<CfOrderGetOneDataEntity>) => {
          if (Number(cfDataRes.error.errorCode) === 200 && cfDataRes.body.data) {
            const cfData = cfDataRes.body.data;
            dispatch(apiActions.run(
              new ApiGetCloudFolderOrderFile({
                kind: 1,
                kijshopCd,
                orderId: cfData.id,
                thumbOnly: 1,
              }),
              {
                onSuccess: (cfFilesRes: ResponseBase<CloudFolderGetFileList>) => {
                  if (Number(cfFilesRes.error.errorCode) === 200 && cfFilesRes.body.data?.fileList) {
                    const cfFiles = cfFilesRes.body.data.fileList;
                    resolve({
                      cfData,
                      cfFiles,
                    })
                  } else {
                    reject();
                  }
                },
                onError: () => reject(),
              }
            ))
          } else {
            reject();
          }
        },
        onError: () => reject(),
      }))
    })
  }, [dispatch]);

  const getImages = useCallback((
    shopOrderId: string,
  ) => new Promise<ImagesGetResponse[]>((resolve, reject) => {
    dispatch(apiActions.run(
      new ApiImagesGet({
        kijshopCd,
        shopOrderId,
        kind: '6',
      }),
      {
        onSuccess: (res: ResponseBase<ImagesGetResponse[]>) => {
          if ((Number(res.error.errorCode) === 200) && res.body?.data) {
            resolve(res.body.data);
          } else {
            reject();
          }
        },
        onError: () => reject(),
      },
    ));
  }), [kijshopCd, dispatch]);

  // ワンストップラボ発注完了処理
  const CfLabOrder = useCallback((
    shopOrderId: string,
    cfOrderId: string,
  ) => {
    return new Promise<void>((resolve) => {
      dispatch(apiActions.run(new ApiCfLabOrder({
        kijshopCd,
        shopOrderId,
        cfOrderId,
        oneStopOrderUser: store.getState().storage.machineId,
        status: 'ラボ発注中',
      }), {
        onSuccess: (res: ResponseBase<boolean>) => {
          if (res?.body?.data) {
            resolve();
          }
        },
      }));
    });
  }, [dispatch, kijshopCd]);

  // -- 発注ボタン押下 --
  const handlerClickOrder = useCallback(async () => {
    const enableOrderList: { createDate: string, lastName: string, shopOrderId: string, status: string, cnt: number, cfOrderId?: string }[] = [];
    orderList.forEach((v) => {
      if (v.status === 'ラボ発注可' || retry) {
        enableOrderList.push({
          createDate: v.createDate,
          lastName: v.lastName,
          shopOrderId: v.shopOrderId,
          status: v.status,
          cnt: v.cnt || 1,
          cfOrderId: v.cfOrderId,
        });
      }
    });
    if (!enableOrderList.length) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['発注できる注文がありません。'],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        }],
      }));
      return;
    }
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: [
        '発注処理を行います。よろしいですか？',
      ],
      buttons: [
        {
          label: 'いいえ',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'はい',
          callback: async () => {
            dispatch(dialogActions.pop());
            const errorList: ErrorOrder[] = [];
            for (let i = 0; i < enableOrderList.length; i++) {
              setOrderingMessage(`ラボ発注を実施中です。（${i + 1}/${enableOrderList.length}）`);
              const orderData = enableOrderList[i];
              // ワンストップ用事前処理
              if (orderData.cfOrderId) {
                const {
                  cfData,
                } = await getOneStopData(
                  kijshopCd,
                  orderData.cfOrderId,
                );
                await new Promise<boolean>((resolve) => {
                  dispatch(xmlActions.oneStop({
                    kijshopCd,
                    shopOrderId: orderData.shopOrderId,
                    cfData,
                    callback: {
                      success: () => resolve(true),
                      error: () => {
                        errorList.push({
                          ...orderData,
                          errorStatus: {
                            code: 'xml',
                            message: ['XMLの取得に失敗しました'],
                          },
                          cnt: (orderData.cnt || 1) + 1,
                        });
                        resolve(false)
                      },
                    }
                  }));
                });
                if (cfData.isSelectedDownload) {
                  await CfLabOrder(orderData.shopOrderId, orderData.cfOrderId);
                }
                continue;
              }
              await new Promise<void>((resolve) => {
                dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId: orderData.shopOrderId }).labOrder({
                  success: () => {
                    resolve();
                  },
                  error: (e) => {
                    errorList.push({
                      ...orderData,
                      errorStatus: e ? {
                        code: e.code,
                        message: e.message,
                      } : {
                        code: 'xml',
                        message: ['XMLの取得に失敗しました'],
                      },
                      cnt: (orderData.cnt || 1) + 1,
                    });
                    resolve();
                  },
                }, true));
              });
            }
            dispatch(dialogActions.pop());
            setOrderingMessage('');
            if (errorList.length) {
              dispatch(orderPreparationActions.setErrorOrderList([...errorList]));
              dispatch(push(RoutingPath.cnv.uploadManager({ kijshopCd })));
            } else {
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: [
                  '発注処理を行いました',
                  '',
                  '受注完了メールに納品日が記載されていますので',
                  '合わせてご確認ください',
                ],
                buttons: [{
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                    callbackEndOrder();
                  },
                }],
              }));
            }
          },
        },
      ],
    }));
  }, [kijshopCd, orderList, retry]);

  // -- 閉じるボタン押下 --
  const handlerClickClose = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);

  const orderData = [...orderList].map((v) => [
    v.createDate || '',
    v.lastName || '',
    v.shopOrderId,
    <div className="order_status">
      <div className="order_status__inner">
        <ColorIcon color={v.status} />{v.status}
      </div>
    </div>,
    <div style={{ textAlign: 'center' }}>{v.status === 'ラボ発注可' ? '○' : '×'}</div>,
  ]);
  return (
    <div className='dialog_contents lab_order'>
      <div className='dialog_contents__body'>
        <Table
          head={['作成日時', 'お客様名', '注文番号', retry ? '状態' : 'ステータス', '発注可否']}
          body={orderData}
        />
      </div>
      <div className='dialog_contents__footer'>
        <Button
          label='キャンセル'
          onClick={handlerClickClose}
        />
        <Button
          label='発注'
          onClick={handlerClickOrder}
          color='warning'
        // disabled={!}
        />
      </div>
      {orderingMessage ? (<LoadingPopup label={orderingMessage} />) : (<></>)}
    </div>
  );
};
