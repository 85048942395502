import './cloud-folder.scss';
import React, { useCallback, useEffect, useMemo } from 'react';
import { apiActions } from '../../../slices/api-slice';
import { ApiNumberingOrder } from '../../../api/back/numbering-order/api-numbering-order';
import { ResponseBase } from '../../../api/response-base';
import { NumberingOrderResponse } from '../../../models/api/back/numbering-order';
import { xmlActions } from '../../../xml/slice/xml-slice';
import { ApiMetaShopOrderPost } from '../../../api/front/meta/api-meta-shop-order';
import { DateFormatter } from '../../../utilities/date-formatter';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { commonActions } from '../../../slices/common-slice';
import logoImg from '../../../assets/img/logo/ppmcloud_01_y.png';
import { ApiCfOrderGetOne, CfOrderGetOneDataEntity } from '../../../api/cf/api-cf-order-get-one/api-cf-order-get-one';
import {
  ApiCheckCf2PPMToken,
  Cf2PPMTokenCheckResponse,
} from '../../../api/cf/api-cf2ppm-token-check/api-cf2ppm-token-check/api-cf2ppm-token-check';
import { SessionTokenManager } from '../../../manager/session-token-manager';
import { localStorageActions } from '../../../slices/local-storage-slice';
import { ApiBaseBack } from '../../../api/back/api-base-back';
import { ApiBaseFront } from '../../../api/front/api-base-front';
import { ApiCfOrderUpdate } from '../../../api/cf/api-cf-order-update/api-cf-order-update';
import { store } from '../../../app/store';
import { ApiGetShopExpired, ShopExpiredDataEntity } from '../../../api/front/api-staff-shop/api-shop-expired.ts';

export const CloudFolder = () => {
  const { kijshopCd } = useParams<PathParams>();
  const search = useLocation<{ orderId: string, token: string }>();
  // const {} =
  const dispatch = useDispatch();

  const query = useMemo(() => {
    const data = new URLSearchParams(search.search);
    return {
      token: data.get('token') || '',
      orderId: data.get('orderId') || '',
    };
  }, [search]);

  const pushErrorBase = useCallback(function <T = any>(
    message: string[],
    retryAction?: () => Promise<T>,
    redirectLogin?: boolean,
  ) {
    return new Promise<T>((resolve) => {
      const buttons = [
        {
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
            if (redirectLogin) {
              dispatch(push(RoutingPath.login));
            } else {
              dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
            }
          },
        },
      ];
      if (retryAction) {
        buttons.push({
          label: '再試行',
          callback: () => {
            dispatch(dialogActions.pop());
            retryAction().then((res) => resolve(res));
          },
        });
      }
      dispatch(dialogActions.pushMessage({
        title: 'エラー',
        message: message,
        buttons,
      }));
    });

  }, [dispatch, kijshopCd]);

  /* */

  /* トークンチェック セッションの引き継ぎ */
  const checkToken = useCallback(() => {
    /* ログイン情報の初期化 */
    new SessionTokenManager().save('', '');
    dispatch(localStorageActions.setStaffIdAndName({ kijshopCd: '', id: '', name: '' }));
    return new Promise<Cf2PPMTokenCheckResponse>((resolve) => {
      const pushError = () => pushErrorBase(
        [
          'セッションの連携に失敗しました。',
          'この画面を閉じて、注文データ管理アプリから再度連携をお試しください。',
        ],
        checkToken,
        true,
      ).then((res) => resolve(res));
      dispatch(apiActions.run(new ApiCheckCf2PPMToken({
          kijshopCd,
          token: query.token,
        }),
        {
          onSuccess: (res: ResponseBase<Cf2PPMTokenCheckResponse>) => {
            if (res.body?.data && Number(res.error.errorCode) === 200) {
              const sessionToken = res.body.data?.sessionId || '';
              const fSessionToken = res.body.data?.token || '';
              ApiBaseBack.session = sessionToken;
              ApiBaseFront.session = fSessionToken;
              new SessionTokenManager().save(sessionToken, fSessionToken);
              console.log(res.body.data);
              resolve(res.body.data);
            } else {
              pushError();
            }
          },
          onError: (e) => {
            pushError();
          },
        },
        { ignoreSystemError: true },
      ));
    });
  }, [query, dispatch, kijshopCd, pushErrorBase]);

  const getCommonData = useCallback((checkData: Cf2PPMTokenCheckResponse) => {
    return new Promise<void>((resolve) => {
      /* ログインと同機能 */
      dispatch(commonActions.getData(
        kijshopCd,
        undefined,
        {
          onSuccess: () => {
            const isStaff = !checkData.machineId;
            const machineId = !isStaff
              ? checkData.machineId
              : store.getState().common.staffInfo?.staffId ?? '';
            dispatch(localStorageActions.setData(kijshopCd, 'shopName', checkData.shopName || ''));
            dispatch(localStorageActions.setData(kijshopCd, 'isAdmin', Boolean(checkData.isAdmin) || false));
            dispatch(localStorageActions.setMachineId(machineId));
            dispatch(localStorageActions.setLoginMode(isStaff ? 'STAFF' : 'ADMIN'));
            dispatch(apiActions.versionCheck(
              {
                xmlVersion: '',
                xml: '',
                version: '',
              },
              {
                onSuccess: () => {
                  dispatch(commonActions.getData(kijshopCd));
                  dispatch(localStorageActions.getLocalData(kijshopCd));
                  resolve();
                },
              },
            ));
          },
        },
      ));
    });
  }, [dispatch, kijshopCd]);

  /* 保存期限取得 */
  const getShopData = useCallback(() => {
    return new Promise<ShopExpiredDataEntity>((resolve) => {
      const pushError = () => pushErrorBase(
        ['注文の取得に失敗しました。'],
        getShopData,
      ).then((res) => resolve(res));
      ;
      dispatch(apiActions.run(new ApiGetShopExpired({
        kijshopCd,
      }), {
        onSuccess: (res: ResponseBase<ShopExpiredDataEntity>) => {
          if (res?.body?.data && Number(res?.error?.errorCode) === 200) {
            resolve(res.body?.data);
          } else {
            pushError();
          }
        },
        onError: () => pushError(),
      }));
    });
  }, [
    dispatch, pushErrorBase,
    kijshopCd,
  ]);

  /* クラウドフォルダ注文詳細取得 */
  const getOrderData = useCallback(() => {
    return new Promise<CfOrderGetOneDataEntity>((resolve) => {
      const pushError = () => pushErrorBase(
        ['注文の取得に失敗しました。'],
        getOrderData,
      ).then((res) => resolve(res));
      ;

      dispatch(apiActions.run(new ApiCfOrderGetOne({
        kijshopCd,
        orderId: query.orderId || '',
      }), {
        onSuccess: (res: ResponseBase<CfOrderGetOneDataEntity>) => {
          if (res.body?.data && Number(res.error.errorCode) === 200) {
            resolve(res.body.data);
          } else {
            pushError();

          }
        },
        onError: (e) => pushError(),
      }));
    });
  }, [query, kijshopCd, dispatch, pushErrorBase]);

  const updateCloudFolderOrder = useCallback((shopOrderId: string, lastChangerId: string) => {
    return new Promise<void>((resolve) => {
      const pushError = () => pushErrorBase(
        ['注文情報の連携に失敗しました。'],
        () => updateCloudFolderOrder(shopOrderId, lastChangerId),
      ).then(() => resolve());

      dispatch(apiActions.run(new ApiCfOrderUpdate({
          kijshopCd,
          orderId: query.orderId,
          shopOrderId,
          lastChangerId,
        }),
        {
          onSuccess: (res: ResponseBase<{}>) => {
            if (Number(res.error?.errorCode) === 200) {
              resolve();
            } else {
              pushError();
            }
          },
          onError: () => pushError(),
        },
        { ignoreSystemError: true },
      ));
    });
  }, [dispatch, kijshopCd, pushErrorBase, query]);

  /* 注文作成 */
  const createOrder = useCallback((
    orderData: CfOrderGetOneDataEntity,
    shopData: ShopExpiredDataEntity,
  ) => {
    const {
      name,
    } = orderData;
    return new Promise<{ shopOrderId: string }>((resolve) => {
      const pushError = () => pushErrorBase(
        ['注文の作成に失敗しました'],
        () => createOrder(
          orderData,
          shopData,
        ).then((res) => resolve(res)),
      );
      dispatch(apiActions.run(
        new ApiNumberingOrder({}),
        {
          onSuccess: (res: ResponseBase<NumberingOrderResponse>) => {
            const orderNo = res.body.data?.orderNo;
            if (orderNo) {
              dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId: orderNo, lastName: name }).create({
                  success: () => {
                    dispatch(apiActions.run(new ApiMetaShopOrderPost(
                        {
                          kijshopCd,
                          shopOrderId: orderNo,
                          data: {
                            shopOrderId: orderNo,
                            lastUpdateDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                            createDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                            lastName: name,
                            note: `フォルダ番号 : ${query.orderId}`,
                            status: '注文作成中',
                            isOneStop: 1,
                            cfOrderId: orderData.id,
                            oneStopOrderDate: null,
                            oneStopOrderUser: null,
                          },
                        },
                      ),
                      {
                        onSuccess: () => {
                          resolve({ shopOrderId: orderNo });
                        },
                        onError: () => {
                        },
                      },
                      { ignoreSystemError: true },
                    ));
                  },
                  error: () => {
                  },
                },
                { oneStop: { orderExpiredDay: shopData.orderExpiredDay } },
              ));
            } else {
              pushError();
            }
          },
          onError: () => {
          },
        },
      ));
    });
  }, [kijshopCd, dispatch, pushErrorBase, query]);

  const main = useCallback(async () => {
    /* トークンチェック */
    const checkTokenResponse = await checkToken();
    /* ログイン情報の取得 */
    await getCommonData(checkTokenResponse);
    if (checkTokenResponse.shopOrderId) {
      // 注文がすでにある場合は、注文作成をせずに遷移を行う
      dispatch(push(RoutingPath.cnv.oneStopOrdersPreparation({
        kijshopCd: kijshopCd,
        shopOrderId: checkTokenResponse.shopOrderId,
      })));
      return;
    }
    /* クラウドフォルダの注文詳細の取得 */
    const orderData = await getOrderData();
    /* 店舗情報の取得 */
    const shopInfo = await getShopData();
    /* PPM注文情報の作成 */
    const { shopOrderId } = await createOrder(orderData, shopInfo);
    /* PPM→クラウドフォルダーへ注文番号の登録連携 */
    const machineId = checkTokenResponse.machineId || (store.getState().common.staffInfo?.id ?? '');
    await updateCloudFolderOrder(shopOrderId, machineId);
    // /* 注文画面へ遷移 */
    dispatch(push(RoutingPath.cnv.oneStopOrdersPreparation({ kijshopCd: kijshopCd, shopOrderId: shopOrderId })));
  }, [
    kijshopCd,
    checkToken,
    getCommonData,
    getOrderData,
    createOrder,
    updateCloudFolderOrder,
    getShopData,
    dispatch,
  ]);

  useEffect(() => {
    /* 情報が欠けている場合はログイン画面にリダイレクト */
    if (!Object.values(query).every((v) => !!v) || !kijshopCd) {
      dispatch(push(RoutingPath.login));
      return;
    }
    main();
  }, [search]);

  return <>
    <div className="container">
      <div className="wrapper">
        <img
          src={logoImg}
          alt=""
        />
        <div className="animation">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  </>;
};
