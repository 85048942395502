import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { FreeTextType } from '../../../models/api/front/layout';
import { DispatchActionType, SelectorActionType } from './layout-data-actions';
import { CjContainer } from '../../model/cj-factory';
import {
  createXmlParam,
  FocusModeType,
  FrameContainerType, LayoutCompFilesType, LayoutCompFileType,
  OrderFrameGroupType,
  OrderPictureGroupType,
  StorePageDataType,
  StoreTemplateType,
} from './layout-data-type';
import { CjTool } from '../../../utilities/cj-tool';
import { CJ_CONTAINER_NAME, CJ_DPOBJ_NAME } from '../../model/cj-factory/cj-obj-name.collection';
import { AlbumManager, AlbumPage } from '../../albam/albam';
import { UiManager } from '../ui/ui.manager';
import { LayoutDataFormatter, TextImageData } from './layout-data.formatter';
import { TrimType } from '../trimming/trimming.manager';
import { store } from '../../../app/store';
import { xmlActions, padding } from '../../../xml/slice/xml-slice';
import { EditableImageManager } from '../editable-image/editable-image.manager';
import { dialogActions } from '../../../components/dialog/slice/dialog-slice';
import { PreviewType } from '../../../components/pages/layout/image-preview/layout.image-preview';
import { UuidGenerator } from '../../../utilities/uuid-generator';
import { XmlStructureModel } from '../../../xml/model/xml-structure-model';
import { ApiImagesDelete, ApiImagesPost } from '../../../api/front/images/api-images';
import { apiActions } from '../../../slices/api-slice';
import { layoutActions } from '../../../slices/layout-slice';
import { PreviewManager } from '../preview/preview.manager';
import { cloneDeep } from 'lodash';
import { TextImageManager } from '../text-image/text-image.manager';
import { SelectIdChecker } from '../../../utilities/select-id-checker';
import { FontFamilyChecker } from '../../../utilities/font-family-checker';
import { ThumbChecker } from '../../../utilities/thumb-checker';

export type TrimListParam = {
  id: number,
  data: TrimType
}
export class LayoutDataManager implements ILayoutEditorManagerBase {

  private uiManager!: UiManager;
  private albumManager!: AlbumManager;
  private editableImageManager!: EditableImageManager;
  private previewManager!: PreviewManager;
  private textManager!: TextImageManager;

  private maskContainers: CjContainer[] = [];
  private frameContainers: FrameContainerType[] = [];
  private backContainers: CjContainer[] = [];
  private imageAreaContainers: CjContainer[] = [];
  private textContainers: CjContainer[] = [];
  private imageContainers: CjContainer[] = [];
  private photoContainers: CjContainer[] = [];
  private rotateContainers: CjContainer[] = [];
  private scaleContainers: CjContainer[] = [];
  private editorContainers: CjContainer[] = [];

  // オーダー用
  private orderPages: StorePageDataType[];
  private templates: StoreTemplateType[];
  private frameGroups: OrderFrameGroupType[];
  private pictureGroups: OrderPictureGroupType[];
  private freeText: FreeTextType[];
  private freeGraphics: CjContainer[];
  private prevCovers: CjContainer[] = [];
  private coverFreeGraphics: CjContainer[] = [];
  private coverText: CjContainer[] = [];

  private additional: CjContainer[] = [];
  private windows: any[] = [];
  private trimList: { pageId: string, list: TrimListParam[] }[] = [];

  private guideMode: 'on' | 'off';
  private focusMode: FocusModeType = 'scale';
  private isError: boolean = false;
  private isThumbError: boolean = false;
  // 台紙モードかどうか
  private DAIFlag: boolean = false;
  private currentPage: AlbumPage | null = null;
  // サムネイルの情報
  private layoutCompFiles: PreviewType[] = [];
  private isOnSave: boolean = false;
  private formatPreviewList: LayoutCompFilesType = [];
  private fitNum: number = 5;

  constructor() {
    this.orderPages = [];
    this.templates = [];
    this.frameGroups = [];
    this.pictureGroups = [];
    this.freeGraphics = [];
    this.freeText = [];
    this.guideMode = 'on';
  }

  initialize(): void {
    this.uiManager.on('r->l:save-layout', (v) => {
      this.createXml(v, 'save');
    });
    this.uiManager.on('r->l:complete-layout', (v) => {
      this.createXml(v, 'comp');
    });
    this.uiManager.on('l->r:getOnSave', (v) => {
      v.callback(this.isOnSave);
    });
    this.uiManager.on('r->l:change-cover:start', () => {
      const _prevCovers = this.editorContainers.filter(v => {
        return store.getState().layout.pageTypeList.cover.find(cover => cover.pageTypeID === v.pageType);
        // return COVER_LIST.find(cover => cover.name === v.pageType);
      });
      const coverFreeGraphics = this.freeGraphics.filter(v => {
        return v.pageId === _prevCovers[0].pageId;
      })
      const coverText = this.textContainers.filter(v => {
        return v.pageId === _prevCovers[0].pageId;
      })
      this.prevCovers = _prevCovers.map((v) => cloneDeep(v));
      this.coverFreeGraphics = coverFreeGraphics.map((v) => cloneDeep(v));
      this.coverText = coverText.map((v) => cloneDeep(v));
      if (_prevCovers.length) {
        for (const cover of _prevCovers) {
          this.editorContainers = this.editorContainers.filter(v => v.pageId !== cover.pageId);
          this.imageAreaContainers = this.imageAreaContainers.filter(v => v.pageId !== cover.pageId);
          this.scaleContainers = this.scaleContainers.filter(v => v.pageId !== cover.pageId);
          this.rotateContainers = this.rotateContainers.filter(v => v.pageId !== cover.pageId);
          this.textContainers = this.textContainers.filter(v => v.pageId !== cover.pageId);
          this.freeGraphics = this.freeGraphics.filter(v => v.pageId !== cover.pageId);
          if (CjTool.checkPhotoInFrame(cover)) {
            const photo = CjTool.getImageContainer(cover);
            this.imageContainers = this.imageContainers.filter(v => v.editableImageId !== photo.editableImageId);
          };
        };
      }
    });
    this.uiManager.on('r->l:change-cover:end', () => {
      this.prevCovers.length = 0;
    });
  }

  destroy(): void {
    this.orderPages.length = 0;
    this.templates.length = 0;
    this.frameGroups.length = 0;
    this.pictureGroups.length = 0;
    this.freeGraphics.length = 0;
    this.freeText.length = 0;
    this.prevCovers.length = 0;
    this.additional.length = 0;
    this.fitNum = 5;
    this.guideMode = 'on';
    this.maskContainers = [];
    this.frameContainers = [];
    this.backContainers = [];
    this.imageAreaContainers = [];
    this.textContainers = [];
    this.imageContainers = [];
    this.photoContainers = [];
    this.rotateContainers = [];
    this.scaleContainers = [];
    this.editorContainers = [];
    this.layoutCompFiles = [];
    this.isOnSave = false;
    this.uiManager.off('r->l:save-layout');
    this.uiManager.off('r->l:complete-layout');
    this.uiManager.off('l->r:getOnSave');
    this.uiManager.off('r->l:change-cover:start');
    this.uiManager.off('r->l:change-cover:end');
  }

  di(
    uiMng: UiManager,
    albumMng: AlbumManager,
    editableImg: EditableImageManager,
    previewMng: PreviewManager,
    textMng: TextImageManager,
  ): void {
    this.uiManager = uiMng;
    this.albumManager = albumMng;
    this.editableImageManager = editableImg;
    this.previewManager = previewMng;
    this.textManager = textMng;
  }

  private async urlToFile(url: string) {
    return new Promise<File>((resolve) => {
      fetch(url)
        .then(response => response.blob())
        .then(blob => new File([blob], `image:${UuidGenerator.create()}.jpg`, { type: 'image/jpg' }))
        .then(file => {
          resolve(file);
        });
    });
  }

  private async formatPreview(list: PreviewType[]) {
    return new Promise<LayoutCompFileType[]>(async (resolve) => {
      const files: LayoutCompFileType[] = [];
      for (const preview of list) {
        files.push({
          file: await this.urlToFile(preview.url),
          album: preview.album,
        });
      }
      resolve(files);
    });
  }

  private async createCompFiles(kijshopCd: string, shopOrderId: string, orderId: string) {
    return new Promise<XmlStructureModel>(async (resolve, reject) => {
      this.layoutCompFiles.sort((a, b) => {
        if (b.album.type.indexOf('cover') !== -1) {
          return 1;
        } else {
          if (b.album.pageNo === '***') {
            return -1;
          }
          if (Number(a.album.pageNo.split('-')[0]) > Number(b.album.pageNo.split('-')[0])) {
            return 1;
          } else {
            return -1;
          }
        }
      });
      this.formatPreviewList = await this.formatPreview(this.layoutCompFiles);
      this.uiManager.emit('l->r:wait-loading', { message: 'サムネイル画像を生成しています...' });
      store.dispatch(xmlActions.uploadImage({
        kijshopCd,
        shopOrderId,
      }).layout(orderId).comp(this.formatPreviewList, (newFiles, xml, isError, isThumbError) => {
        if (!this.formatPreviewList.length) {
          this.isError = isError;
          if (isThumbError) {
            this.isThumbError = isThumbError;
          }
          resolve(xml);
          return;
        }
        // for (let i = 0; i < newFiles.length; i++) {
        //   this.layoutCompFiles[i].file = newFiles[i];
        // }
        Promise.all(newFiles.map(async (file) => await this.saveFile({
          file,
          kijshopCd,
          shopOrderId,
          orderId,
        }))).then(() => {
          if (isThumbError) {
            this.isThumbError = isThumbError;
          }
          this.uiManager.emit('l->r:wait-loading', { message: '書き込み中です...' });
          resolve(xml);
        });
      }));
      // this.uiManager.emit('r->l:add-layout-comp', {
      //   files,
      //   kijshopCd,
      //   shopOrderId,
      //   orderId,
      //   callback: (v) => {
      //     this.uiManager.emit('l->r:wait-loading', { message: '書き込み中です...' });
      //     store.dispatch(xmlActions.uploadImage({
      //       kijshopCd,
      //       shopOrderId,
      //     }).layout(orderId).comp(files, (newFiles) => {
      //       for (let i = 0; i < newFiles.length; i++) {
      //         this.layoutCompFiles[i].file = newFiles[i];
      //       }
      //       window.setTimeout(() => {
      //         resolve();
      //       }, 10000)
      //     }));
      //   },
      // });
    });
  }

  private saveFile(param: {
    file: File,
    kijshopCd: string,
    shopOrderId: string,
    orderId: string,
  }): Promise<void> {
    const { file, kijshopCd, shopOrderId, orderId } = param;
    return new Promise<void>((resolve) => {
      this.uiManager.emit('r->l:add-layout-comp', {
        file,
        kijshopCd,
        shopOrderId,
        orderId,
        callback: () => resolve(),
        onError: () => {
          this.isThumbError = true;
          resolve();
        },
      });
    });
  }
  // 削除された画像があるかチェック
  private async createXml(param: createXmlParam, type: 'save' | 'comp') {
    const { kijshopCd, shopOrderId, orderId } = param;
    this.isOnSave = true;
    store.dispatch(layoutActions.setIsSaving(true));
    this.previewManager.stop();
    if (this.albumManager.currentAlbum?.imageEditManager?.getCanvasEle() && store.getState().layout.page === 'edit') {
      this.previewManager.makePreviewImage(this.albumManager.currentAlbum?.imageEditManager?.getCanvasEle(), this.albumManager.currentAlbum);
    }
    if (type === 'comp') {
      if (store.getState().layout.page !== 'edit') {
        store.dispatch(layoutActions.setPage('edit'));
      }
      const activate = (album?: AlbumPage) => new Promise<void>(async (resolve) => {
        window.setTimeout(() => {
          if (!album) {
            resolve();
            return;
          }
          this.uiManager.emit('l->r:set-album', {
            albumPage: album, callback: () => {
              // album.imageEditManager?.setCanvasData()
              // UiManager.ins.emit('r->l:image-guide-on/off', { mode: 'on' });
              const overWrap = album.imageEditManager?.stage?.getChildByName(CJ_CONTAINER_NAME.overwrap);
              const guide = album.imageEditManager?.stage?.getChildByName(CJ_CONTAINER_NAME.guide);
              const frameLength = album.imageEditManager?.stage?.numChildren;
              const additional = album.imageEditManager?.stage?.getChildByName(CJ_CONTAINER_NAME.additional);
              const base = album.imageEditManager?.stage?.getChildByName(CJ_CONTAINER_NAME.base);
              // const png = album.imageEditManager?.stage?.getChildByName(CJ_CONTAINER_NAME.image);
              const baseMask = album.imageEditManager?.stage?.getChildByName(CJ_CONTAINER_NAME.baseMask);
              const pngs = this.freeGraphics.filter((elm) => elm.pageId === album.imageEditManager?.stage?.pageId);
              const texts = this.textContainers.filter((elm) => elm.pageId === album.imageEditManager?.stage?.pageId);
                if (frameLength) {
                  additional && album.imageEditManager?.stage?.setChildIndex(additional, frameLength - 1);
                  overWrap && album.imageEditManager?.stage?.setChildIndex(overWrap, frameLength - 1);
                  guide && album.imageEditManager?.stage?.setChildIndex(guide, frameLength - 1);
                  // png && album.imageEditManager?.stage?.setChildIndex(png, frameLength - 1);
                  baseMask && album.imageEditManager?.stage?.setChildIndex(baseMask, frameLength - 1);
                  for (const png of pngs) {
                    album.imageEditManager?.stage?.setChildIndex(png, frameLength - 1);
                  }
                  for (const text of texts) {
                    album.imageEditManager?.stage?.setChildIndex(text, frameLength - 1);
                  }
                  base && album.imageEditManager?.stage?.setChildIndex(base, 0);
                  album.imageEditManager?.stage?.stage.update();
                }
                if (album.imageEditManager?.getCanvasEle()) {
                  // this.previewManager.makePreviewImage(album.imageEditManager!.getCanvasEle(), album);
                  album.imageEditManager?.removeCanvasData();
                }
                resolve();
            },
          });
        });
      });
      for (const album of this.albumManager.pages) {
        await activate(album);
      }
    }
    // this.uiManager.emit('l->r:wait-loading', { message: '' });
    // if (true) return;

    UiManager.ins.emit('l->r:wait-loading', { message: '' });
    this.createCompFiles(param.kijshopCd, param.shopOrderId, param.orderId)
      .then((_xml) => {
        if (type === 'comp') {
          const targetXml = (() => {
            return _xml.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === param.orderId) ?? null;
          })()
          if (targetXml) {
            for (const parts of targetXml.parts?.partsData ?? []) {
              for (const page of parts.page?.pageData ?? []) {
                if (!page.viewModel.compositeFileName?.virtualPath) {
                  this.isThumbError = true;
                }
              }
            }
          }
          if (this.isError) {
            UiManager.ins.emit('l->r:wait-loading', { message: '' });
            store.dispatch(dialogActions.pushMessage({
              title: '確認',
              message: [
                `レイアウト情報の保存に失敗しました。`,
                `再度、「レイアウト完了」ボタンをクリックしてください。`,
              ],
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    store.dispatch(dialogActions.pop());
                  },
                },
              ],
            }))
            return;
          }
        }
        LayoutDataFormatter.jsonToXml(
          param.kijshopCd,
          param.orderId,
          param.shopOrderId,
          _xml,
          this.albumManager,
          this.editorContainers,
          this.imageContainers,
          this.textContainers,
          this.freeGraphics,
          this.trimList,
          this.editableImageManager,
          this.formatPreviewList,
          type,
          this.fitNum,
          this.textManager,
        ).then(async ({ xml, deleteIdList, changeOrderSelectData, textData, metaData, isWrongFont }) => {
          await this.saveAndDeleteTextImage(textData, { ...param });
          store.dispatch(xmlActions.layout({
            kijshopCd: param.kijshopCd,
            shopOrderId: param.shopOrderId,
            orderId: param.orderId,
          }).update(
            xml,
            type,
            param.startDate,
            {
              success: async () => {
                await this.deletePngImages({
                  list: deleteIdList,
                  kijshopCd,
                  shopOrderId,
                });
                if (type === 'comp') {
                  await SelectIdChecker.check(xml, param.kijshopCd, param.shopOrderId, param.orderId, true);
                  await FontFamilyChecker.check(xml, param.kijshopCd, param.shopOrderId, param.orderId, true);
                  if (this.isThumbError) {
                    await ThumbChecker.check(xml, param.kijshopCd, param.shopOrderId, param.orderId, true);
                  }
                }
                param.callback();
                store.dispatch(layoutActions.setIsSaving(false));
              },
              error: (res) => {
                UiManager.ins.emit('l->r:wait-loading', { message: '' });
                store.dispatch(dialogActions.pop());
                UiManager.ins.emit('l->r:push-dialog', {
                  title: '確認',
                  message: [res.message],
                  buttons: [
                    {
                      label: 'OK',
                      callback: () => {
                      },
                    },
                  ],
                });
              },
            },
            { albumMng: this.albumManager },
            {
              datas: store.getState().layout.albumSettingData,
              pages: store.getState().layout.albumPages,
              changeOrderSelectData,
              metaData: {
                shopOrderId,
                kijshopCd,
                orderId: padding(Number(orderId), 3),
                data: (() => {
                  const _data = store.getState().layout.metaData ?? {
                    orderId: '',
                    note: '',
                    checkChangeTemplateList: '',
                    imageSortType: '',
                    pictureData: '',
                  }
                  return {
                    ..._data,
                    pictureData: JSON.stringify(metaData),
                  }
                }
                )(),
              },
            },
          ));
        });
      })
      // .catch(() => {
      // UiManager.ins.emit('l->r:wait-loading', { message: '' });
      // store.dispatch(dialogActions.pushMessage({
      //   title: '確認',
      //   message: [
      //     `レイアウトの保存に失敗しました。`,
      //     `再度、保存処理を実行して下さい。`,
      //   ],
      //   buttons: [
      //     {
      //       label: 'OK',
      //       callback: () => {
      //         store.dispatch(dialogActions.pop());
      //         return;
      //       },
      //     },
      //   ],
      // }))
    // })
  }

  private deletePngImages = async (
    param: {
      list: string[],
      kijshopCd: string,
      shopOrderId: string,
    },
  ): Promise<void> => new Promise(async (resolve) => {
    const { list, kijshopCd, shopOrderId } = param;
    const pathList = this.editableImageManager.list.filter((v) => list.includes(v.id) && v.id !== '')
      .map((v) => `${kijshopCd}/${shopOrderId}/FreeGraphics/${v.path}`);
    await Promise.all(pathList.map((v) => new Promise<void>((_resolve) => {
      store.dispatch(apiActions.run(
        new ApiImagesDelete({
          kijshopCd,
          shopOrderId,
          kind: '5',
          filename: v,
        }),
        {
          onSuccess: () => _resolve(),
          onError: () => _resolve(),
        },
        {
          ignoreSystemError: true,
        },
      ));
    })));
    resolve();
  });

  private imageDeleteAsync = (path: string, shopData: { kijshopCd: string, shopOrderId: string }) => new Promise<void>((resolve) => {
    const _path = path.replace('Except_Real/', '');
    store.dispatch(apiActions.run(
      new ApiImagesDelete({
        kijshopCd: shopData.kijshopCd,
        shopOrderId: shopData.shopOrderId,
        kind: '4',
        filename: _path,
      }),
      {
        onSuccess: () => resolve(),
      },
    ));
  });

  private imageSaveAsync = (file: File, shopData: { kijshopCd: string, shopOrderId: string }) => new Promise<void>((resolve) => {
    store.dispatch(apiActions.run(
      new ApiImagesPost({
        kind: '4',
        data: file,
        kijshopCd: shopData.kijshopCd,
        shopOrderId: shopData.shopOrderId,
        filename: file.name,
      }),
      { onSuccess: () => resolve() },
    ));
  });
  private saveAndDeleteTextImage = async (textData: TextImageData, shopData: { kijshopCd: string, shopOrderId: string }) => {
    await Promise.all(textData.deleteData.map((v) => this.imageDeleteAsync(v, shopData)));
    await Promise.all(textData.textData.map((v) => this.imageSaveAsync(v, shopData)));
  };

  // data.dispatchをラップ
  async dispatch(action: DispatchActionType, payload: any) {
    // const payload = lodash.cloneDeep(_payload)
    switch (action) {
      case 'addLayoutEdit':
        this.orderPages = [...this.orderPages, payload];
        break;
      case 'setLayoutEdit':
        this.orderPages = payload;
        break;
      case 'setTemp':
        this.templates = [...this.templates, payload];
        break;
      case 'setFrame':
        this.frameGroups = [...this.frameGroups, payload];
        break;
      case 'setPicture':
        this.pictureGroups = [...this.pictureGroups, payload];
        break;
      case 'setFreeGraphic':
        this.freeGraphics = [...this.freeGraphics, payload];
        break;
      case 'removeFreeGraphic':
        this.freeGraphics = this.freeGraphics.filter(v => v.id !== payload.id);
        break;
      case 'changeGuideMode':
        this.guideMode = payload;
        break;
      case 'setMaskContainer':
        this.maskContainers = [...this.maskContainers, payload];
        break;
      case 'setFrameContainer':
        const targetFrame = this.frameContainers.find((elm) => elm.pageId === payload.pageId && elm.photoAreaId === payload.photoAreaId);
        if (targetFrame) {
          targetFrame.container.push(payload);
        } else {
          this.frameContainers.push({ pageId: payload.pageId, photoAreaId: payload.photoAreaId, container: [payload] });
        }
        this.frameContainers = [...this.frameContainers, payload];
        break;
      case 'setImageAreaContainer':
        this.imageAreaContainers = [...this.imageAreaContainers, payload];
        break;
      case 'setImageContainer':
        const targetImage = this.imageContainers.find(elm => elm.pageId === payload.pageId && elm.photoAreaId === payload.image.photoAreaId);
        if (targetImage) {
          const targetImageIndex = this.imageContainers.indexOf(targetImage);
          this.imageContainers.splice(targetImageIndex, 1, payload.image);
        } else {
          this.imageContainers = [...this.imageContainers, payload.image];
        }
        this.imageContainers.forEach((image) => {
          const list = this.imageContainers.filter((v) => v.pageId === payload.pageId);
          if (this.imageContainers.length === this.imageContainers.indexOf(image) + 1) {
            list.forEach(v => {
              v.photoSeqNo = list.indexOf(v) + 1;
            });
          }
        });
        break;
      case 'setPhotoContainer':
        this.photoContainers = [...this.photoContainers, payload];
        break;
      case 'setBackContainer':
        this.backContainers = [...this.backContainers, payload];
        break;
      case 'setTextContainer':
        this.textContainers = this.textContainers.filter((v) => v.textId !== payload.textId);
        this.textContainers = [...this.textContainers, payload];
        break;
      case 'setRotateContainer':
        this.rotateContainers = [...this.rotateContainers, payload];
        break;
      case 'setScaleContainer':
        this.scaleContainers = [...this.scaleContainers, payload];
        break;
      case 'setEditorContainer':
        this.editorContainers = [...this.editorContainers, payload];
        break;
      case 'setRotation':
        if (payload.name === 'photo-container') {
          const target = this.photoContainers.filter((elm) => elm.pageId !== payload.pageId && elm.photoAreaId !== payload.photoAreaId);
          target.push(payload);
        } else {
          const target = this.scaleContainers.find((elm) => {
            return elm.pageId === payload.pageId && elm.photoAreaId === payload.photoAreaId;
          });
          if (!target) return;
          const rotateContainer = CjTool.getRotateContainer(target);
          rotateContainer.rotation = payload.rotation;
        }
        break;
      case 'setScale':
        if (payload.name === 'photo-container') {
          const target = this.photoContainers.filter((elm) => elm.pageId !== payload.pageId && elm.photoAreaId !== payload.photoAreaId);
          target.push(payload);
        } else {
          const target = this.editorContainers.find((elm) => {
            return elm.pageId === payload.pageId && elm.photoAreaId === payload.photoAreaId;
          });
          if (!target) return;
          const scaleContainer = CjTool.getScaleContainer(target);
          scaleContainer.scaleX = payload.scaleX;
          scaleContainer.scaleY = payload.scaleY;
        }
        break;
      case 'setMove':
        if (payload.name === 'image-container') {
          const target = this.imageContainers.filter((elm) => elm.pageId !== payload.pageId && elm.photoAreaId !== payload.photoAreaId);
          target.push(payload);
        } else {
          const target = this.editorContainers.find((elm) => {
            return elm.pageId === payload.pageId && elm.photoAreaId === payload.photoAreaId;
          });
          if (!target) return;
          target.set({ x: payload.x, y: payload.y });
        }
        break;
      case 'removeFrame':
        this.editorContainers = this.editorContainers.filter(v => !(v.pageId === payload.pageId && v.photoAreaId === payload.photoAreaId));
        break;
      case 'removeImage':
        this.imageContainers = this.imageContainers.filter(v => !(v.editableImageId === payload.editableImageId && v.photoAreaId === payload.photoAreaId));
        break;
      case 'removeTextContainer':
        this.textContainers = this.textContainers.filter((v) => v.textId !== payload.textId);
        break;
      case 'setFocusMode':
        this.focusMode = payload;
        break;
      case 'setCurrentPage':
        this.currentPage = payload;
        break;
      case 'setFitNum':
        this.fitNum = payload;
        break;
      case 'setAdditional':
        this.additional = this.additional.filter(v => v.pageId !== payload.pageId);
        this.additional = [...this.additional, payload];
        break;
      case 'setTrimData':
        if (this.trimList.length) {
          for (const v of this.trimList) {
            if (Boolean(this.trimList.find(v => v.pageId === payload.pageId))) {
              if (v.pageId === payload.pageId) {
                v.list = v.list.filter(v2 => v2.id !== payload.id);
                v.list = [...v.list, { id: payload.id, data: payload.data }];
                v.list.sort((a, b) => a.id - b.id);
              }
            } else {
              this.trimList = [...this.trimList, {
                pageId: payload.pageId,
                list: [{ id: payload.id, data: payload.data }],
              }];
            }
          }
        } else {
          this.trimList = [{ pageId: payload.pageId, list: [{ id: payload.id, data: payload.data }] }];
        }
        break;
      case 'setDAIFlag':
        this.DAIFlag = payload;
        break;
      case 'changeTemp':
        this.editorContainers = this.editorContainers.filter(elm => elm.pageId !== payload);
        this.imageAreaContainers = this.imageAreaContainers.filter(elm => elm.pageId !== payload);
        this.scaleContainers = this.scaleContainers.filter(elm => elm.pageId !== payload);
        this.rotateContainers = this.rotateContainers.filter(elm => elm.pageId !== payload);
        this.textContainers = this.textContainers.filter(elm => elm.pageId !== payload);
        break;
      case 'setPreviewList':
        const cover = payload.list.cover;
        const top = payload.list.top;
        const page = payload.list.page;
        const end = payload.list.end;
        const option = payload.list.option;
        this.layoutCompFiles = [...cover, ...top, ...page, ...end, ...option];
        break;
      case 'removePreviewList':
        this.layoutCompFiles = this.layoutCompFiles.filter(v => v.album.id !== payload);
        break;
      case 'setWindows':
        this.windows = this.windows.filter(v => v.pageId !== payload.pageId);
        this.windows = [...this.windows, payload];
        break;
      case 'removeEditorContainer':
        this.editorContainers = this.editorContainers.filter(v => v.id !== payload.id);
        break;
      default:
        break;
    }
  }

  selector(action: SelectorActionType, payload?: any): any {
    switch (action) {
      case 'getAlbum':
        return this.editorContainers;
      case 'getEditors':
        return this.editorContainers.filter((elm) => elm.pageId === payload);
      case 'getImageAreas':
        return this.imageAreaContainers.filter((elm) => elm.pageId === payload && !elm.deleteFlag);
      case 'getAllImageAreas':
        return this.imageAreaContainers.filter((elm) => elm.pageId === payload);
      case 'getImageAreasDeleteIn':
        return this.imageAreaContainers.filter((elm) => elm.pageId === payload);
      case 'getImageArea':
        return this.imageAreaContainers.find((elm) => elm.pageId === payload.pageId && elm.photoAreaId === payload.photoAreaId && !elm.deleteFlag);
      case 'getImageAreaDeleteIn':
        return this.imageAreaContainers.find((elm) => elm.pageId === payload.pageId && elm.photoAreaId === payload.photoAreaId);
      case 'getScaleContainers':
        return this.scaleContainers.filter((elm) => elm.pageId === payload);
      case 'getScaleContainer':
        return this.scaleContainers.find((elm) => elm.pageId === payload.pageId && elm.photoAreaId === payload.photoAreaId);
      case 'getRotateContainers':
        return this.rotateContainers.filter((elm) => elm.pageId === payload);
      case 'getFreeGraphics':
        return this.freeGraphics.filter((elm) => elm.pageId === payload);
      case 'getTexts':
        return this.textContainers.filter((elm) => elm.pageId === payload);
      case 'getText':
        return this.textContainers.find((elm) => elm.textId === payload);
      case 'getLogoArea':
        const area = this.imageAreaContainers.filter((v) => v.logoFlag);
        if (payload) {
          return area.filter((v) => CjTool.checkPhotoInFrame(v));
        } else {
          return area;
        }
      case 'getFocusMode':
        return this.focusMode;
      case 'getCurrentPage':
        return this.currentPage;
      case 'getAdditional':
        return this.additional.find((elm) => elm.pageId === payload);
      case 'getDAIFlag':
        return this.DAIFlag;
      case 'getImages':
        return this.imageContainers;
      case 'getPreviewList':
        return this.layoutCompFiles;
      case 'getFitNum':
        return this.fitNum;
      case 'getPrevCover':
        return this.prevCovers;
      case 'getCoverFreeGraphics':
        return this.coverFreeGraphics;
      case 'getCoverText':
        return this.coverText;
      default:
        break;
    }
  }

  getAdditionalWindow(photoAreaId: string, pageId: string) {
    if (this.selector('getDAIFlag')) {
      const targetWindow = this.windows.find((v) => v.pageId === pageId);
      const photoAreaData = this.albumManager.currentAlbum?.parseData.templateData.photoarea;
      const targetPa = photoAreaData.find((elm: any) => elm.$.photoareaID === photoAreaId);
      const i = photoAreaData.indexOf(targetPa);
      const window = targetWindow.data.find((v: any) => v.id === `${CJ_DPOBJ_NAME.additionalWindow}:${i + 1}`);
      return window;
    }
  }

}
