import { ApiBaseFront } from '../api-base-front';

export class ApiMetaShopOrderCheck extends ApiBaseFront {
  constructor(param: { kijshopCd: string, shopOrderId: string }) {
    super(
      'POST',
      `/api/v1/meta/shopOrder/check`,
      'JSON',
      param,
    );
  }
}

