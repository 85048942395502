import { ApiBaseFront } from "../api-base-front";

type Params = {
  kijshopCd: string,
  shopOrderId: string,
  be_debug?: 1 | 2,
};

export class ApiCreateSelectId extends ApiBaseFront {
  constructor(param: Params) {
    const {
      kijshopCd,
      shopOrderId,
      be_debug,
    } = param;

    super(
      'POST',
      `/api/v1/request/${kijshopCd}/${shopOrderId}/create/selectId`,
      'JSON',
      {be_debug},
    );
  }
}
