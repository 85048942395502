import { Button } from '../../ui/button/button';
import { TextArea } from '../../ui/textarea/textarea';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import './image-upload.scss';
import { FileDropZone } from '../../ui/file-drop-zone/file-drop-zone';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { SaveFavoriteBrand } from '../../dialog/unique/save-favorite-brand';
import iconPrint from '../../../assets/img/icon/print.svg';
import iconStar from '../../../assets/img/icon/star.svg';
import iconDelete from '../../../assets/img/icon/delete.svg';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { ordersActions, OrdersSliceState } from '../../../slices/orders-slice';
import { ProductDetailGetResponse } from '../../../models/api/front/product-detail';
import { useParams } from 'react-router-dom';
import { apiActions } from '../../../slices/api-slice';
import {
  ApiProductDetailDelete,
  ApiProductDetailGet,
} from '../../../api/front/product-detail/api-product-detail';
import { ResponseBase } from '../../../api/response-base';
import { orderPreparationActions } from '../../../slices/order-preparation-slice';
import { FolderDropZone } from '../../ui/folder-drop-zone/folder-drop-zone';
import { LayoutThumbnailZone } from '../../ui/none-drop-zone/layout-thumbnail-zone';
import { ApiImageCheckPost } from '../../../api/front/image-check/api-image-check';
import { ImageCheckPostResponse } from '../../../models/api/front/image-check';
import { XmlStructureOrderInfoData } from '../../../xml/model/xml-structure-model';
import {
  ApiMetaOrderDelete,
  ApiMetaOrderGet,
  ApiMetaOrderPost,
  MetaOrderGetResponse,
} from '../../../api/front/meta/api-meta-order';
import { xmlActions } from '../../../xml/slice/xml-slice';
import { setItemSelect } from '../../../slices/orders/item-detail/item-detail';
import { LoadingPopup } from '../../ui/loading/loading-popup';
import { ApiImagesGetOne, ImagesGetResponse } from '../../../api/front/images/api-images';
import { VisibleConditionValueGetter } from '../../../manager/visible-condition-value-getter';
import {
  ApiMetaShopOrderGet,
  ApiMetaShopOrderPost,
  MetaShopOrderGetResponse,
} from '../../../api/front/meta/api-meta-shop-order';
import { DateFormatter } from '../../../utilities/date-formatter';
import { cloneDeep } from 'lodash';

type ImageUploadProps = {
  memo: string,
  orderInfoDataStructureModel: XmlStructureOrderInfoData,
  metaDelete: (orderId: string, num: number, list: string[]) => void,
  orderIdList: string[],
  creating: boolean,
  enableLabOrder: boolean,
  uploadImageList: ImagesGetResponse[],
  insertIndex: number,
};

export const convertResponseToOrderData = (_order: XmlStructureOrderInfoData, _options: XmlStructureOrderInfoData[], visibleCondition: VisibleConditionValueGetter): Partial<OrdersSliceState> => {
  const order = _order.xml;
  const cover = _order.parts?.partsData?.find((v) => v.xml.viewModel && !v.xml.viewModel.type)?.xml;
  const genuine = _order.parts?.partsData?.find((v) => v.xml.viewModel && v.xml.viewModel.type === 'main')?.xml;
  const options = _options.filter((v) => v.xml.metaModel.parentId === order.metaModel.id);
  return ({
    itemInfo: order.viewModel.category && {
      category: { value: order.viewModel.category?.id || '', label: order.viewModel.category?.name || '' },
      tag: { value: order.viewModel.productTagId || '', label: order.viewModel.productTagId || '' },
    },
    itemDetail: {
      coverSelect: { value: order.viewModel.coverId || '', label: '' },
      coverColorSelect: {
        value: order.viewModel.coverColorId || cover?.viewModel.cover?.color || '',
        label: cover?.viewModel.cover?.colorName || '',
      },
      itemSelect: { value: order.viewModel.item?.id || '', label: order.viewModel.item?.name || '' },
      boardSelect: { value: order.viewModel.boardInfo || '', label: '' },
      orderMethod: { value: order.viewModel.orderMethod?.id || '', label: order.viewModel.orderMethod?.name || '' },
      designType1: { value: order.viewModel.designType?.templateGroupId || '', label: '' },
      designType2: {
        value: order.viewModel.designType?.templateId || '',
        label: order.viewModel.designType?.name || '',
      },
      // quantity: Number(res.infoOutputCount) || 0,
      quantity: Number(order.viewModel.infoOutputCount) || 0,
    },
    descriptionInfo: order.viewModel.descriptionInfo && {
      name: order.viewModel.descriptionInfo.name,
      date: { value: order.viewModel.descriptionInfo.date, alert: false },
      formatType: '',
      formatCase: '',
      photographLocation: order.viewModel.descriptionInfo.location,
      descriptionLocations: order.viewModel.descriptionInfo.pageType && Object.keys(order.viewModel.descriptionInfo.pageType || {}).map((v) => v),
    },
    cover: cover && {
      laminate: {
        value: cover.viewModel.infoSurface?.laminateID || '',
        label: cover.viewModel.infoSurface?.name || '',
      },
      color: { value: cover.viewModel.cover?.color || '', label: cover.viewModel.cover?.colorName || '' },
      coverProcessing: { value: cover.viewModel.leaf?.color || '', label: cover.viewModel.leaf?.colorName || '' },
      leafText1: cover.viewModel.leaf?.text1 || '',
      leafText2: cover.viewModel.leaf?.text2 || '',
    },
    genuine: genuine && {
      laminate: {
        value: genuine.viewModel.infoSurface?.laminateID || '',
        label: genuine.viewModel.infoSurface?.name || '',
      },
      genuineProcessing: { value: genuine.viewModel.leaf?.color || '', label: genuine.viewModel.leaf?.colorName || '' },
      leafText1: genuine.viewModel.leaf?.text1 || '',
      leafText2: genuine.viewModel.leaf?.text2 || '',
      additional: { value: genuine.viewModel.additionalInfo?.[0] || '', label: '' },
      additionalMulti: genuine.viewModel.additionalInfo?.[0] || '', // TODO
      chronologies: {
        value: genuine.viewModel.chronology?.chronologyID || '',
        label: genuine.viewModel.chronology?.chronologyName || '',
      },
      page: Number(genuine.viewModel.pageCount) || 1,
    },
    useImage: {
      image: [],
    },
    option: options.length ? [...options].map((v, i) => ({
      tag: { value: v.xml.viewModel.productTagId || '', label: v.xml.viewModel.productTagId || '' },
      item: {
        value: v.xml.viewModel.item?.id || '',
        label: v.xml.viewModel.item?.name || '',
        serviceId: v.xml.viewModel.category?.id || '',
        serviceName: v.xml.viewModel.category?.name || '',
      },
      board: { value: v.xml.viewModel.boardInfo || '', label: '' }, // TODO
      quantity: Number(v.xml.viewModel.infoOutputCount) || 1,
      cover: v.parts?.partsData?.[0]?.xml.viewModel.infoSurface?.laminateID ? { // TODO
        laminate: { value: v.parts.partsData[0].xml.viewModel.infoSurface.laminateID, label: '' },
      } : { laminate: { value: '', label: '' } },
      genuine: v.parts?.partsData?.[1]?.xml.viewModel.infoSurface?.laminateID ? { // TODO
        laminate: { value: v.parts.partsData[1].xml.viewModel.infoSurface.laminateID, label: '' },
      } : { laminate: { value: '', label: '' } },
      descriptionLocations: Object.keys(v.xml.viewModel.descriptionInfo?.pageType || {}).map((key) => key),
      layoutFinish: v.xml.metaModel.layoutFinish || '',
      partsCount: '',
      edit: true,
      pageTypes: [],
    })) : [],
    remarks: {
      remarks: order.viewModel.remarks || '',
    },
    mailRemarks: {
      mailRemarks: order.viewModel.mailRemarks || '',
    },
    orderInfo: {
      proFlg: (() => {
        return Boolean(visibleCondition?.visibleData.orderInfo.o_isCategorySap?.visibleCondition?.find((v) => v.value === order.viewModel.category?.id));
        // switch (order.viewModel.category?.id) {
        //   case 'jp0299':
        //   case 'jp0306':
        //   case 'jp0337':
        //   case 'jp0343':
        //   case 'jp0350':
        //   case 'jp0374':
        //   case 'jp0386':
        //   case 'jp0390':
        //   case 'jp0395':
        //   case 'jp0399':
        //   case 'jp0410':
        //   case 'jp0411':
        //   case 'jp0425':
        //   case 'jp0426':
        //   case 'jp0427':
        //   case 'jp0444':
        //   case 'jp0453':
        //   case 'jp0454':
        //   case 'jp0506':
        //   case 'jp0539':
        //   case 'jp0550':
        //   case 'jp0559':
        //   case 'jp0570':
        //   case 'jp0573':
        //     return true;
        //   default:
        //     return false;
        // }
      })(),
      imageNum: {
        min: 0,
        max: 0,
      },
    },
  });
};

export const ImageUpload = (props: ImageUploadProps) => {
  const { memo: _memo, orderInfoDataStructureModel, metaDelete, orderIdList, creating, enableLabOrder, uploadImageList } = props;
  const orderInfoData = orderInfoDataStructureModel?.xml;
  const { kijshopCd, shopOrderId } = useParams<PathParams>();
  const {
    order,
    xml,
    orderingShopId,
    visibleCondition,
    selectedOrderIdArr,
    pageTypeList,
    updatingCustomerName,
    connecting,
    imageLessOrder,
  } = useAppSelector((state) => ({
    order: state.orderPreparation.currentOrder,
    xml: state.xml[shopOrderId],
    orderingShopId: state.common.orderingShopId,
    visibleCondition: state.createOrder.visibleCondition,
    selectedOrderIdArr: state.orderPreparation.selectedOrderIdArr,
    pageTypeList: state.common.pageTypeList,
    updatingCustomerName: state.orderPreparation.updatingCustomerName,
    connecting: state.api.connecting,
    imageLessOrder: state.orderPreparation.imageLessOrder,
  }), lodash.isEqual);
  const dispatch = useDispatch();
  const [memo, setMemo] = useState(_memo === 'null' ? '' : (_memo || ''));
  const [imageCount, setImageCount] = useState(0);
  const [imageCheckRes, setImageCheckRes] = useState<ImageCheckPostResponse[]>([]);
  const [optionImageCheckRes, setOptionImageCheckRes] = useState<{
    orderInfoDataId: string,
    checkRes: ImageCheckPostResponse[],
  }[]>([]);
  const [maxImageCount, setMaxImageCount] = useState((() => {
    let num = 0;
    orderInfoDataStructureModel?.parts?.partsData?.forEach((page) => {
      num += Number(page.page?.pageData?.length || 0);
    });
    return num;
  })());
  const [optionImageCount, setOptionImageCount] = useState(0);
  const [checkChangeTemplateList, setCheckChangeTemplateList] = useState('');
  const [imageSortType, setImageSortType] = useState('');
  const [deleting, setDeleting] = useState(false);
  const [pictureData, setPictureData] = useState('');
  const [waitRouting, setWaitRouting] = useState(false);
  const handlerChangeMemo = useCallback((v) => {
    setMemo(v);
  }, []);
  const handlerBlurMemo = useCallback(() => {
    if (memo !== _memo) {
      dispatch(apiActions.run(
        new ApiMetaOrderPost({
          kijshopCd,
          shopOrderId,
          // orderId: order?.orderId,
          // memo,
          orderId: orderInfoData.indexes[0] || '000',
          data: {
            orderId: orderInfoData.indexes[0] || '000',
            note: memo || '',
            checkChangeTemplateList,
            imageSortType,
            pictureData,
          },
        }),
        {
          onSuccess: () => {
            dispatch(apiActions.run(
              new ApiProductDetailGet({
                kijshopCd: kijshopCd,
                shopOrderId: shopOrderId,
              }),
              {
                onSuccess: (res: ResponseBase<ProductDetailGetResponse[]>) => {
                  dispatch(orderPreparationActions.setOrderList(res.body.data || [], kijshopCd));
                },
              },
            ));
          },
        },
      ));
    }
  }, [memo, checkChangeTemplateList, pictureData]);
  const handlerClickLayout = useCallback(() => {
    if (updatingCustomerName) {
      setWaitRouting(true);
      return;
    }
    const orderId = orderInfoData?.metaModel.id || '';
    const productNameId = orderInfoData?.viewModel?.item?.id;
    const url = `${window.origin}/#/${kijshopCd}/orders/${shopOrderId}/preparation/${orderId}/layout?productNameId=${productNameId}`;
    window.open(url, '_self');
    window.location.reload();
  }, [shopOrderId, orderInfoData?.metaModel.id, orderInfoData?.viewModel?.item?.id, updatingCustomerName]);
  useEffect(() => {
    if (waitRouting && !updatingCustomerName) {
      const orderId = orderInfoData?.metaModel.id || '';
      const productNameId = orderInfoData?.viewModel?.item?.id;
      const url = `${window.origin}/#/${kijshopCd}/orders/${shopOrderId}/preparation/${orderId}/layout?productNameId=${productNameId}`;
      window.open(url, '_self');
      window.location.reload();
    }
  }, [waitRouting, updatingCustomerName]);
  const handlerClickOrderEdit = useCallback(() => {
    if (orderInfoDataStructureModel && xml?.orderInfo?.infoData) {
      const data = convertResponseToOrderData(orderInfoDataStructureModel, xml.orderInfo.infoData, visibleCondition);
      dispatch(ordersActions.runAllApi({ orders: data }, kijshopCd, Boolean(xml?.info.metaModel?.dpNumber)));
    }
    dispatch(push(RoutingPath.cnv.ordersEdit({ kijshopCd, shopOrderId, orderId: orderInfoData?.metaModel.id || '' })));
  }, [shopOrderId, xml, visibleCondition]);
  const handlerClickPrint = useCallback(() => {
    dispatch(push(RoutingPath.cnv.ordersPrint({ kijshopCd, shopOrderId, orderId: orderInfoData?.metaModel.id || '' })));
  }, [shopOrderId, orderInfoData?.metaModel.id]);
  const handlerClickFavorite = useCallback(() => {
    dispatch(dialogActions.push({
      title: 'お気に入り商品保存',
      element: <SaveFavoriteBrand
        kijshopCd={kijshopCd}
        orderInfoDataStructureModel={orderInfoDataStructureModel}
      />,
      className: 'save_favorite_brand__ancestor',
      onBlurPop: true,
    }));
  }, [orderInfoDataStructureModel]);
  const handlerClickGarbage = useCallback(() => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: ['選択した注文情報を削除します。よろしいですか？'],
      buttons: [
        {
          label: 'いいえ',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'はい',
          callback: () => {
            // dispatch(orderPreparationActions.setOrderList([]));
            setDeleting(true);
            dispatch(dialogActions.pop());
            dispatch(xmlActions.order({
              kijshopCd, shopOrderId, callback: {
                success: () => {
                  dispatch(apiActions.run(
                    new ApiMetaOrderDelete({
                      kijshopCd: kijshopCd,
                      shopOrderId: shopOrderId,
                      orderId: (orderInfoData._indexes) ? orderInfoData._indexes[0] : '',
                    }),
                    {
                      onSuccess: () => {
                        const num = orderInfoDataStructureModel.xml.optionOrderInfoDataXml.length + 1;
                        metaDelete(orderInfoData._indexes[0], num, orderIdList);
                        dispatch(xmlActions.getXml(kijshopCd, shopOrderId, undefined, () => {
                          setDeleting(false);
                        }));
                        dispatch(orderPreparationActions.resetSelectedOrderId());
                      },
                      onError: () => {
                        dispatch(dialogActions.pushMessage({
                          title: '確認',
                          message: ['更新に失敗しました'],
                          buttons: [
                            {
                              label: 'OK',
                              callback: () => {
                                dispatch(dialogActions.pop());
                              },
                            },
                          ],
                        }));
                        setDeleting(false);
                      },
                    },
                  ));
                },
                error: () => {
                  dispatch(dialogActions.pushMessage({
                    title: '確認',
                    message: ['更新に失敗しました'],
                    buttons: [
                      {
                        label: 'OK',
                        callback: () => {
                          dispatch(dialogActions.pop());
                        },
                      },
                    ],
                  }));
                  setDeleting(false);
                },
              },
            }).delete(orderInfoData?.metaModel.id || ''));
            dispatch(orderPreparationActions.resetCompImages());
          },
        },
      ],
    }));
  }, [orderInfoData]);
  // - Effect -
  useEffect(() => {
    if (memo !== _memo && _memo !== 'null') {
      setMemo(_memo || '');
    }
  }, [_memo]);
  useEffect(() => {
    // 完成画像入稿時の画像枚数設置。xmlで保持してれば不要
    if (orderInfoData?.viewModel.orderMethod?.id === '40') {
      let checkResArr: {
        orderInfoDataId: string,
        checkRes: ImageCheckPostResponse[],
      }[] = [];
      dispatch(apiActions.run(
        new ApiImageCheckPost({
          kijshopCd,
          goodsId: orderInfoData.viewModel.item?.id || '',
          page: Number(orderInfoData?.orderParts?.orderPartsDataMain()?.viewModel.pageCount) || 99,
        }),
        {
          onSuccess: (res: ResponseBase<ImageCheckPostResponse[]>) => {
            // checkResArr = res.body.data || [];
            // setImageCheckRes(res.body.data || []);
            // setMaxImageCount(res.body.data?.[0]?.length || 0);
            const arr: ImageCheckPostResponse[] = [];
            const data = res.body.data;
            if (data?.[0]?.[0]?.fileName?.split('.')[0] === '*') {
              // data[0][0].fileName = `.${data[0][0].fileName}`;
              arr[0] = [];
              // [...new Array(Number(orderInfoData?.orderParts?.orderPartsDataMain()?.viewModel.pageCount || 0))].forEach(() => arr[0].push(data[0][0]));
              let copyIndex = data[0].length - 1;
              let endIndex = 999;
              const pageDataArr = orderInfoData.orderParts?.orderPartsDataArr?.flatMap((partsData) => partsData?.orderPage?.orderPageDataArr || []) || [];
              /* 表紙 */
              if (pageTypeList.cover.find((v) => v.pageTypeID === pageDataArr[0]?.viewModel.pageType) && data[0].length - 1 > copyIndex) {
                // if (COVER_LIST.find((v) => v.pageTypeID === pageDataArr[0]?.viewModel.pageType) && data[0].length - 1 > copyIndex) {
                copyIndex++;
              }
              /* TOP */
              if (pageTypeList.top.find((v) => v.pageTypeID === pageDataArr[0]?.viewModel.pageType) && data[0].length - 1 > copyIndex) {
                // if (pageDataArr[0]?.viewModel.pageType === 'jptg380013' && data[0].length - 1 > copyIndex) {
                copyIndex++;
              }
              /* END */
              if (pageTypeList.endCover.find((v) => v.pageTypeID === pageDataArr[pageDataArr.length - 1]?.viewModel.pageType)) {
                // if (pageDataArr[pageDataArr.length - 1]?.viewModel.pageType === 'jptg380015') {
                endIndex = pageDataArr.length;
              }
              while (arr[0].length < pageDataArr.length) {
                arr[0].push(data[0][copyIndex]);
                if (arr[0].length === endIndex) {
                  arr[0].push(data[0][data[0].length - 1]);
                }
              }
              data[0] = arr[0];
            } else {
              res.body.data?.forEach((imageCheckArr, i) => {
                arr[i] = [];
                imageCheckArr.forEach((imageCheck) => {
                  if (!arr[i].find((v) => v.fileName === imageCheck.fileName)) {
                    arr[i].push(imageCheck);
                  }
                });
              });
            }
            // setImageCheckRes(res.body.data || []);
            // setMaxImageCount(res.body.data?.[0]?.length || 0);
            setImageCheckRes(arr);
            setMaxImageCount(arr[0]?.length);
            orderInfoData.optionOrderInfoDataXml.forEach((v) => {
              dispatch(apiActions.run(
                new ApiImageCheckPost({
                  kijshopCd,
                  goodsId: v.viewModel.item?.id || '',
                  page: Number(v?.orderParts?.orderPartsDataMain()?.viewModel.pageCount) || 99,
                }),
                {
                  onSuccess: (opRes: ResponseBase<ImageCheckPostResponse[]>) => {
                    const data = opRes.body.data;
                    if (data?.[0]?.length) {
                      if (data?.[0]?.[0]?.fileName?.split('.')[0] === '*') {
                        let copyIndex = data[0].length - 1;
                        let endIndex = 999;
                        const pageDataArr = orderInfoData.orderParts?.orderPartsDataArr?.flatMap((partsData) => partsData?.orderPage?.orderPageDataArr || []) || [];
                        /* 表紙 */
                        if (pageTypeList.cover.find((v) => v.pageTypeID === pageDataArr[0]?.viewModel.pageType) && data[0].length - 1 > copyIndex) {
                          // if (COVER_LIST.find((v) => v.pageTypeID === pageDataArr[0]?.viewModel.pageType) && data[0].length - 1 > copyIndex) {
                          copyIndex++;
                        }
                        /* TOP */
                        if (pageTypeList.top.find((v) => v.pageTypeID === pageDataArr[0]?.viewModel.pageType) && data[0].length - 1 > copyIndex) {
                          // if (pageDataArr[0]?.viewModel.pageType === 'jptg380013' && data[0].length - 1 > copyIndex) {
                          copyIndex++;
                        }
                        /* END */
                        if (pageTypeList.endCover.find((v) => v.pageTypeID === pageDataArr[pageDataArr.length - 1]?.viewModel.pageType)) {
                          // if (pageDataArr[pageDataArr.length - 1]?.viewModel.pageType === 'jptg380015') {
                          endIndex = pageDataArr.length;
                        }
                        while (arr[0].length < pageDataArr.length) {
                          arr[0].push(data[0][copyIndex]);
                          if (arr[0].length === endIndex) {
                            arr[0].push(data[0][data[0].length - 1]);
                          }
                        }
                        data[0] = arr[0];
                      }
                      checkResArr.push({
                        orderInfoDataId: v.metaModel.id || '',
                        checkRes: data,
                      });
                      setOptionImageCheckRes(cloneDeep(checkResArr));
                    }
                  },
                  onError: () => {
                    // TODO リトライ
                    dispatch(apiActions.run(
                      new ApiImageCheckPost({
                        kijshopCd,
                        goodsId: v.viewModel.item?.id || '',
                        page: Number(v?.orderParts?.orderPartsDataMain()?.viewModel.pageCount) || 99,
                      }),
                      {
                        onSuccess: (opRes: ResponseBase<ImageCheckPostResponse[]>) => {
                          const data = opRes.body.data;
                          if (data?.[0]?.length) {
                            if (data?.[0]?.[0]?.fileName?.split('.')[0] === '*') {
                              let copyIndex = data[0].length - 1;
                              let endIndex = 999;
                              const pageDataArr = orderInfoData.orderParts?.orderPartsDataArr?.flatMap((partsData) => partsData?.orderPage?.orderPageDataArr || []) || [];
                              /* 表紙 */
                              if (pageTypeList.cover.find((v) => v.pageTypeID === pageDataArr[0]?.viewModel.pageType) && data[0].length - 1 > copyIndex) {
                                copyIndex++;
                              }
                              /* TOP */
                              if (pageTypeList.top.find((v) => v.pageTypeID === pageDataArr[0]?.viewModel.pageType) && data[0].length - 1 > copyIndex) {
                                copyIndex++;
                              }
                              /* END */
                              if (pageTypeList.endCover.find((v) => v.pageTypeID === pageDataArr[pageDataArr.length - 1]?.viewModel.pageType)) {
                                // if (pageDataArr[pageDataArr.length - 1]?.viewModel.pageType === 'jptg380015') {
                                endIndex = pageDataArr.length;
                              }
                              while (arr[0].length < pageDataArr.length) {
                                arr[0].push(data[0][copyIndex]);
                                if (arr[0].length === endIndex) {
                                  arr[0].push(data[0][data[0].length - 1]);
                                }
                              }
                              data[0] = arr[0];
                            }
                            checkResArr.push({
                              orderInfoDataId: v.metaModel.id || '',
                              checkRes: data,
                            });
                            setOptionImageCheckRes(checkResArr);
                          }
                        },
                      },
                      {
                        ignoreSystemError: true,
                      },
                    ));
                  },
                },
                {
                  ignoreSystemError: true,
                },
              ));
            });
          },
        },
      ));
    }
    /* 画像を選択しておまかせ画像枚数 */
    if (orderInfoData?.viewModel.orderMethod?.id === '10') {
      setImageCount(Number(orderInfoData?.orderPageBreak?.viewModel.data?.image.length || 0));
    }
  }, [orderInfoData?.viewModel.item?.id]);
  useEffect(() => {
    setOptionImageCount(optionImageCheckRes.flatMap((v) => v.checkRes?.[0]).length);
  }, [optionImageCheckRes]);
  useEffect(() => {
    if (orderInfoData?.viewModel.orderMethod?.id === '40') {
      if (!connecting && imageCount && imageCount < (maxImageCount + optionImageCount) && xml?.status.getOrderStatus(orderInfoData?.metaModel.id || '', xml) === 'ラボ発注可' && !imageLessOrder.find((v) => v === orderInfoData?.metaModel.id)) {
        dispatch(orderPreparationActions.setImageLessOrder(orderInfoData?.metaModel.id || ''));
        dispatch(apiActions.run(
          new ApiMetaShopOrderGet({
            kijshopCd,
            shopOrderId,
          }),
          {
            onSuccess: (res: ResponseBase<MetaShopOrderGetResponse>) => {
              if (!res?.body?.data?.createDate || res?.body?.data?.status !== 'ラボ発注可') {
                return;
              }
              if (res.body.data) {
                dispatch(apiActions.run(
                  new ApiMetaShopOrderPost({
                    kijshopCd,
                    shopOrderId,
                    data: {
                      ...res.body.data,
                      lastUpdateDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                      status: '注文作成済',
                    },
                  }),
                  {
                    onSuccess: () => {
                    },
                    onError: () => {
                    },
                  },
                  { ignoreSystemError: true },
                ));
              }
            },
          },
          { ignoreSystemError: true },
        ));
      }
    }
  }, [connecting, imageCount, maxImageCount, optionImageCount]);
  useEffect(() => {
    const infoData = xml?.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderInfoData?.metaModel.id);
    if (infoData?.xml?.viewModel.orderMethod?.id === '10') {
      const num = Number(infoData?.xml?.orderPageBreak?.viewModel.data?.image.length || 0);
      if (num !== imageCount) {
        setImageCount(num);
      }
    }
  }, [xml]);
  useEffect(() => {
    dispatch(apiActions.run(
      new ApiMetaOrderGet({
        kijshopCd,
        shopOrderId,
        orderId: orderInfoData?.indexes[0] || '000',
      }),
      {
        onSuccess: (res: ResponseBase<MetaOrderGetResponse>) => {
          if (res.body.data) {
            setMemo(res.body.data.note !== 'null' ? (res.body.data.note || '') : '');
            setCheckChangeTemplateList(res.body.data.checkChangeTemplateList);
            setImageSortType(res.body.data.imageSortType);
            setPictureData(res.body.data.pictureData ?? '');
          }
        },
      },
    ));
  }, [orderInfoData]);
  useEffect(() => {
    if (orderInfoData && orderInfoData.viewModel.orderMethod?.id === '40') {
      const filterOrderPageDataArr = orderInfoData.allOrderPageDataXml.filter((v) => v.viewModel.compositeFileName !== undefined);
      setImageCount(filterOrderPageDataArr.length);
    }
  }, [orderInfoData]);
  const ordering = Boolean(xml?.info?.xmlModel?.lnwOrder?.order?.[0]?.$?.orderDate);
  const visible = selectedOrderIdArr.find((v) => v === orderInfoData?.metaModel?.id);
  return (
    <div className="image_upload" style={{ display: !visible ? 'none' : '' }}>
      <div className="image_upload__header">
        <div className="image_upload__header__text">
          {/*{order?.surrogateProcess?.id === '10' ? (*/}
          {/*  <span className="img_index">{imageCount}/{maxImageCount}~{maxImageCount} 画像</span>*/}
          {/*) : (<></>)}*/}
          {/*{order?.surrogateProcess?.id === '40' ? (*/}
          {/*  <span className="img_index">{imageCount}/{Number(order?.orderPartsData?.[0]?.pageCount || 0) / 2 + 1} 画像</span>*/}
          {/*) : (<></>)}*/}
          <>{orderInfoData?.viewModel.orderMethod?.name || ''}</>
          {orderInfoData?.viewModel.orderMethod?.id === '10' ? (
            <span className="img_index">
              <span className={((imageCount < orderInfoData.imageCount.minImageCount) || (imageCount > orderInfoData.imageCount.maxImageCount)) ? 'alert_message' : ''}>{imageCount}</span>/
              <span className={imageCount < orderInfoData.imageCount.minImageCount ? 'alert_message' : ''}>{orderInfoData.imageCount.minImageCount}</span>~
              <span className={imageCount > orderInfoData.imageCount.maxImageCount ? 'alert_message' : ''}>{orderInfoData.imageCount.maxImageCount}</span> 画像</span>
          ) : (<></>)}
          {orderInfoData?.viewModel.orderMethod?.id === '40' ? (
            // <span className="img_index">{(orderInfoData?.allOrderPageDataXml.filter((v) => v.viewModel.compositeFileName?.realPath)?.length || 0)/*imageCount*/}/{maxImageCount + optionImageCheckRes.length} 画像</span>
            <span className="img_index">{imageCount}/{maxImageCount + optionImageCount} 画像</span>
          ) : (<></>)}
        </div>
        <Button
          label="レイアウト"
          onClick={handlerClickLayout}
          size="sm"
          disabled={
            ordering
            || creating
            || (orderInfoData?.viewModel.orderMethod?.id === '10' || orderInfoData?.viewModel.orderMethod?.id === '40')
          }
        />
        <Button
          label="注文編集"
          onClick={handlerClickOrderEdit}
          color="light"
          size="sm"
          disabled={ordering}
        />
        <Button
          onClick={handlerClickPrint}
          color="light"
          size="sm"
          icon={<img src={iconPrint} alt="" />}
          disabled={!enableLabOrder}
        />
        <Button
          onClick={handlerClickFavorite}
          color="light"
          size="sm"
          icon={<img src={iconStar} alt="" />}
        />
        <Button
          onClick={handlerClickGarbage}
          color="light"
          size="sm"
          icon={<img src={iconDelete} alt="" />}
          disabled={ordering}
        />
      </div>

      {orderInfoData.viewModel.orderMethod?.id === '40' ? (
        <span className='image_upload__alert'>
          データ作成時のミスが増えています。とくにセーフゾーンが含まれるレイヤーの消し忘れにご注意ください
        </span>
      ) : (<></>)}

      <div className="image_upload__body">
        {orderInfoData?.viewModel.orderMethod?.id === '10' ? (
          <FileDropZone
            // onGetTotalNum={(num) => setImageCount(num)}
            onGetTotalNum={() => { }}
            orderId={orderInfoData?.metaModel.id || null}
            pageBreak={orderInfoData?.orderPageBreak}
            orderInfoData={orderInfoData}
            ordering={ordering}
            maxPageCount={orderInfoData.imageCount.maxImageCount}
            uploadImageList={uploadImageList}
          />
        ) : (<></>)}
        {orderInfoData?.viewModel.orderMethod?.id === '20' ? (
          <LayoutThumbnailZone
            orderInfoData={orderInfoData}
          />
        ) : (<></>)}
        {orderInfoData?.viewModel.orderMethod?.id === '30' ? (
          <LayoutThumbnailZone
            orderInfoData={orderInfoData}
          />
        ) : (<></>)}
        {orderInfoData?.viewModel.orderMethod?.id === '40' ? (
          <FolderDropZone
            onGetTotalNum={() => { }}
            orderId={orderInfoData?.metaModel.id || null}
            // page={Number(orderInfoData?.orderParts?.orderPartsData(2)?.viewModel.pageCount) || 0}
            page={Number(orderInfoData.pageCount)}
            imagesCheck={imageCheckRes}
            orderInfoDataArr={xml?.orderInfo?.infoData || []}
            orderPageDataArr={orderInfoData.allOrderPageDataXml}
            orderInfoData={orderInfoData}
            ordering={ordering}
            optionImagesCheck={optionImageCheckRes}
            callbackChangeImageNum={(v) => {
              if (imageCount !== v) {
                setImageCount(v);
              }
            }}
          />
        ) : (<></>)}
      </div>

      <div className="image_upload__memo">
        <p>商品メモ（このメモは注文内容に含まれません。ラボへの指示など記載しないようにご注意ください）</p>
        <TextArea
          value={memo}
          onChange={(e) => handlerChangeMemo(e.target.value)}
          onBlur={handlerBlurMemo}
          disabled={ordering}
        />
      </div>
      {deleting ? (<LoadingPopup label="注文データを削除中です..." />) : (<></>)}
      {waitRouting ? (<LoadingPopup label="更新中です..." />) : (<></>)}
    </div>
  );
};
