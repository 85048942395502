import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import iconReturn from '../../../assets/img/icon/return.svg';
import { apiActions } from '../../../slices/api-slice';
import { newsListActions } from '../../../slices/news-list-slice';
import './news-list.scss';
import { ApiBase } from "../../../api/api-base";
import { useAppSelector } from '../../../app/hooks';
import lodash from 'lodash';
import LinkifyIt from 'linkify-it';

export const NewsList = () => {
  const linkify = useMemo(() => new LinkifyIt(), []);

  const dispatch = useDispatch();
  const { isSamePath } = useAppSelector((state) => ({
    isSamePath: state.newsList.isSamePath,
  }), lodash.isEqual);
  // - State -
  // -- お知らせ一覧情報 --
  const [newsList, setNewsList] = useState<string[]>([]);
  // - Callback -
  // -- 注文一覧に戻る --
  const handlerClickBackOrderList = useCallback(() => {
    dispatch(newsListActions.close());
  }, []);

  // - Effect -
  // -- 一覧データ取得 --
  useEffect(() => {
    setNewsList([]);
    dispatch(apiActions.onGetXml(
      'news',
      {
        onSuccess: (res: any) => {
          // ローカルに保存
          localStorage.setItem('news', res.body.data.slice(0, 50));
          // リンクに変換
          setNewsList(res.body.data.split('\n'));
        },
      },
    ));
    if (isSamePath) {
      dispatch(newsListActions.setIsSamePath(false));
    }
  }, [isSamePath]);

  const transformLink = (text: string): JSX.Element[] => {
    const matches = linkify.match(text);
    // リンク無
    if (!matches) return [<span key="text">{text}</span>];

    let lastIndex = 0;
    const result: JSX.Element[] = [];
    // リンク有
    matches.forEach((match: { raw: string; url: string; index: number }, index: number) => {
      if (lastIndex < match.index) {
        result.push(<span key={`text-${index}-before`}>{text.slice(lastIndex, match.index)}</span>);
      }
      if (match.url.match(/^https?:\/\/|^www\./)) {
        result.push(
          <a key={`link-${index}`} href={match.url} target="_blank" rel="noopener noreferrer">
            {match.raw}
          </a>
        );
      } else {
        result.push(<span key={`text-${index}-link`}>{match.raw}</span>);
      }
      lastIndex = match.index + match.raw.length;
    });
    if (lastIndex < text.length) {
      result.push(<span key="text-last">{text.slice(lastIndex)}</span>);
    }

    return result;
  };

  return (
    <div className="news_list">
      <div className="news_list__return" >
        <div className="news_list__btn" onClick={handlerClickBackOrderList}>
          <img src={iconReturn} alt="" />
          <div>注文一覧に戻る</div>
        </div>
      </div>
      <div className="news_list__header">
        お知らせ
      </div>
      <div className="news_list__body">
        {newsList.map((v, i) => (
          <div key={`news-list_${i}`} className="news_list__line">
            {transformLink(v)}
          </div>
        ))}
      </div>
    </div>
  );
};
