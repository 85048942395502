import EventEmitter from 'eventemitter3';
import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { EditableImage } from '../image-edit/editable-image';
import { AlbumPage, OrderParam } from '../../albam/albam';
import { TextFont, TextInfo, TextOption } from '../../../components/pages/layout/toolbar/layout.free-text';
import { DisplayObject } from 'createjs-module';
import { XmlStructureModel } from '../../../xml/model/xml-structure-model';
import { AlbumSettingData, PresetData } from '../../../slices/layout-slice';
import { CheckFinishType } from '../validation/validation.manager';
import { PreviewListType } from '../../../components/pages/layout/image-preview/layout.image-preview';
import { Pos } from '../../../models/pos';
import { LayoutInitType } from '../../../components/pages/layout/edit-template/layout.edit-template';
import { AdditionalShowType } from '../../../components/pages/layout/toolbar/layout.toolbar';
import { PostCardType } from '../../../components/pages/layout/select-template/layout.select-template';
import { Orientation } from 'get-orientation/browser';
import { CjContainer } from '../../model/cj-factory';
import { FileDetail } from '../../../api/front/cloud-folder/api-get-cloud-folder-file-detail';
import { ScreenColorType } from '../../../components/pages/layout/menu/layout.menu';

/**
 * シーケンスの方向
 *  l : LayoutEditor
 *  r : React
 */
type EventType = {
  // 選択
  'l->r:select': (param: { image?: DisplayObject, pos?: { x: number, y: number, scale?: number, }, isImgDisabled?: boolean }) => void,
  // 選択解除
  'l->r:dis-select': () => void,
  // 選択解除
  'r->l:dis-select': () => void,
  // 移動モードの切り替え
  // 'r->l:move-mode': (param: { moveMode: boolean }) => void,
  // 移動
  'l->r:move': (param: { x: number, y: number }) => void,
  // Shift移動
  'r->l:shift-move': (param: { x: number, y: number, type?: 'image' }) => void,
  'l->r:shift-move:push': (param: { shiftNum: number }) => void,
  'l->r:shift-move:change': (param: { shiftNum: number }) => void,
  'l->r:shift-move:get': (param: { callback: (p: { shiftNum: number }) => void }) => void,
  // 写真枠フィット
  'l->r:frame-fit:push': (param: { fitNum: number }) => void,
  'l->r:frame-fit:change': (param: { fitNum: number }) => void,
  'l->r:frame-fit:get': (param: { callback: (p: { fitNum: number }) => void }) => void,
  // 移動モード
  'r->l:move-mode': () => void,
  // 回転
  'r->l:rotate': (param: { angle: number, type?: 'image' }) => void,
  // 画像挿入
  'r->l:insert-image': (param: { files: File[] }) => void,
  // テキスト挿入開始
  'r->l:insert-text:start': (param: { callback: (isOpen: boolean, info: TextInfo) => void }) => void,
  // テキスト挿入終了
  'r->l:insert-text:end': (param: {}) => void,
  // 中心線の表示切り替え
  'r->l:center-line': (param: { display: boolean }) => void,
  // ルーラーの表示切り替え
  'r->l:ruler': (param: { display: boolean }) => void,
  // 拡大/縮小（図形）
  'r->l:resize': (param: { scale: number }) => void,
  // 拡大/縮小（viewer）
  'r->l:zoom': (param: { lv: number }) => void,
  'l->r:zoom:change': (param: { scale: number, x: number, y: number }) => void,
  // 拡大 1 段階アップ
  'r->l:zoom:lv:up': (param: {}) => void,
  // 拡大 1 段階ダウン
  'r->l:zoom:lv:down': (param: {}) => void,
  // 拡大/縮小 フィット
  'r->l:zoom:fit': (param: {}) => void,
  // 拡大/縮小 lv 検知
  'l->r:zoom:lv:change': (param: { lv: number }) => void,
  // 拡大/縮小 lv 取得
  'l->r:zoom:lv:get': (param: { callback: (p: { lv: number }) => void }) => void,
  // 削除
  'r->l:delete': () => void,
  // リセット
  'r->l:reset': () => void,
  // 複製
  'r->l:clone': () => void,
  // undo
  'r->l:undo': () => void,
  // redo
  'r->l:redo': () => void,
  // ダイアログの表示
  'l->r:push-dialog': (param: {
    title: string,
    message: string[],
    buttons: {
      label: string,
      callback: () => void,
      options?: {
        waitActive?: number,
      },
    }[],
    remind?: {
      callback: (v: boolean) => void;
      label?: string;
      initCheck?: boolean;
    },
  }) => void,
  // 画像の追加
  'r->l:add-image': (param: { files: File[], kijshopCd: string, shopOrderId: string, orderId: string | null, restore?: boolean, path?: string, selectId?: string, onUploaded?: (p: { editableImage: EditableImage, index: number, sameImage?: boolean }) => void, onCreatedEditableImage?: (p: { editableImage: EditableImage, index: number, sameImage?: boolean }) => void, isUseCheck?: boolean, selectIdList?: { path: string, selectID: string }[], samePushIgnore?: boolean, orientation?: Orientation, cfImageId?: string | null }) => void,
  'r->l:add-image-png': (param: { files: File[], kijshopCd: string, shopOrderId: string, orderId: string | null, restore?: boolean, path?: string, selectId?: string, onUploaded?: (p: { editableImage: EditableImage, index: number }) => void, onCreatedEditableImage?: (p: { editableImage: EditableImage, index: number }) => void, isUseCheck?: boolean }) => void,
  'r->l:shortcut:add-image-png': () => void,
  'r->l:add-image-comp': (param: { files: File[], kijshopCd: string, shopOrderId: string, orderId: string | null, restore?: boolean, path?: string, callback?: (p: { editableImage: EditableImage }) => void }) => void,
  // 画像の復元
  'r->l:add-image:restore': (param: { kijshopCd: string, shopOrderId: string, orderId: string | null, list: any[], onRestoreOne: () => void, isDelete: boolean, cfImageId?: string | null }) => void,
  // レイアウトサムネイル画像の追加
  'r->l:add-layout-comp': (param: { file: File, kijshopCd: string, shopOrderId: string, orderId: string | null, restore?: boolean, path?: string, callback?: (p: { editableImage: EditableImage }) => void, onError: () => void }) => void,
  // ロゴ画像の追加
  'r->l:add-image-logo': (param: { file: File, kijshopCd: string, shopOrderId: string, orderId: string | null, restore?: boolean, path?: string, callback: (p: { editableImage: EditableImage }) => void, onCreatedEditableImage?: (p: { editableImage: EditableImage }) => void, hashData?: { create?: boolean, hash?: string, checkList?: { path: string, hash: string }[], selectIdList?: { path: string, selectID: string }[] }, samePushIgnore?: boolean }) => void,
  // 画像のダイレクト追加
  'r->l:add-image:direct': (param: { file: File, kijshopCd: string, shopOrderId: string, orderId: string | null, restore?: boolean, path?: string, callback: (p: { editableImage: EditableImage }) => void, onCreatedEditableImage?: (p: { editableImage: EditableImage }) => void }) => void,
  // PPM連携画像追加
  'r->l:add-image:cloud-folder': (param: { files: (FileDetail & { file: File | null, orderId: string })[], kijshopCd: string, shopOrderId: string, orderId: string | null, onUploaded?: (p: { editableImage: EditableImage, index: number }) => void, onCreatedEditableImage?: (p: { editableImage: EditableImage }) => void, isLayout?: boolean }) => void,
  // EditableImage の list 更新
  'l->r:change-editable-image-list': (param: { list: EditableImage[] }) => void,
  'l->r:pushed-editable-image-list': (param: { list: EditableImage[] }) => void,
  // EditableImage の list 取得 (Reactへの受け渡し)
  'l->r:post-editable-image-list': (param: { callback: (p: { list: EditableImage[] }) => void }) => void,
  // EditableImage の追加 (レイアウト編集画面へ)
  'r->l:add-editable-image': (param: { editableImage: EditableImage, file: File}) => void,
  // EditableImage のドラッグスタート
  'r->l:editable-image:drag:start': (param: { editableImage: EditableImage }) => void,
  // EditableImage のドラッグエンド
  'r->l:editable-image:drag:end': (param: { editableImage: EditableImage }) => void,
  // EditableImage のドラッグの現在地チェック
  'r->l:editable-image:drag:check': (param: { x: number, y: number }) => void,
  // EditableImage のドロップ
  'r->l:editable-image:drop': () => void,
  // ローカルからcanvasへのドロップ
  'r->l:editable-image:drop:direct': (param: { editableImage: EditableImage, index: number, callback?: () => void }) => void,
  // ローカルからtemplateへのドロップ
  'r->l:editable-image:drop:temp': (param: { editableImage: EditableImage, index: number, postCardType: PostCardType, callback: () => void }) => void,
  // 画像のごみフラグ更新
  'r->l:change-editable-image-ccd-flags': (param: { editableImageId: string, flag: boolean, isPack: boolean }) => void,
  // アルバム選択
  'r->l:add-album': (param: { album: AlbumPage, callback?: () => void, restoreData?: OrderParam }) => void,
  // アルバム編集開始
  'r->l:select-album': (param: { album: AlbumPage, callback?: () => void }) => void,
  // 画像処理の状態取得
  'l->r:get-image-processor-status': (param: { status: 'busy' | 'standby', total: number, finish: number }) => void,
  // 操作モード変更
  'r->l:change-operation-mode': (param: { mode: 'pan-zoom' | 'select' }) => void,
  'r->l:shortcut:change-operation-mode': (param: { mode: 'pan-zoom' | 'select' }) => void,
  'l->r:shortcut:change-operation-mode-reset': (param: { isSelect: boolean }) => void,
  // 操作カーソル変更
  'r->l:change-operation-cursor': (param: { mode: 'pan-zoom' | 'select' | 'text' }) => void,
  // 写真/写真枠切り替え
  'r->l:change-image/frame': (param: { mode: 'image' | 'image-frame' }) => void,
  'l->r:change-image/frame': (param: { mode: 'image' | 'image-frame', isAdditional?: boolean }) => void,
  // フォント一覧取得の対応状況チェック
  'l->r:font:check-support': (param: { callback: (p: { supported: boolean }) => void }) => void,
  // フォント一覧の取得
  'l->r:font:get-list': (param: { callback: (p: { fonts: string[] }) => void }) => void,
  // ウェブフォント一覧の取得
  'l->r:web-font:get-list': (param: { callback: (p: { fonts: string[] }) => void }) => void,
  // ウェブフォントのダウンロード
  'l->r:web-font:download': (param: { fontFamilyName: string, callback: () => void, error: (msg: string) => void }) => void,
  // テキストの更新
  'r->l:reload-text': (param: { id:string, text: string, color: string, alpha: number, rotation: number, textAlign: string, lineHeight: number, font: TextFont, options: TextOption, callback?:() => void }) => void,
  // テキストツール表示
  'l->r:show-text-tool': (param: { isOpen: boolean, textInfo: TextInfo }) => void,
  // スポイト開始
  'r->l:color-dropper:start': (param: { container: HTMLDivElement | string, onChange: (p: { color: string }) => void }) => void,
  // スポイト終了
  'r->l:color-dropper:stop': (param: {}) => void,
  // テキスト削除
  'r->l:delete-text': (param: {id: string}) => void,
  // テキスト編集完了
  'r->l:finish-text-edit': (param: {id: string}) => void,
  // 内接・外接切り替え
  'r->l:image-resize-in/out': (param: { mode: 'in' | 'out' }) => void,
  // 有効保証エリア切り替え
  'r->l:image-guide-on/off': (param: { mode: 'on' | 'off' }) => void,
  // スクリーンカラーの変更
  'r->l:change-screen-color': (param: { color: ScreenColorType }) => void,
  // 一時保存
  'r->l:valid:save-layout': (param: {xml: XmlStructureModel, kijshopCd: string, shopOrderId: string, orderId: string, startDate: string}) => void,
  'r->l:save-layout': (param: {xml: XmlStructureModel, kijshopCd: string, shopOrderId: string, orderId: string, startDate: string, callback:() => void}) => void,
  'r->l:shortcut:save-layout': () => void,
  'r->l:shortcut:save-layout:focus-out': () => void,
  // レイアウト完了
  'r->l:valid:complete-layout': (param: {xml: XmlStructureModel, kijshopCd: string, shopOrderId: string, orderId: string, startDate: string}) => void,
  'r->l:complete-layout': (param: {xml: XmlStructureModel, kijshopCd: string, shopOrderId: string, orderId: string, startDate: string, callback:() => void}) => void,
  // プレビュー画像の生成
  'r->l:preview:make': (param: { url: string, album: AlbumPage, callback: (list: PreviewListType) => void }) => void,
  'l->r:removePreview': (param: {album: AlbumPage }) => void,
  // 余白チェッカートーストフラグ
  'l->r:show-toast': (param: { isToast: boolean, target: CjContainer }) => void,
  // ツールチップの表示
  'l->r:show-tooltip': (param: { type: 'imageHover' | 'frameMove' | 'scaleIcon' | 'rotateIcon' | 'none', pos: { x: number, y: number } }) => void,
  // containerの生成処理
  'r->l:container:didmount': (param: { container: HTMLDivElement, kijshopCd: string, shopOrderId: string, orderId: string, initData: LayoutInitType, callback?: () => void, album?: AlbumPage}) => void,
  // containerの削除処理
  'r->l:container:willunmount': (param: {albumPage: AlbumPage, isRestore?: boolean, isComp?: boolean}) => void,
  'r->l:delete-album': (param: {albumID: string}) => void,
  // テキスト中のキーボードイベント制御
  'r->l:stop-keyboard-event': (isStop: boolean) => void,
  // zipファイルの取得
  'l->r:zip-file:get': (param: { file: any, path: string, callback: (p: { file: any }) => void, id?: string }) => void,
  // テンプレート内で使用している写真枚数の取得
  'l->r:count-photo': (param: { album: AlbumPage, photoCount: number }) => void,
  // redo/undoの進行状態の取得
  'r->l:command-list:get': (param: { listLength: number, nowIndex: number }) => void,
  // 中枠の回転
  'r->l:rotate-additional': (param: { }) => void,
  // 中枠の切り替え
  'r->l:transform-additional': (param: { }) => void,
  // 中枠の表示
  'r->l:transparent-additional': (param: { type: AdditionalShowType, callback: () => void }) => void,
  // トリミング対象フレーム
  'r->l:change-middle-frame': (param: { id: number }) => void,
  'l->r:change-middle-frame': (param: { id: number }) => void,
  'r->l:shortcut:change-middle-frame': (param: { id: number, callback: (num: number) => void }) => void,
  // 人寸トリミング開始
  'r->l:trim:start': (param: { trimData: { id: number, windowSize: number, humanScale: number, topSpace: number, bottomSpace: number } }) => void,
  'r->l:shortcut:trim:start': () => void,
  // 人寸トリミング終了
  'l->r:trim:end': () => void,
  // 天地線の表示
  'r->l:show-trim-line': () => void,
  'r->l:shortcut:show-trim-line': () => void,
  // ガイドの表示
  'r->l:show-guide-line': (param: {list: number[]}) => void,
  'r->l:set-trim-guide-check-list': (param: {list: number[]}) => void,
  'r->l:get-trim-guide-check-list': (param: {callback: (list: number[] | null) => void}) => void,
  'r->l:shortcut:show-guide-line': () => void,
  // ガイドの表示
  'r->l:show-select-guide': (param: {list: number[]}) => void,
  // 新規ガイド作成開始
  'r->l:create-guide:start': (param: { type: 'vertical' | 'horizontal' }) => void,
  // 新規ガイド作成完了
  'l->r:create-guide:end': (param: { type: 'vertical' | 'horizontal', positionNum: number }) => void,
  // 新規ガイド削除
  'r->l:delete-guide': (param: { id: number }) => void,
  // トリミングメニューを開く
  'r->l:check:open-trim-menu': (param: { isOpen: boolean }) => void,
  // トリミングメニューを開く
  'l->r:open-trim-menu': (param: { isOpen: boolean }) => void,
  'r->l:shortcut:open-trim-menu': () => void,
  // トリミングメニューを閉じる
  'r->l:close-trim-menu': () => void,
  'l->r:error-close-trim-menu': () => void,
  'r->l:shortcut:close-trim-menu': () => void,
  // トリミングメニューの開閉状態の初期化
  'r->l:shortcut:trim-flag-reset': (param: { isTrimMenu: boolean }) => void,
  // ページ数変更
  'l->r:open:change-page-num': (param: { data: CheckFinishType, albumSetting: AlbumSettingData, callback: () => void }) => void,
  'r->l:close:change-page-num': (param: { data: CheckFinishType }) => void,
  'r->l:shortcut:change-page-num': () => void,
  'r->l:change-cover:start': () => void,
  'r->l:change-cover:end': () => void,
  // デザイン中止
  'r->l:shortcut:design-cancel': () => void,
  // テンプレート変更履歴の表示
  'r->l:shortcut:show-temp-update': () => void,
  // ローディングポップの表示・非表示
  'l->r:wait-loading': (param: { message: string }) => void,
  'l->r:wait-loading2': (param: { message: string }) => void,
  'l->r: post-text-pos': (param: { pos: Pos }) => void,
  // 強制レンダリング
  'l->r:force-update': () => void,
  // divContainer取得
  'l->r:getContainer': (param: {callback: (v: HTMLDivElement | null) => void}) => void,
  // current-albumセット
  'l->r:set-album': (param: { albumPage: AlbumPage, callback: () => void }) => void,
  'l->r:getOnSave': (param: { callback: (v: boolean) => void }) => void,
  'l->r:back-preparation': () => void,
  // 使用画像数の更新
  'l->r:change-use-image-num': (param: {type: 'increment' | 'decrement'}) => void,
  'l->r:change-template': () => void,
  // レイアウトメニューのDisabled
  'l->r:layout-menu-disabled': (param: { isDisabled: boolean }) => void,
  'r->l:close-button-trim-menu': () => void,

};

export class UiManager extends EventEmitter<EventType> implements ILayoutEditorManagerBase {

  private static _ins: UiManager;
  private initialized: boolean = false;

  private constructor() {
    super();
  }

  static get ins() {
    if (UiManager._ins) {
      return UiManager._ins;
    }
    return UiManager._ins = new UiManager();
  }

  initialize() {
    if (this.initialized) {
      // console.error('すでに初期化済みです !!');
      return;
    }
    this.initialized = true;
  }

  destroy(): void {
  }

  di(...manager: ILayoutEditorManagerBase[]): void {
  }

  addListener(
    // event: keyof EventType | EventEmitter.EventNames<string | symbol>,
    event: keyof EventType,
    fn: EventEmitter.EventListener<EventType, any>,
    context?: any,
  ) {
    return super.addListener(event, fn, context);
  }

  removeListener(
    event: keyof EventType,
    // fn?: EventEmitter.EventListener<EventType, keyof EventType>,
    fn?: EventEmitter.EventListener<EventType, any>,
    context?: any,
    once?: boolean,
  ): this {
    return super.removeListener(event, fn, context, once);
  }
}

UiManager.ins.initialize();
