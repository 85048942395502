import { AlbumBase, AlbumManager, AreaBase } from '../../../albam/albam';
import { ImageController, initPictureFlags } from '../image/image-controller';
import { EditableImageManager } from '../../editable-image/editable-image.manager';
import { ImageEvent } from '../image/image-event';
import { ImageEditableTool } from '../image/image-editable-tool';
import { LayoutDataManager } from '../../data/layout-data.manager';
import { OrderFrameType, OrderPictureType } from '../../../../models/api/front/layout';
import { CjBitmap, CjContainer, CjShape } from '../../../model/cj-factory';
import {
  CJ_BGIMAGE_NAME,
  CJ_CONTAINER_NAME,
  CJ_DPOBJ_NAME,
  CJ_FRAME_NAME,
} from '../../../model/cj-factory/cj-obj-name.collection';
import { CjGraphics } from '../../../model/cj-factory/cj-graphics';
import { OrderPageDataXml } from '../../../../xml/class/order/order-page-data-xml';
import lodash, { cloneDeep } from 'lodash';
import { AdditionalManager } from '../../additional/additional.manager';
import { CjTool } from '../../../../utilities/cj-tool';
import { ImageEditManager, ScreenColorCodeType } from '../image-edit.manager';
import { EditableEvent } from '../editable-event';
import { UiManager } from '../../ui/ui.manager';
import * as createjs from 'createjs-module';
import { EditableSideTool } from '../editable-side-tool';
import { TextController } from '../text/text-controller';
import { ScreenColorType } from '../../../../components/pages/layout/menu/layout.menu';

export type HighlightType = {
  pageId?: string;
  photoAreaId?: string;
  category?: 'highlight';
} & CjShape
export type WindowType = {
  pageId?: string;
  photoAreaId?: string;
  category?: 'window';
} & CjShape

export class FrameController {
  private stage: CjContainer;
  private readonly pageId: string;
  public orderPage?: OrderPageDataXml;

  albumData: AlbumBase = {
    realWidth: 0,
    realHeight: 0,
    virtualWidth: 0,
    virtualHeight: 0,
    photoArea: [],
    bgImages: [
      {
        name: `base.jpg`,
        type: 'base',
      },
      {
        name: `guide.png`,
        type: 'guide',
      },
      {
        name: `overwrap.png`,
        type: 'overwrap',
      },
    ],
  };

  private albumManager!: AlbumManager;
  private editableImageManager!: EditableImageManager;
  private imageController!: ImageController;
  private imageEvent!: ImageEvent;
  private imageEditableTool!: ImageEditableTool;
  private layoutDataManager!: LayoutDataManager;
  private additionalManager!: AdditionalManager;
  private imageEditManager!: ImageEditManager;
  private editableEvent!: EditableEvent;
  private editableSideTool!: EditableSideTool;
  private textController!: TextController;

  constructor(
    stage: CjContainer,
    pageId: string,
  ) {
    this.stage = stage;
    this.pageId = pageId;
  }

  di(
    imageController: ImageController,
    albumMng: AlbumManager,
    editableMng: EditableImageManager,
    imageEvent: ImageEvent,
    imageEditableTool: ImageEditableTool,
    layoutStoreMng: LayoutDataManager,
    additionalMng: AdditionalManager,
    imageEditMng: ImageEditManager,
    editableEvent: EditableEvent,
    editableSideTool: EditableSideTool,
    textController: TextController,
  ): void {
    this.imageController = imageController;
    this.albumManager = albumMng;
    this.editableImageManager = editableMng;
    this.imageEvent = imageEvent;
    this.imageEditableTool = imageEditableTool;
    this.layoutDataManager = layoutStoreMng;
    this.additionalManager = additionalMng;
    this.imageEditManager = imageEditMng;
    this.editableEvent = editableEvent;
    this.editableSideTool = editableSideTool;
    this.textController = textController;
  }

  initialize(orderPage?: OrderPageDataXml) {
    this.orderPage = orderPage;
  }

  destroy() {

  }

  update(stage: CjContainer): void {
    this.stage = stage;
  }

  // - 背景作成 -
  async createBgImage(path: string, url: string, isExchange?: boolean) {
    // -- 実画像データとレイアウト用のデータ --
    const bgRealData = this.albumManager.currentAlbum?.parseData.templateData.size[0].real[0].$;
    const bgVirtualData = this.albumManager.currentAlbum?.parseData.templateData.size[0].virtual[0].$;

    this.albumData.realWidth = bgRealData.width;
    this.albumData.realHeight = bgRealData.height;
    this.albumData.virtualWidth = bgVirtualData.width;
    this.albumData.virtualHeight = bgVirtualData.height;

    const baseImage = this.albumData.bgImages.find(image => image.type === 'base') ?? this.albumData.bgImages[0];
    // for (let i = 0; this.albumData.bgImages.length > i; i++) {
    // const image = await ImageController.createImageAsync(`${url}/${baseImage.name}`, 'base');
    const image = await ImageController.createImageAsync(url, baseImage.name, 'base', this.albumManager.currentAlbum?.isZip, this.albumManager.currentAlbum?.templateBlob, isExchange);
    this.stage?.addChild(image);
    image.set({
      // selectable: false,
      // evented: false,
      width: Number(this.albumData.virtualWidth),
      height: Number(this.albumData.virtualHeight),
    });
    // }
  }

  // - ガイド -
  async createGuide(path: string, url: string, isExchange?: boolean) {

    // -- 実画像データとレイアウト用のデータ --
    const baseImage = this.albumData.bgImages.find(image => image.type === 'guide') ?? this.albumData.bgImages[1];
    // for (let i = 0; this.albumData.bgImages.length > i; i++) {
    // const image = await ImageController.createImageAsync(`${url}/${baseImage.name}`, 'guide');
    const image = await ImageController.createImageAsync(url, baseImage.name, 'guide', this.albumManager.currentAlbum?.isZip, this.albumManager.currentAlbum?.templateBlob, isExchange);
    image.mouseEnabled = false;
    this.stage?.addChild(image);
    image.set({
      width: Number(this.albumData.virtualWidth),
      height: Number(this.albumData.virtualHeight),
    });
    this.editableEvent.sortContainer();
    // }
  }

  // - オーバーラップ -
  async createOverWrap(path: string, url: string, isExchange?: boolean) {
    const baseImage = this.albumData.bgImages.find(image => image.type === 'overwrap') ?? this.albumData.bgImages[2];
    // for (let i = 0; this.albumData.bgImages.length > i; i++) {
    // const image = await ImageController.createImageAsync(`${url}/${baseImage.name}`, 'overwrap');
    const image = await ImageController.createImageAsync(url, baseImage.name, 'overwrap', this.albumManager.currentAlbum?.isZip, this.albumManager.currentAlbum?.templateBlob, isExchange);
    image.mouseEnabled = false;
    this.stage?.addChild(image);
    image.set({
      width: Number(this.albumData.virtualWidth),
      height: Number(this.albumData.virtualHeight),
    });
    // }
  }

  // - 台紙 -
  async createAdditional() {
    const additionalData = this.albumManager.currentAlbum?.parseData.templateData.additionalFrame ?? undefined;
    if (!this.imageEditManager.isDAI) {
      this.layoutDataManager.dispatch('setDAIFlag', false);
      return;
    }
    this.layoutDataManager.dispatch('setDAIFlag', true);
    const additionalSize = additionalData[0].additionalFrameSize[0].virtual[0].$;
    const additionalColor = additionalData[0].color[0].$;
    const windows = additionalData[0].windows[0].window;
    const additional = {
      x: Number(additionalSize.x),
      y: Number(additionalSize.y),
      w: Number(additionalSize.width),
      h: Number(additionalSize.height),
    };
    const windowList: any[] = [];

    if (this.imageEditManager.isAdditionalFrame) {
      this.additionalManager.drawBase(additional, `rgb(${additionalColor.r},${additionalColor.g},${additionalColor.b})`);
    }

    for (const windowData of windows) {
      const windowSize = windowData.windowSize[0].virtual[0].$;
      const windowType = windowData.type[0].$.default;
      const windowRotate = Number(windowData.type[0].$.rotation ?? 0);
      const window = {
        x: Number(windowSize.x),
        y: Number(windowSize.y),
        w: Number(windowSize.width),
        h: Number(windowSize.height),
      };
      windowList.push({
        id: `${CJ_DPOBJ_NAME.additionalWindow}:${windowData.$.id}`,
        data: windowData,
      });
      // -- windowの生成 --
      if (this.imageEditManager.isAdditionalFrame) {
        this.additionalManager.clipWindow(additional, window, windowRotate, windowType);
      }
      this.layoutDataManager.dispatch('setTrimData', {
        pageId: this.pageId,
        id: Number(windowData.$.id),
        data: {
          topLine: 0,
          bottomLine: 0,
          windowSize: 0,
          humanScale: 0,
          topSpace: 0,
          bottomSpace: 0,
        },
      });
    }
    this.layoutDataManager.dispatch('setWindows', {
      pageId: this.pageId,
      data: windowList,
    });
    if (!this.imageEditManager.isAdditionalFrame) {
      return;
    }
    const additionalContainer = new CjContainer();
    additionalContainer.pageId = this.pageId;
    additionalContainer.name = CJ_CONTAINER_NAME.additional;
    const additionalUrl = this.additionalManager.makeAdditional();
    const additionalWindow = new CjBitmap(additionalUrl);
    additionalWindow.name = `${CJ_DPOBJ_NAME.additionalWindow}`;
    additionalContainer.addChild(additionalWindow);
    additionalContainer.set({
      x: additional.x,
      y: additional.y,
      alpha: 1,
    });
    this.stage.addChild(additionalContainer);
    await this.layoutDataManager.dispatch('setAdditional', additionalContainer);
  }

  async createGrayOut() {
    const grayOut = new CjShape();
    grayOut.name = CJ_BGIMAGE_NAME.grayout;
    grayOut.graphics.f('#000').dr(0, 0, Number(this.albumData.virtualWidth), Number(this.albumData.virtualHeight));
    grayOut.alpha = 0.4;
    grayOut.visible = false;
    this.stage?.addChild(grayOut);
  }

  // - 写真枠作成 -
  async createPhotoFrame(path: string, url: string, isExchange?: boolean) {
    return new Promise<void>(async (resolve) => {
      this.albumData.photoArea.length = 0;
      const orderFrameGroup: OrderFrameType[] = [];
      const orderPictureGroup: OrderPictureType[] = [];
      let zeroId = false;
      const albumPhotoArea = this.albumManager.currentAlbum?.parseData.templateData.photoarea;
      if (!albumPhotoArea) {
        resolve();
        return;
      }
      albumPhotoArea.map((v: any) => {
        const info = cloneDeep(v);
        /* ロゴがある時なのか CjContainer の orgsubMaskID に 0 が入ることがあるので 0 を許容する */
        // if (info.$.photoareaID === '0') {
        //   zeroId = true;
        // }
        // if (zeroId) {
        //   info.$.photoareaID = `${Number(info.$.photoareaID ?? 0) + 1}`;
        // }
        return info;
      });
      const additionalData = this.albumManager.currentAlbum?.parseData.templateData.additionalFrame ?? undefined;
      const exTemplate = this.albumManager.currentAlbum?.parseData.templateData.exTemplate ?? undefined;
      const materials = this.albumManager.currentAlbum?.parseData.templateData.materials ?? undefined;
      // -- 座標情報のないテンプレート（DVDなど）を除く --

      for (const phArea of albumPhotoArea) {
        const phAreaReal = phArea.real[0].$;
        const phAreaVirtual = phArea.virtual[0].$;
        const photoArea: AreaBase = {
          id: phArea.$.photoareaID,
          logo: Boolean(Number(phArea.$.logo)),
          depth: Number(phArea.$.depth),
          rotation: Number(phArea.$.rotation),
          frameEditFlag: Boolean(Number(phArea.$.frameEditFlag)),
          realWidth: Number(phAreaReal.width),
          realHeight: Number(phAreaReal.height),
          realX: Number(phAreaReal.x),
          realY: Number(phAreaReal.y),
          virtualWidth: Number(phAreaVirtual.width),
          virtualHeight: Number(phAreaVirtual.height),
          virtualX: Number(phAreaVirtual.x),
          virtualY: Number(phAreaVirtual.y),
          // virtualX: Number(phAreaVirtual.x) + Number(frameXml?.virtual.x ?? 0),
          // virtualY: Number(phAreaVirtual.y) + Number(frameXml?.virtual.y ?? 0),
          back: {
            flag: Boolean(Number(phArea.back[0].$.flag)),
            name: Boolean(Number(phArea.back[0].$.flag)) ? phArea.back[0].$.name : '',
            backData: Boolean(Number(phArea.back[0].$.flag)) ? {
                realWidth: Number(phArea.back[0].real[0].$.width),
                realHeight: Number(phArea.back[0].real[0].$.height),
                realX: Number(phArea.back[0].real[0].$.x),
                realY: Number(phArea.back[0].real[0].$.y),
                virtualWidth: Number(phArea.back[0].virtual[0].$.width),
                virtualHeight: Number(phArea.back[0].virtual[0].$.height),
                virtualX: Number(phArea.back[0].virtual[0].$.x),
                virtualY: Number(phArea.back[0].virtual[0].$.y),
              }
              : undefined,
          },
          mask: { flag: Boolean(Number(phArea.mask[0].$.flag)), name: phArea.mask[0].$.name },
          photoFrame: { flag: Boolean(phArea.photoframe[0].$.flag), count: phArea.photoframe[0].$.count, frame: [] },
          orgsubMaskID: phArea.$.photoareaID,
        };

        if (phArea.photoframe[0].frame) {
          for (const frame of phArea.photoframe[0].frame) {
            const frameReal = frame.real[0].$;
            const frameVirtual = frame.virtual[0].$;
            photoArea.photoFrame.frame.push({
              id: frame.$.id,
              name: frame.$.name,
              blendMode: frame.$.blendmode,
              realWidth: Number(frameReal.width),
              realHeight: Number(frameReal.height),
              realX: Number(frameReal.x),
              realY: Number(frameReal.y),
              virtualWidth: Number(frameVirtual.width),
              virtualHeight: Number(frameVirtual.height),
              virtualX: Number(frameVirtual.x),
              virtualY: Number(frameVirtual.y),
            });
          }
        }
        this.albumData.photoArea.push(photoArea);
      }
      const orderFrame = this.orderPage?.viewModel.orderFrame;
      const orderPicture = this.orderPage?.viewModel.orderPicture;
      // const photoAreas = this.albumData.photoArea;
      const photoAreas = await this.checkLayoutData(orderFrame);
      // - 写真エリアのグループ化(マスク、フレームの各グループを一つのグループにラップ) -
      if (!photoAreas.length) {
        resolve();
        return;
      }

      const createPhotoArea = (photoArea: AreaBase) => {
        return new Promise<CjContainer>(async (resolve) => {
          const frameXml = orderFrame?.find(elm => {
            const isLogo = Boolean(this.albumData.photoArea.find(elm => elm.logo));
            return (isLogo ? String((Number(elm.id) || 0)) : elm.id) === String(photoAreas.indexOf(photoArea) + 1 || 1);
          });
          const pictureXml = () => {
            if (!orderPicture || !orderPicture.data || !frameXml) return;
            return orderPicture.data.find(elm => elm.subMaskID === photoArea.id);
          };
          const id = photoArea.id;
          const imageAreaContainer = new CjContainer();
          const restoreData = this.albumManager.currentAlbum?.restorePicData;
          const findRestoreData = restoreData?.find((v) => v.photoAreaId === id);
          const editorContainer = new CjContainer();
          editorContainer.set({
            pageId: this.pageId,
            photoAreaId: id,
            pageType: this.albumManager.currentAlbum?.typeID ?? '',
            path,
            depth: photoArea.depth,
            name: CJ_CONTAINER_NAME.editor,
            x: isExchange
              ? photoArea.virtualX
              : (photoArea.virtualX + Number(frameXml?.virtual?.x ?? 0)),
            y:  isExchange
              ? photoArea.virtualY
              :(photoArea.virtualY + Number(frameXml?.virtual?.y ?? 0)),
            regX: photoArea.virtualX,
            regY: photoArea.virtualY,
            real: {
              x: photoArea.realX,
              y: photoArea.realY,
              width: photoArea.realWidth,
              height: photoArea.realHeight,
            },
            virtual: {
              x: photoArea.virtualX,
              y: photoArea.virtualY,
              width: photoArea.virtualWidth,
              height: photoArea.virtualHeight,
            },
            frameId: String(photoAreas.indexOf(photoArea) + 1 || 1),
          });
          editorContainer.visible = frameXml?.deleteFlag !== '1';
          const scaleContainer = new CjContainer();
          scaleContainer.set({
            pageId: this.pageId,
            photoAreaId: id,
            pageType: this.albumManager.currentAlbum?.type ?? '',
            path,
            name: CJ_CONTAINER_NAME.scale,
            x: photoArea.virtualX + photoArea.virtualWidth / 2,
            y: photoArea.virtualY + photoArea.virtualHeight / 2,
            regX: photoArea.virtualX + photoArea.virtualWidth / 2,
            regY: photoArea.virtualY + photoArea.virtualHeight / 2,
            scaleX: isExchange
              ? 1
              : (Number(frameXml?.zoom ?? 1) ?? 1),
            scaleY: isExchange
              ? 1
              : (Number(frameXml?.zoom ?? 1) ?? 1),
          });
          const rotateContainer = new CjContainer();
          rotateContainer.set({
            pageId: this.pageId,
            photoAreaId: id,
            pageType: this.albumManager.currentAlbum?.type ?? '',
            path,
            name: CJ_CONTAINER_NAME.rotate,
            x: photoArea.virtualX + photoArea.virtualWidth / 2,
            y: photoArea.virtualY + photoArea.virtualHeight / 2,
            regX: photoArea.virtualX + photoArea.virtualWidth / 2,
            regY: photoArea.virtualY + photoArea.virtualHeight / 2,
            rotation: isExchange
              ? photoArea.rotation
              : frameXml?.frameRotate ? Number(frameXml?.frameRotate) + photoArea.rotation : photoArea.rotation,
            isRotate: isExchange
              ? false
              : frameXml?.frameRotate ? Boolean(Number(frameXml?.frameRotate)) : false,
          });
          editorContainer.addChild(scaleContainer);
          scaleContainer.addChild(rotateContainer);
          imageAreaContainer.set({
            pageId: this.pageId,
            photoAreaId: id,
            pageType: this.albumManager.currentAlbum?.type ?? '',
            path,
            copyFlag: Boolean(Number(frameXml?.copyFlag)) ?? false,
            deleteFlag: Boolean(Number(frameXml?.deleteFlag)) ?? false,
            logoFlag: photoArea.logo,
            maskFlag: photoArea.mask.flag,
            maskName: photoArea.mask ? photoArea.mask.name : '',
            frameFlag: (() => {
              if (photoArea.photoFrame) {
                return Boolean(photoArea.photoFrame.frame.length);
              } else {
                return false;
              }
            })(),
            depth: Boolean(Number(frameXml?.copyFlag)) ? Number(frameXml?.id) : photoArea.depth,
            originDepth: Boolean(Number(frameXml?.copyFlag)) ? (()=> {
              const orgsubMask = this.albumData.photoArea.find(v => v.id === photoArea.orgsubMaskID);
              return orgsubMask ? orgsubMask.depth : photoArea.depth;
            })() : photoArea.depth,
            // orgsubMaskID: editorContainer.frameId ?? '1',
            orgsubMaskID: photoArea.orgsubMaskID ?? photoArea.id,
            subMaskID: photoArea.id,
            real: {
              x: photoArea.realX,
              y: photoArea.realY,
              width: photoArea.realWidth,
              height: photoArea.realHeight,
            },
            virtual: {
              x: photoArea.virtualX,
              y: photoArea.virtualY,
              width: photoArea.virtualWidth,
              height: photoArea.virtualHeight,
            },
            restorePos: {
              x: isExchange
                ? 0
                : Number(frameXml?.virtual?.x ?? 0),
              y: isExchange
                ? 0
                : Number(frameXml?.virtual?.y ?? 0),
            },
            name: CJ_CONTAINER_NAME.imageArea,
            x: photoArea.virtualX,
            y: photoArea.virtualY,
            regX: photoArea.virtualX,
            regY: photoArea.virtualY,
            originalRotate: pictureXml() ? (pictureXml()?.subMaskRotate ?? photoArea.rotation) : photoArea.rotation,
            movePos: {
              x: isExchange
                ? 0
                : (findRestoreData?.photoAreaX ?? Number(photoArea.virtualX)) - Number(photoArea.virtualX),
              y: isExchange
                ? 1
                : (findRestoreData?.photoAreaY ?? Number(photoArea.virtualY)) - Number(photoArea.virtualY)
            }
          });
          // 写真枠の背景
          let back;
          if (photoArea.back.flag && photoArea.back.backData) {
            const backPath = `${url}/${photoArea.back.name}`;
            // back = await ImageController.createImageAsync(backPath, CJ_FRAME_NAME.back);
            back = await ImageController.createImageAsync(url, photoArea.back.name, CJ_FRAME_NAME.back, this.albumManager.currentAlbum?.isZip, this.albumManager.currentAlbum?.templateBlob, isExchange);
            back.addChild(back);
            back.photoAreaId = id;
            back.pageId = this.pageId;
            back.path = backPath;
            back.set({
              x: photoArea.virtualX + photoArea.back.backData.virtualX,
              y: photoArea.virtualY + photoArea.back.backData.virtualY,
              width: photoArea.back.backData.virtualWidth,
              height: photoArea.back.backData.virtualHeight,
            });
            rotateContainer.addChild(back);
          }
          // 写真枠のマスク
          if (photoArea.mask.flag) {
            const maskPath = `${url}/${photoArea.mask.name}`;
            // const mask = await ImageController.createImageAsync(maskPath, CJ_FRAME_NAME.mask);
            const mask = await ImageController.createImageAsync(url, photoArea.mask.name, CJ_FRAME_NAME.mask, this.albumManager.currentAlbum?.isZip, this.albumManager.currentAlbum?.templateBlob, isExchange);
            imageAreaContainer.addChild(mask);
            mask.photoAreaId = id;
            mask.pageId = this.pageId;
            mask.path = maskPath;
            mask.set({
              x: photoArea.virtualX,
              y: photoArea.virtualY,
              width: photoArea.virtualWidth,
              height: photoArea.virtualHeight,
              alpha: 1,
            });
            this.layoutDataManager.dispatch('setMaskContainer', mask);
            const hitArea = new CjShape();
            hitArea.set({
              x: hitArea.x,
              y: hitArea.y,
              graphics: new CjGraphics().f('#FFFFFF').dr(0, 0, photoArea.virtualWidth, photoArea.virtualHeight),
            });
            mask.hitArea = hitArea;
          }

          // 写真枠の枠装飾
          if (photoArea.photoFrame.flag && photoArea.photoFrame.frame.length) {
            for (const frameData of photoArea.photoFrame.frame) {
              const framePath = `${url}/${frameData.name}`;
              const frame = await ImageController.createImageAsync(url, frameData.name, CJ_FRAME_NAME.frame, this.albumManager.currentAlbum?.isZip, this.albumManager.currentAlbum?.templateBlob, isExchange);
              const frameImage = frame.getChildByName(CJ_FRAME_NAME.frame);
              // const matrix = new createjs.ColorMatrix().adjustBrightness(-200)
              // frameImage.filters = [
              //   new createjs.ColorMatrixFilter(matrix)
              //
              // ]
              switch (frameData.blendMode) {
                case 'darken':
                  frameImage.compositeOperation = 'darken';
                  break;
                case 'multiply':
                  frameImage.compositeOperation = 'multiply';
                  break;
                case 'overlay':
                  frameImage.compositeOperation = 'overlay';
                  break;
                  // 以下、必要になった際現行テンプレートと比較してチェックする。
                case 'hardlight':
                  frameImage.compositeOperation = 'hard-light';
                  break;
                case 'lighten':
                  frameImage.compositeOperation = 'lighten';
                  break;
                case 'screen':
                  frameImage.compositeOperation = 'screen';
                  break;
                case 'difference':
                  frameImage.compositeOperation = 'difference';
                  break;
                case 'subtract':
                  // globalCompositeOperationにないため近いものを設定。必要になった際に要調査
                  frameImage.compositeOperation = 'difference';
                  // frameImage.compositeOperation = 'subtract';
                  break;
                default:
                  break;
              }
              // frameImage.cache(0, 0, 1000, 1000)
              imageAreaContainer.addChild(frame);
              frame.set({
                pageId: this.pageId,
                photoAreaId: id,
                path: framePath,
                x: photoArea.virtualX + frameData.virtualX,
                y: photoArea.virtualY + frameData.virtualY,
                width: frameData.virtualWidth,
                height: frameData.virtualHeight,
                defaultPos: {
                  x: Number(frameData.virtualX),
                  y: Number(frameData.virtualY),
                },
              });
              imageAreaContainer.set({
                pageId: this.pageId,
                photoAreaId: id,
                path,
                name: CJ_CONTAINER_NAME.imageArea,
                pageType: this.albumManager.currentAlbum?.type ?? '',
                x: photoArea.virtualX + frameData.virtualX,
                y: photoArea.virtualY + frameData.virtualY,
                regX: photoArea.virtualX + frameData.virtualX,
                regY: photoArea.virtualY + frameData.virtualY,
                copyFlag: Boolean(Number(frameXml?.copyFlag)) ?? false,
                deleteFlag: Boolean(Number(frameXml?.deleteFlag)) ?? false,
                logoFlag: photoArea.logo,
                maskFlag: photoArea.mask.flag,
                maskName: photoArea.mask ? photoArea.mask.name : '',
                frameFlag: (() => {
                  if (photoArea.photoFrame) {
                    return Boolean(photoArea.photoFrame.frame.length);
                  } else {
                    return false;
                  }
                })(),
                depth: photoArea.depth,
                orgsubMaskID: photoArea.orgsubMaskID ?? photoArea.id,
                subMaskID: photoArea.id,
              });
            }
          }
          // 写真移動時の境界線表示用DummyShape(左上から時計回りにA,B,C,D)
          const dummyFrame = new CjContainer();
          const dummyRect = new CjShape();
          const dummyCornerA = new CjShape();
          const dummyCornerB = new CjShape();
          const dummyCornerC = new CjShape();
          const dummyCornerD = new CjShape();
          dummyRect.graphics.f('#00f').dr(photoArea.virtualX, photoArea.virtualY, photoArea.virtualWidth, photoArea.virtualHeight);
          dummyCornerA.graphics.s('#00f').dc(photoArea.virtualX, photoArea.virtualY, 2);
          dummyCornerB.graphics.s('#00f').dc(photoArea.virtualX + photoArea.virtualWidth, photoArea.virtualY, 2);
          dummyCornerC.graphics.s('#00f').dc(photoArea.virtualX + photoArea.virtualWidth, photoArea.virtualY + photoArea.virtualHeight, 2);
          dummyCornerD.graphics.s('#00f').dc(photoArea.virtualX, photoArea.virtualY + photoArea.virtualHeight, 2);
          dummyRect.name = CJ_DPOBJ_NAME.dummyRect;
          dummyCornerA.name = CJ_DPOBJ_NAME.dummyCornerA;
          dummyCornerA.alpha = 0.01;
          dummyCornerB.name = CJ_DPOBJ_NAME.dummyCornerB;
          dummyCornerB.alpha = 0.01;
          dummyCornerC.name = CJ_DPOBJ_NAME.dummyCornerC;
          dummyCornerC.alpha = 0.01;
          dummyCornerD.name = CJ_DPOBJ_NAME.dummyCornerD;
          dummyCornerD.alpha = 0.01;
          dummyFrame.alpha = 0;
          dummyFrame.name = CJ_CONTAINER_NAME.dummyFrame;
          dummyFrame.addChild(dummyRect, dummyCornerA, dummyCornerB, dummyCornerC, dummyCornerD);
          imageAreaContainer.addChild(dummyFrame);
          // centerLineの生成
          const centerLineContainer = new CjContainer();
          const verticalLine = new CjShape();
          // verticalLine.graphics.s('#B6B1C6')
          verticalLine.graphics.s('#674ea7')
            .ss(2)
            .mt(photoArea.virtualX, photoArea.virtualY + photoArea.virtualHeight / 2)
            .lt(photoArea.virtualX + photoArea.virtualWidth, photoArea.virtualY + photoArea.virtualHeight / 2);
          const horizonLine = new CjShape();
          // horizonLine.graphics.s('#B6B1C6')
          horizonLine.graphics.s('#674ea7')
            .ss(2)
            .mt(photoArea.virtualX + photoArea.virtualWidth / 2, photoArea.virtualY)
            .lt(photoArea.virtualX + photoArea.virtualWidth / 2, photoArea.virtualY + photoArea.virtualHeight);
          centerLineContainer.name = CJ_CONTAINER_NAME.centerLine;
          centerLineContainer.alpha = 0;
          centerLineContainer.addChild(verticalLine, horizonLine);
          imageAreaContainer.addChild(centerLineContainer);

          rotateContainer.addChild(imageAreaContainer);
          // highlightの生成
          const highlight: HighlightType = new CjShape();
          highlight.pageId = this.pageId;
          highlight.photoAreaId = id;
          highlight.name = CJ_DPOBJ_NAME.highlight;
          highlight.graphics.f('#fff').dr(photoArea.virtualX, photoArea.virtualY, photoArea.virtualWidth, photoArea.virtualHeight);
          highlight.alpha = 0.4;
          highlight.visible = false;
          imageAreaContainer.addChild(highlight);
          // highlightの生成
          const grayFilm: HighlightType = new CjShape();
          grayFilm.pageId = this.pageId;
          grayFilm.photoAreaId = id;
          grayFilm.name = CJ_DPOBJ_NAME.grayFilm;
          grayFilm.graphics.f('#000').dr(photoArea.virtualX, photoArea.virtualY, photoArea.virtualWidth, photoArea.virtualHeight);
          grayFilm.alpha = 0.4;
          grayFilm.visible = false;
          imageAreaContainer.addChild(grayFilm);

          await this.layoutDataManager.dispatch('setFrame', { pageId: this.pageId, order: orderFrameGroup });
          await this.layoutDataManager.dispatch('setPicture', { pageId: this.pageId, order: orderPictureGroup });

          back && await this.layoutDataManager.dispatch('setBackContainer', back);

          if (frameXml?.deleteFlag !== '1') {
            if (additionalData) {
              this.imageEvent.setImageEvent(editorContainer, 'dai');
            } else {
              this.imageEvent.setImageEvent(editorContainer, 'frame');
            }
          }
          this.stage.addChild(editorContainer);
          await this.layoutDataManager.dispatch('setImageAreaContainer', imageAreaContainer);
          await this.layoutDataManager.dispatch('setRotateContainer', rotateContainer);
          await this.layoutDataManager.dispatch('setScaleContainer', scaleContainer);
          await this.layoutDataManager.dispatch('setEditorContainer', editorContainer);
          const timer = window.setInterval(() => {
            const checkContainer = [
              this.layoutDataManager.selector('getImageAreasDeleteIn', imageAreaContainer.pageId).find((v: CjContainer) => v.photoAreaId === imageAreaContainer.photoAreaId),
              this.layoutDataManager.selector('getRotateContainers', rotateContainer.pageId).find((v: CjContainer) => v.photoAreaId === rotateContainer.photoAreaId),
              this.layoutDataManager.selector('getScaleContainers', scaleContainer.pageId).find((v: CjContainer) => v.photoAreaId === scaleContainer.photoAreaId),
              this.layoutDataManager.selector('getEditors', editorContainer.pageId).find((v: CjContainer) => v.photoAreaId === editorContainer.photoAreaId),
            ].every((v) => !!v);
            if (checkContainer) {
              resolve(editorContainer);
              clearInterval(timer);
            }
          }, 300);
          CjTool.checkLoad(scaleContainer).then((con) => {
            const scaleBounds = con.getTransformedBounds();
            if (scaleBounds) {
              con.originalBounds.width = scaleBounds.width / con.scaleX;
              con.originalBounds.height = scaleBounds.height / con.scaleY;
            }
          });
        });
      };
      Promise.all(photoAreas.map(async (v, i) => {
        const container = await createPhotoArea(v);
        await this.changeCover(container, !i);
      }))
        .then(() => {
          /* depthをみて重ね順を正しくする */
          const editors = this.layoutDataManager.selector('getEditors', this.pageId) as CjContainer[]
          for (const editor of editors) {
            this.stage.setChildIndex(editor, editor.depth)
          }
          UiManager.ins.emit('r->l:change-cover:end');
          resolve();
        });
    });
  }

  private async changeCover(container: CjContainer, isFirst: Boolean) {
    const prevCoverList = this.layoutDataManager.selector('getPrevCover') as CjContainer[];
    if (prevCoverList && prevCoverList.length) {
      const target = prevCoverList.find(v => v.photoAreaId === container.photoAreaId);
      if (!target) return;
      const scaleContainer = CjTool.getScaleContainer(container);
      const targetScale = CjTool.getScaleContainer(target);
      const imageAreaContainer = CjTool.getImageAreaContainer(container);
      const targetImageArea = CjTool.getImageAreaContainer(target);
      const rotateContainer = CjTool.getRotateContainer(container);
      const targetRotate = CjTool.getRotateContainer(target);
      const editableList = this.editableImageManager.list;
      container.x += targetImageArea.movePos.x;
      container.y += targetImageArea.movePos.y;
      imageAreaContainer.movePos = targetImageArea.movePos;
      scaleContainer.scaleX = targetScale.scaleX;
      scaleContainer.scaleY = targetScale.scaleY;
      rotateContainer.rotation = targetRotate.rotation;
      rotateContainer.isRotate = targetRotate.isRotate;
      if (CjTool.checkPhotoInFrame(target)) {
        const targetImage = CjTool.getImageContainer(target);
        const photo = editableList.find((v) => v.id === targetImage.editableImageId);
        if (photo) {
          await this.imageController.createPhotoImage(photo, scaleContainer, {
            ...initPictureFlags,
            isChangeCover: true,
          });
        }
      }
      if (isFirst) {
        const freeGraphics = await this.layoutDataManager.selector('getCoverFreeGraphics')
        if (freeGraphics) {
          for (const _freeGraphic of freeGraphics) {
            const freeGraphic = editableList.find((v) => v.id === _freeGraphic.editableImageId);
            if (freeGraphic) {
              await this.editableSideTool.addImage(freeGraphic, null, undefined, true)
            }
          }
        }
        const textContainers = await this.layoutDataManager.selector('getCoverText')
        if (textContainers) {
          for (const textContainer of textContainers) {
            await this.textController.changeCover(textContainer)
          }
        }
      }
      this.stage.stage.update();
    }
  }

  private async checkLayoutData(_orderFrame: any) {
    return new Promise<AreaBase[]>((resolve) => {
      if (_orderFrame) {
        const photoAreas: AreaBase[] = [];
        const pictures = this.orderPage?.viewModel.orderPicture?.data;
        if (pictures) {
        let copyDepth  = Math.max(...pictures.map(v => Number(v.depth)));
          for (const v of pictures) {
            if (v.copyFlag === '1') {
              const copyAlbum = lodash.cloneDeep(this.albumData.photoArea.find(elm => elm.id === v.orgsubMaskID && v.copyFlag === '1'));
              if (copyAlbum && v) {
                copyAlbum.orgsubMaskID = v.orgsubMaskID || '';
                copyAlbum.id = v.subMaskID || '';
                copyAlbum.depth = ++copyDepth;
                copyAlbum && photoAreas.push(copyAlbum);
              }
            } else {
              const album = this.albumData.photoArea.find((elm, i) => String(elm.id) === v.subMaskID);
              album && photoAreas.push(album);
            }
          }
        }
        resolve(photoAreas);
      } else {
        resolve(this.albumData.photoArea);
      }
    });
  }

  async createStageMask(color: ScreenColorCodeType = '#000000') {
    const base = {
      x: -ImageEditManager.STAGE_CONTAINER_X,
      y: -ImageEditManager.STAGE_CONTAINER_Y,
      w: ImageEditManager.CANVAS_W,
      h: ImageEditManager.CANVAS_H,
    };
    const window = {
      x: 0,
      y: 0,
      w: this.imageEditManager.templateVirtual.w,
      h: this.imageEditManager.templateVirtual.h,
    };

    this.additionalManager.drawBase(base, color);
    this.additionalManager.clipWindow(base, window, 0, 'rect');
    const baseMaskContainer = new CjContainer();
    baseMaskContainer.pageId = this.pageId;
    baseMaskContainer.name = CJ_CONTAINER_NAME.baseMask;
    const baseMaskUrl = this.additionalManager.makeAdditional();
    const baseMaskWindow = new CjBitmap(baseMaskUrl);
    baseMaskWindow.name = `${CJ_DPOBJ_NAME.baseMaskWindow}`;
    baseMaskContainer.addChild(baseMaskWindow);
    baseMaskContainer.set({
      x: base.x,
      y: base.y,
      alpha: 1,
      mouseEnable: false,
    });
    this.stage.addChild(baseMaskContainer);
  }

}
