import React, { DetailedHTMLProps, ImgHTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './image.memo.scss';
import { Orientation } from 'get-orientation/browser';
import { AutoPosProps, DomContentTooltip } from '../tooltip/dom-content-tooltip';
import { MemoDomContentTooltip } from '../tooltip/dom-content-tooltip.memo';

export type ImageSizeType = { width: number, height: number };

export type ImageProps = {
  setSizeCallback?: (size: ImageSizeType) => void,
  // - 画像のコンテナ要素のアスペクト比（高さと幅どちらにフィットさせるかの判定に使用） -
  containerAspectRatio?: number,
  // - ズーム操作の倍率（100倍にして使用(仮処理)） -
  scale?: number,
  // - ドラッグ用ダミー -
  useDragDummy?: boolean,
  // - ドラッグ用ダミーのスケール -
  dummyScale?: number,
  // - 回転方向 -
  orientation?: Orientation,
  // - レタッチズーム倍率 -
  retouchScale?: number,
  // - ツールチップ -
  tooltip?: { text: string[], title?: string, autoPos?: AutoPosProps},
  scrollObserver?: boolean,
} & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export const MemoImage = (props: ImageProps) => {
  const { setSizeCallback, containerAspectRatio = 1.33, scale = 1, useDragDummy, dummyScale = 1, orientation, retouchScale, tooltip, ...defaultProps } = props;
  const [fitVector, setFitVector] = useState<"x" | "y">();

  const ref = useRef<HTMLImageElement>(null);
  const timer = useRef(0);

  // - State -
  const [dragDummy, setDragDummy] = useState('');
  const [hover, setHover] = useState(false);
  const [observer, setObserver] = useState(false);
  const [delayHover, setDelayHover] = useState(false);

  const transform = useMemo(() => {
    if (orientation) {
      return setTransform(orientation);
    } else {
      return retouchScale ? `scale(${retouchScale})` : undefined
    }
  }, [orientation, retouchScale]);

  useEffect(() => {
    const width = ref.current?.width || 0;
    const height = ref.current?.height || 0;
    if (width > 0 && height > 0) {
      // 矩形情報のセット
      setSizeCallback && setSizeCallback({ width, height });
      const aspectRatio = width / height;
      // console.log({ aspectRatio, containerAspectRatio });
      // 高さと幅どちらにフィットさせるか
      setFitVector(aspectRatio > containerAspectRatio ? "x" : "y");
    } else {
      const interval = setInterval(() => {
        const width = ref.current?.width || 0;
        const height = ref.current?.height || 0;
        if (setSizeCallback && width && height) {
          clearInterval(interval);
          setSizeCallback({ width, height });
          const aspectRatio = width / height;
          // 高さと幅どちらにフィットさせるか
          setFitVector(aspectRatio > containerAspectRatio ? "x" : "y");
        }
      });
    }
  }, [ref.current, containerAspectRatio]);

  /* ドラッグ用ダミー画像設置 */
  useEffect(() => {
    if (useDragDummy && defaultProps.src && ref.current && !dragDummy) {
      const img = document.createElement('img');
      img.src = defaultProps.src;
      img.alt = '';
      img.width = ref.current?.width || scale * 100;
      img.height = ref.current?.height || scale * 100;
      img.style.opacity = '0.3';
      img.onload = async () => {
        const blob = await fetch(img.src)
          .then((res) => res.blob());
        const reader = new FileReader();
        reader.onload = (e) => {
          const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
          svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
          svg.setAttributeNS(null, 'x', '0');
          svg.setAttributeNS(null, 'y', '0');
          svg.setAttributeNS(null, 'width', `${img.width}`);
          svg.setAttributeNS(null, 'height', `${img.height}`);
          svg.setAttributeNS(null, 'viewBox', `0 0 ${img.width} ${img.height}`);
          const svgImage = document.createElementNS('http://www.w3.org/2000/svg', 'image');
          svgImage.setAttributeNS(null, 'x', '0');
          svgImage.setAttributeNS(null, 'y', '0');
          svgImage.setAttributeNS(null, 'width', `${img.width}`);
          svgImage.setAttributeNS(null, 'height', `${img.height}`);
          // svgImage.setAttributeNS(null, 'preserveAspectRatio', 'none');
          svgImage.setAttributeNS(null, 'opacity', '0.25');
          svgImage.setAttributeNS('http://www.w3.org/1999/xlink', 'href', e.target?.result as string);
          svg.appendChild(svgImage);
          const svgData = new XMLSerializer().serializeToString(svg);
          const image = document.createElement('img');
          image.src = `data:image/svg+xml;charset=utf-8;base64,${btoa(svgData)}`;
          image.onload = () => {
            const canvas = document.createElement('canvas') as HTMLCanvasElement;
            const context: CanvasRenderingContext2D = canvas.getContext('2d') as CanvasRenderingContext2D;
            context.drawImage(image, 0, 0, image.width, image.height);
            const url = canvas.toDataURL();
            setDragDummy(url);
          };
        };
        reader.readAsDataURL(blob);
      };
    }
  }, [ref.current, defaultProps.src]);

  const setTimer = useCallback(() => {
    timer.current = window.setTimeout(() => {
      setDelayHover(true);
    }, 500);
  }, []);

  const clearTimer = useCallback(() => {
    clearTimeout(timer.current);
    setDelayHover(false);
  }, []);

  return (
    <div className={`base_image_memo ${props.className ?? ''}  ${delayHover ? 'delay_hover' : ''}`} style={{ display: defaultProps.style?.display ? defaultProps.style?.display : undefined }}>
      <img
        {...defaultProps}
        ref={ref}
        className={`base_image_memo__img${scale <= 1 ? ' scale_option' : ''} ${hover ? 'hover' : ''}`}
        style={{
          ...defaultProps.style,
          ...fitVector === "x" ? { width: `${scale * 100}%` } : { height: `${scale * 100}%` },
          display: fitVector || scale ? '' : 'none',
          transform: transform,
          // transform: ((orientation ? setTransform(orientation) : '') + (retouchScale ? ` scale(${retouchScale})` : '')),
        }}
        onMouseOver={() => {
          setTimer();
          setHover(true);
          setObserver(!observer);
        }}
        onMouseOut={() => {
          setHover(false);
          setObserver(!observer);
          clearTimer();
        }}
        onMouseLeave={() => {
          setHover(false);
          setObserver(!observer);
          clearTimer();
        }}
      />
      {dragDummy ? (
        <img
          className="base_image_memo__dummy"
          src={dragDummy}
          style={{ transform: `scale(${dummyScale})` }}
        />
      ) : (<></>)}
      {tooltip ? (
        <MemoDomContentTooltip
          className='tooltip'
          imageRef={ref}
          content={
            <>
              {tooltip.title ? (
                <div className="tooltip__title">{tooltip.title}</div>
              ) : (<></>)}
              <div className="tooltip__text">
                {tooltip.text.map((str, i) => {
                  return (
                    <div key={i}>
                      {str}
                    </div>
                  );
                })}
              </div>
            </>
          }
          relativeEle={ref.current}
          autoPos={tooltip.autoPos}
          observer={observer}
        />
      ) : (<></>)}
    </div>
  );
};

const setTransform = (orientation: Orientation) => {
  switch (orientation) {
    case Orientation.BOTTOM_LEFT:
      return 'rotate(-270deg)';
    case Orientation.BOTTOM_RIGHT:
      return 'rotate(-180deg)';
    case Orientation.LEFT_BOTTOM:
      return 'rotate(-270deg)';
    // case Orientation.LEFT_TOP:
    //   return 'rotate(deg)';
    case Orientation.RIGHT_BOTTOM:
      return 'rotate(-180deg)';
    case Orientation.RIGHT_TOP:
      return 'rotate(-90deg)';
    // case Orientation.TOP_LEFT:
    //   return 'rotate(deg)';
    case Orientation.TOP_RIGHT:
      return 'rotate(-90deg)';
  }
  return undefined;
};
